import React, { memo } from "react";

import "./user.scss";
import DatabaseTable from "./databasetable/index";
const Users = memo(() => {
  return (
    <div className="list-container">
      <DatabaseTable />
    </div>
  );
});

export default Users;
