import { memo, useEffect, useState } from "react";
import "../report/report.scss";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import moment from "moment";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "react-datepicker/dist/react-datepicker.css";
import { Stack } from "@mui/system";
import {
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  TextField,
  InputLabel,
  ListItemText,
  ListSubheader,
  Input,
  Chip,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
interface LocationFilter {
  id: string;
  label: string;
}
interface dataObj {
  id: string;
  label: string;
}
const Report = memo(() => {
  const [selectPeriod, setSelectPeriod] = useState("");
  const [csvState, setCsvState] = useState<any>([]);
  const [userFilter, setUserFilter] = useState({ id: "", label: "" });
  const [statusFilter, setStatusFilter] = useState("");
  const [chargerFilter, setChargerFilter] = useState("");
  const [currentTypeFilter, setCurrentTypeFilter] = useState("");
  const [chargerTypeFilter, setChargerTypeFilter] = useState({
    label: "",
    type: "",
  });

  const [startDateFilter, setStartDateFilter] = useState<any>("");
  const [endDateFilter, setEndDateFilter] = useState<any>("");
  const [customDateFilter, setCustomDateFilter] = useState<any>("");
  const [locationTypeFilter, setLocationTypeFilter] = useState<any>([]);
  const [allLocations, setAllLocations] = useState<any | null>([]);
  const [allLocationType, setAllLocationType] = useState<any | null>([]);
  const [allCity, setAllCity] = useState<any | null>([]);
  const [openInvoice, setOpenInvoice] = useState(false);
  const [openCustom, setOpenCustom] = useState<boolean>(false);
  const [SingleDateChips, setSingleDateFilterChips] = useState<string | null>(
    null
  );
  const [dateFilter, setDateFilter] = useState("");
  const [dateRangeChipVisible, setDateRangeChipVisible] =
    useState<boolean>(false);

  // Filter For Date Range Picker Function
  const dateRangeChange = (dates: any) => {
    const [start, end] = dates;
    console.log("DateRange", dates);

    setCustomDateFilter(start);
    setStartDateFilter(start);
    setEndDateFilter(end);
    setEndDateFilter(end);
    setDateRangeChipVisible(!!start && !!end);
    setSingleDateFilterChips(start);
  };

  const [dateChips, setDateFilterChips] = useState<any[]>([]);

  const [locationTypeChips, setLocationTypeChips] = useState("");
  const [chargerTypeChips, setChargerTypeChips] = useState("");
  const [locationFilter, setLocationFilter] = useState<LocationFilter[]>([]);
  const [locationChips, setLocationChips] = useState<
    { id: string; label: string }[]
  >([]);
  const [filterChips, setFilterChips] = useState<
    { id: string; label: string }[]
  >([]);
  const [locationIds, setLocationIds] = useState<any[]>([]);

  // Delete Chip Location Filter
  const handleLocationDeleteChip = (chipId: string) => {
    console.log("Deleting chip with id:", chipId);

    // Remove the corresponding chip from the state
    setLocationChips((prevChips) =>
      prevChips.filter((chip) => chip.id !== chipId)
    );

    // Remove the location filter
    setLocationIds((prevUserIds) => prevUserIds.filter((id) => id !== chipId));

    setLocationFilter((prevUserFilter: any) =>
      prevUserFilter.filter((filter: any) => filter.id !== chipId)
    );
  };

  const handleDateChange = (selectedFilter: any) => {
    console.log("selectedFilter", selectedFilter);

    setDateFilter(selectedFilter);
    if (selectedFilter === "") {
      setSelectPeriod("");
      setOpenCustom(!openCustom);
    } else {
      setSelectPeriod(selectedFilter);
      setOpenCustom(false);
      setCustomDateFilter("");
      setStartDateFilter("");
      setEndDateFilter("");
      setDateRangeChipVisible(false);
    }
    
  };

  const handleDeleteLocationTypeChip = (chipId: string) => {
    setStatusFilter("");
    setLocationTypeFilter("");
    setLocationTypeChips("");
  };
  const handleDeleteChargerChip = (chipId: string) => {
    setChargerFilter("");
    setChargerTypeFilter({ label: "", type: "" });
    setChargerTypeChips("");
  };

  const handleDeleteDateChip = (chipId: string) => {
    setDateFilterChips((prevChips) =>
      prevChips.filter((chip) => chip.id !== chipId)
    );
    setDateFilter("");
    setSelectPeriod("");
  };

  const handleDateRangeDelete = () => {
    setStartDateFilter(null);
    setEndDateFilter(null);
    setDateRangeChipVisible(false);
  };

  const handleSingleDeleteDateChip = (chipId: string) => {
    setStartDateFilter(null);
    setEndDateFilter(null);
    setSingleDateFilterChips(null);
  };

  const currentUser: any = Parse.User.current();
  const intitalPayoutFields = {
    cpo: currentUser.get("CPO").get("Name"),
    isProcessed: false,
    status: "Pending",

    amount: 0,
  };
  const [allConnectorType, setAllConnectorType] = useState<any>([]);
  const [connectorTypeLoading, setConnectorTypeLoading] = useState(false);
  const getAllConnectorType = (currentType: string) => {
    const Locations = Parse.Object.extend("ConnectorTypes");

    const parseQuery = new Parse.Query(Locations);
    if (currentType) {
      if (currentType === "All") {
        let currentTypes = ["AC", "DC"];
        parseQuery.containedIn("CurrentType", currentTypes);
      } else {
        parseQuery.equalTo("CurrentType", currentType);
      }
    }
    // if (currentType) {
    //   parseQuery.equalTo("CurrentType", currentType);
    // }
    setConnectorTypeLoading(true);
    parseQuery.limit(50);
    parseQuery.find().then((result) => {
      let connectorArray: any = [];
      result.forEach((item) => {
        connectorArray.push({
          label: item.get("Name"),
          type: item.get("CurrentType"),
        });
      });
      setConnectorTypeLoading(false);
      setAllConnectorType(connectorArray);
    });
  };
  const handleConnectorChange = (newValue: any) => {
    console.log("newValue Connector", newValue);

    setChargerTypeChips(`Type ${newValue.type} ${newValue.label}`);
    setChargerFilter((prevValue) => (prevValue === newValue ? "" : newValue));
    setChargerTypeFilter(newValue);
    setChargerTypeFilter(
      newValue === chargerTypeFilter ? { label: "", type: "" } : newValue
    );

    setCurrentTypeFilter("");
  };
  const handleLocationChange = (newValue: any) => {
    setLocationTypeChips(newValue);
    setStatusFilter((prevValue) => (prevValue === newValue ? "" : newValue));
    setLocationTypeFilter(newValue);
  };

  console.log("locationTypeFilter", locationTypeFilter);
  const getAllLocations = () => {
    const Locations = Parse.Object.extend("Locations");

    const parseQuery = new Parse.Query(Locations);

    parseQuery.limit(50);

    parseQuery.find().then((result) => {
      let addressOptions: dataObj[] = [];
      let locationArray: any[] = [];
      let locationType: any[] = [];
      let cityType: any[] = [];
      result.forEach((item, index) => {
        if (
          !cityType.find(function (i) {
            return i === item.get("City");
          })
        ) {
          cityType.push(item.get("City"));
        }

        if (
          !locationType.find(function (i) {
            return i === item.get("LocationType");
          })
        ) {
          locationType.push(item.get("LocationType"));
        }

        let locPoint = item.get("GeoLocation");

        locationArray.push({
          id: item.id,
          stationName: `${item.get("Name")}`,
          type: `${item.get("LocationType")}`,
          city: `${item.get("City")}`,
          access: `${
            item.get("hasRestrictedAccess") ? "Restricted" : "Public"
          }`,
          operator: "Charge City",
          address: `${item.get("Address")}`,
          state: `${item.get("State")}`,
          lat: `${locPoint.latitude}`,
          long: `${locPoint.longitude}`,
          openingTime: `${moment(item.get("OpenTime"), "hh:mm A")}`,
          closingTime: `${moment(item.get("CloseTime"), "hh:mm A")}`,
          electricityTariff: `${item.get("ElectricityTariff")}`,
          isEnabled: `${item.get("isEnabled") ? "true" : "false"}`,
          modelType: `${item.get("RevenueModel")}`,
          currency: "₹",
          revenueAmount: `${item.get("RevenueAmount")}`,
          revenuePercent: `${item.get("RevenueSharingType")}`,
        });

        addressOptions.push({
          id: item.id,
          label: item.get("Name"),
        });
      });

      setAllCity(allCity.length ? allCity : cityType);
      setAllLocationType(
        allLocationType.length ? allLocationType : locationType
      );
    });
  };
  let userDetail: any = sessionStorage.getItem("user-details");

  const getFilteredLocations = () => {
    const chargersQuery = new Parse.Query("Chargers");
    if (currentUser) {
      chargersQuery.equalTo("CPO", currentUser.get("CPO"));
    }

    chargersQuery.find().then((chargeResult) => {
      let locArray: any = [];
      chargeResult.map((chargePoint) => {
        if (!locArray.includes(chargePoint.get("Location").id)) {
          locArray.push(chargePoint.get("Location").id);
        }
      });

      const locationQuery = new Parse.Query("Locations");
      !JSON.parse(userDetail).isSuperAdmin &&
        locationQuery.containedIn("objectId", locArray);
      locationQuery.find().then((result) => {
        let addressArray: any[] = [];

        result.forEach((item) => {
          let locPoint = item.get("GeoLocation");
          addressArray.push({
            id: item.id,
            label: item.get("Name"),
          });
          setAllLocations(addressArray);
        });
      });
    });
  };

  useEffect(() => {
    getAllLocations();
    getFilteredLocations();
  }, []);

  const [totalRevenue, setTotalRevenue] = useState(0);
  const [allUsers, setAllUsers] = useState<any>([]);
  const [reportData, setReportData] = useState<any>([]);
  const loadSessions = (
    userId: string,
    locationId: any,
    locationType: any,
    connectorType: any,
    currentType: any,
    status: string,
    date: string,
    startDate: string | Date,
    endDate: string | Date,
    customDate: string | Date
  ) => {
    const parseQuery = new Parse.Query("ChargeSession");
    parseQuery.include("ChargePoint");
    if (userId) {
      let User = Parse.User;
      let userObj = User.createWithoutData(userId);
      parseQuery.equalTo("User", userObj);
    }
    if (currentUser) {
      var innerQuery = new Parse.Query("Chargers");

      innerQuery.equalTo("CPO", currentUser.get("CPO"));
      parseQuery.matchesQuery("ChargePoint", innerQuery);
    }
    if (locationId.length) {
      var innerQuery = new Parse.Query("Locations");
      innerQuery.containedIn(
        "objectId",
        locationId.map((item: any) => item.id)
      );
      parseQuery.matchesQuery("Location", innerQuery);
    }
    console.log("locationType", locationType);
    let LocType = [];
    LocType.push(locationType);

    if (locationType == "all") {
      var innerQuery = new Parse.Query("Locations");
      innerQuery.containedIn("LocationType", [
        "Mall",
        "Residential",
        "Office Building",
        "Public Parking",
      ]);
      parseQuery.matchesQuery("Location", innerQuery);
    }
    if (locationType.length > 1) {
      var innerQuery = new Parse.Query("Locations");

      // Check if the selected location type is "all"
      if (locationType === "all") {
        // If "all" is selected, set locationType to an array containing all location types
        locationType = [
          "Mall",
          "Residential",
          "Office Building",
          "Public Parking",
        ];
        // Use containedIn to match any of the location types
        innerQuery.containedIn("LocationType", locationType);
        parseQuery.matchesQuery("Location", innerQuery);
      } else {
        var innerQuery = new Parse.Query("Locations");
        innerQuery.containedIn("LocationType", [`${locationType}`]);
        parseQuery.matchesQuery("Location", innerQuery);
      }
    }

    if (connectorType.label) {
      var innerQuery = new Parse.Query("Chargers");
      innerQuery.equalTo("Connector", connectorType.label);
      parseQuery.matchesQuery("ChargePoint", innerQuery);
    }
    console.log("currentType", currentType);

    if (currentType) {
      var innerQuery = new Parse.Query("Chargers");
      var moreInnerQuery = new Parse.Query("ConnectorTypes");

      if (currentType === "All") {
        currentType = ["AC", "DC"];
        moreInnerQuery.containedIn("CurrentType", currentType);
      } else {
        moreInnerQuery.equalTo("CurrentType", currentType);
      }

      innerQuery.matchesQuery("ConnectorType", moreInnerQuery);
      parseQuery.matchesQuery("ChargePoint", innerQuery);
    }

    if (status) {
      status === "Completed"
        ? parseQuery.equalTo("Live", false)
        : parseQuery.equalTo("Live", true);
    }

    if (date) {
      if (date === "Today") {
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment().startOf("day").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "createdAt",
          new Date(moment().add(1, "day").startOf("day").toString())
        );
      } else if (date === "Yesterday") {
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment().subtract(1, "day").startOf("day").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "createdAt",
          new Date(moment().startOf("day").toString())
        );
      } else if (date === "This Week") {
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment().subtract(7, "day").startOf("day").toString())
        );
        parseQuery.lessThanOrEqualTo("createdAt", new Date());
      } else if (date === "Last Month") {
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment().subtract(1, "month").startOf("month").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "createdAt",
          new Date(moment().subtract(1, "month").endOf("month").toString())
        );
      }
    }

    if (startDate) {
      // Apply single date filter
      parseQuery.greaterThanOrEqualTo(
        "createdAt",
        new Date(moment(startDate).startOf("day").toString())
      );
      parseQuery.lessThanOrEqualTo(
        "createdAt",
        new Date(moment(startDate).endOf("day").toString())
      );
    }
    if (startDate && endDate) {
      parseQuery.greaterThanOrEqualTo("createdAt", new Date(startDate));
      parseQuery.greaterThanOrEqualTo("createdAt", new Date(startDate));
      const adjustedEndDate = moment(endDate).endOf("day").toDate();
      parseQuery.lessThanOrEqualTo("createdAt", adjustedEndDate);
    }

    parseQuery.include("ChargePoint");
    parseQuery.include("Location");
    parseQuery.include("Vehicle");
    parseQuery.include("User");
    parseQuery.descending("createdAt");
    parseQuery.limit(10000);
    parseQuery.find().then((result: any[]) => {
      let newRow: {
        id: number;
        status: string;
        startTime: string;
        endTime: string;
        chargeBy: string;
        chargeFor: string;
        customer: string;
        location: string;
        carCharged: string;
        aid: string;
        date: string;
        cost: string;
        energy: string;
        duration: string;
        obj: any;
        serialNum: string;
        power: string;
        connector: string;
      }[] = [];

      let usersArray: any[] = [];
      let totalEnergy = 0;
      let thisMonthEnergy = 0;
      let lastMonthEnergy = 0;
      let percentageEnergy = 0;
      let totalMoney = 0;
      let thisMonthMoney = 0;
      let lastMonthMoney = 0;
      let totalSession = 0;
      let thisMonthSession = 0;
      let lastMonthSession = 0;
      let csvRow: any[] = [
        [
          "Id",
          "Name",
          "Location",
          "Start Time",
          "End Time",
          "Cost",
          "Energy",
          "Serial",
          "Power",
          "Connector",
        ],
      ];
      let csvFirstRow: any[] = [
        ["Revenue", "Energy Consumed", "Total Session"],
      ];

      result.forEach((item, index) => {
        totalEnergy = item.get("TotalEnergyConsumed")
          ? item.get("TotalEnergyConsumed") + totalEnergy
          : 0 + totalEnergy;
        totalMoney = item.get("TotalCost")
          ? item.get("TotalCost") + totalMoney
          : 0 + totalMoney;
        totalSession = totalSession + 1;
        setTotalRevenue(totalMoney);
        let bc = [
          index + 1,
          `${item.get("User").get("FullName")}`,
          item.get("Location").get("Name"),
          `${
            moment(item.get("createdAt")).format("lll")
              ? moment(item.get("createdAt")).format("lll")
              : "-"
          }`,
          `${
            moment(item.get("updatedAt")).format("lll")
              ? moment(item.get("updatedAt")).format("lll")
              : "-"
          }`,
          `₹ ${parseFloat(item.get("TotalCost")).toFixed(2)}`,
          `${parseFloat(item.get("TotalEnergyConsumed")).toFixed(2)} kWh`,

          `${item.get("ChargePoint").get("Serial")}`,
          `${item.get("ChargePoint").get("Power")}`,
          `${item.get("ChargePoint").get("Connector")}`,
        ];

        csvRow.push(bc);

        let car = "";
        if (`${item.get("Vehicle")}` !== "undefined") {
          car = `${item.get("Vehicle").get("Name")}`;
        }

        const userObj = {
          id: `${item.get("User").id}`,
          label: `${item.get("User").get("FullName")}`,
        };

        if (
          !usersArray.find(function (i) {
            return i.id === userObj.id;
          })
        ) {
          usersArray.push(userObj);
        }

        newRow.push({
          id: index + 1,
          status: `${item.get("User").get("FullName")}`,
          customer: `${item.get("User").get("FullName")}`,
          location: `${item.get("Location").get("Name")}`,
          serialNum: `${item.get("ChargePoint").get("Serial")}`,
          power: `${item.get("ChargePoint").get("Power")}`,
          connector: `${item.get("ChargePoint").get("Connector")}`,
          startTime: `${
            moment(item.get("createdAt")).format("lll")
              ? moment(item.get("createdAt")).format("lll")
              : "-"
          }`,
          endTime: `${
            moment(item.get("updatedAt")).format("lll")
              ? moment(item.get("updatedAt")).format("lll")
              : "-"
          }`,
          carCharged: car,
          chargeBy: `${item.get("ChargeBy")}`,
          chargeFor: `${item.get("ChargeFor")}`,
          duration: `${
            moment
              .duration(
                moment(new Date(item.get("updatedAt"))).diff(
                  moment(new Date(item.get("createdAt")))
                )
              )
              .hours() +
            "hr" +
            " " +
            moment
              .duration(
                moment(new Date(item.get("updatedAt"))).diff(
                  moment(new Date(item.get("createdAt")))
                )
              )
              .minutes() +
            "min"
          }  `,
          aid: `${item.get("AID")}`,
          date: `${item.get("createdAt")}`,
          cost: `₹ ${parseFloat(item.get("TotalCost")).toFixed(2)}`,
          energy: `${parseFloat(item.get("TotalEnergyConsumed")).toFixed(
            2
          )} kWh`,
          obj: item,
        });
      });

      csvFirstRow.push([totalMoney, totalEnergy, totalSession], ["", "", ""]);
      setCsvState(csvFirstRow.concat(csvRow));
      setAllUsers(!allUsers.length ? usersArray : allUsers);
      setReportData(newRow);
    });
  };
  console.log("locationFilter", locationFilter);

  useEffect(() => {
    loadSessions(
      userFilter.id,
      locationFilter,
      locationTypeFilter,
      chargerTypeFilter,
      currentTypeFilter,
      statusFilter,
      selectPeriod,
      startDateFilter,

      endDateFilter,
      customDateFilter
    );
  }, [
    userFilter.id,
    locationFilter,
    locationTypeFilter,
    chargerTypeFilter,
    currentTypeFilter,
    statusFilter,
    selectPeriod,
    startDateFilter,
    endDateFilter,
    customDateFilter,
  ]);

  useEffect(() => {
    getAllConnectorType(currentTypeFilter);
  }, [currentTypeFilter]);

  console.log("chargerTypeFilter.label", chargerTypeFilter.label);

  const ReportFilter = (props: { data: any }) => {
    switch (props.data) {
      case "Location":
        return (
          <Autocomplete
            className="mb-4 mt-4 mx-4"
            freeSolo
            id="checkboxes-tags-demo"
            disableClearable
            multiple
            limitTags={-1}
            options={allLocations}
            // loading={loadingUsers}
            autoHighlight
            size="small"
            value={locationFilter} // Set the value to control the selected Location
            onChange={(event: any, newValue: any) => {
              console.log("New Value", newValue);

              setLocationChips((prevChips: { id: string; label: string }[]) => {
                // Ensure that newValue is an array of objects with 'id' property
                const newChips: { id: string; label: string }[] = newValue.map(
                  (user: any) => ({
                    id: user.id,
                    label: `Location: ${user.label}`,
                  })
                );

                const filteredChips = newChips.filter(
                  (chip) =>
                    !prevChips.some((prevChip) => prevChip.id === chip.id)
                );

                return [...prevChips, ...filteredChips];
              });
              setLocationFilter(newValue);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option: any) => option.label}
            getOptionDisabled={(option) =>
              filterChips.some((chip) => chip.id === option.id)
            }
            renderOption={(props, option) => {
              let counter = 0;
              const uniqueKey = `${option.id}_${option.label}_${counter++}`;
              const selected = filterChips.some(
                (chip) => chip.id === option.id
              );
              // console.log("selected", selected);

              return (
                <MenuItem key={uniqueKey} {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={
                      selected ||
                      filterChips.some((chip) => chip.id === option.id)
                    }
                  />
                  <ListItemText primary={option.label} />
                </MenuItem>
              );
            }}
            renderInput={(params: any) => (
              <TextField {...params} placeholder="search Location..." />
            )}
          />
        );
      case "Location Type":
        return (
          <div className="flex mt-2">
            <div className="flex items-center ml-3">
              <input
                type="radio"
                style={{
                  background: "black",
                  width: "20px",
                  height: "20px",
                }}
                checked={locationTypeFilter == "all"}
                onChange={() => setLocationTypeFilter("all")}
              />
              <label htmlFor="" className="font-lexend ml-2">
                All
              </label>
            </div>
            {allLocationType?.map((ele: any, index: number) => (
              <div className="flex items-center ml-3" key={index}>
                <input
                  type="radio"
                  className={`mr-1 ${index !== 0 ? "ml-4" : "ml-0"}`}
                  style={{
                    background: "black",
                    width: "20px",
                    height: "20px",
                  }}
                  checked={ele == locationTypeFilter}
                  //   onChange={() => handleLocationChange(ele)}
                  onChange={() => setLocationTypeFilter(ele)}
                />
                <label htmlFor="" className="font-lexend">
                  {ele}
                </label>
              </div>
            ))}
          </div>
        );

      case "Connector Type":
        return (
          <div className="connector_Type_container flex mt-2">
            {allConnectorType?.map((ele: any, index: number) => (
              <div className="flex items-center ml-3" key={index}>
                <input
                  type="radio"
                  className={`mr-1 ${index !== 0 ? "ml-4" : "ml-0"}`}
                  style={{
                    background: "black",
                    width: "20px",
                    height: "20px",
                  }}
                  checked={ele.label === chargerTypeFilter.label}
                  onChange={() => handleConnectorChange(ele)}
                />
                <label htmlFor="" className="font-lexend">
                  {ele?.label}
                </label>
              </div>
            ))}
          </div>
        );

      case "Current Type":
        if (chargerTypeFilter.label) {
          return (
            <>
              <div className="flex items-center ml-3">
                <input
                  type="radio"
                  onClick={() => {
                    setCurrentTypeFilter(
                      currentTypeFilter === "All" ? "" : "All"
                    );
                    setChargerTypeFilter({
                      label: "",
                      type: "",
                    });
                  }}
                  style={{
                    background: "black",
                    width: "20px",
                    height: "20px",
                  }}
                  checked={currentTypeFilter === "All"}
                />
                <label htmlFor="" className="font-lexend ml-2">
                  All
                </label>
              </div>
              <div className="flex items-center ml-3">
                <input
                  type="radio"
                  style={{
                    background: "black",
                    width: "20px",
                    height: "20px",
                  }}
                  checked={chargerTypeFilter.type === "AC"}
                />
                <label htmlFor="" className="font-lexend">
                  AC
                </label>
              </div>
              <div className="flex items-center ml-3">
                <input
                  type="radio"
                  style={{
                    background: "black",
                    width: "20px",
                    height: "20px",
                  }}
                  checked={chargerTypeFilter.type === "DC"}
                />
                <label htmlFor="" className="font-lexend">
                  DC
                </label>
              </div>
            </>
          );
        } else {
          return (
            <div className="flex">
              <div className="flex items-center ml-3">
                <input
                  type="radio"
                  onClick={() => {
                    setCurrentTypeFilter(
                      currentTypeFilter === "All" ? "" : "All"
                    );
                  }}
                  style={{
                    background: "black",
                    width: "20px",
                    height: "20px",
                  }}
                  checked={currentTypeFilter === "All"}
                />

                <label htmlFor="" className="font-lexend ml-2">
                  All
                </label>
              </div>
              <div className="flex items-center ml-3">
                <input
                  type="radio"
                  onClick={() => {
                    setCurrentTypeFilter(
                      currentTypeFilter === "AC" ? "" : "AC"
                    );
                    setChargerTypeFilter({
                      label: "",
                      type: "",
                    });
                  }}
                  style={{
                    background: "black",
                    width: "20px",
                    height: "20px",
                  }}
                  checked={currentTypeFilter === "AC"}
                />
                <label htmlFor="" className="font-lexend ml-2">
                  AC
                </label>
              </div>
              <div className="flex items-center ml-5">
                <input
                  type="radio"
                  onClick={() => {
                    setCurrentTypeFilter(
                      currentTypeFilter === "DC" ? "" : "DC"
                    );
                    setChargerTypeFilter({
                      label: "",
                      type: "",
                    });
                  }}
                  style={{
                    background: "black",
                    width: "20px",
                    height: "20px",
                  }}
                  checked={currentTypeFilter === "DC"}
                />
                <label htmlFor="" className="font-lexend ml-2">
                  DC
                </label>
              </div>
            </div>
          );
        }

      case "User":
        return (
          <FormControl>
            <Select
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{ width: 350 }}
              input={<OutlinedInput />}
              value={userFilter.id}
            >
              <MenuItem disabled value="">
                User
              </MenuItem>
              {allUsers.map((item: any) => (
                <MenuItem value={item.id} onClick={() => setUserFilter(item)}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case "CPO":
        return (
          <FormControl>
            <Select
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{ width: 350 }}
              input={<OutlinedInput />}
              value=""
            >
              <MenuItem disabled value="">
                CPO
              </MenuItem>

              <MenuItem>Charge City</MenuItem>
            </Select>
          </FormControl>
        );
      default:
        return null;
    }
  };

 // ! ||--------------------------------------------------------------------------------||
  // ! ||                    Handle Custom File Name With Dates Values                   ||
  // ! ||--------------------------------------------------------------------------------||
  const customFilename = () => {
    let filename = "Charge Session";
    const today = moment().format("YYYY-MM-DD");
  
    if (startDateFilter) {
      const startDate = moment(startDateFilter).format("YYYY-MM-DD");
      if (endDateFilter) {
        const endDate = moment(endDateFilter).format("YYYY-MM-DD");
        filename += `_${startDate}_to_${endDate}`;
      } else {
        filename += `_${startDate}`;
      }
    } else {
      switch (dateFilter) {
        case "Today":
          filename += `_${today}`;
          break;
        case "Yesterday":
          const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");
          filename += `_${yesterday}`;
          break;
        case "This Week":
          const last7Days = moment().subtract(7, "days").format("YYYY-MM-DD");
          filename += `_${last7Days}_to_${today}`;
          break;
        case "Last Month":
          const previousMonth = moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD");
          const endOfPreviousMonth = moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD");
          filename += `_${previousMonth}_to_${endOfPreviousMonth}`;
          break;
        case "This Year":
          const startOfYear = moment().startOf("year").format("YYYY-MM-DD");
          filename += `_${startOfYear}_to_${today}`;
          break;
        default:
          filename += `_All`;
      }
    }
  
    return `${filename}.csv`;
  };
  

  return (
    <div className="report_Section mx-auto w-11/12">
      <div className="mt-5  ">
        {locationChips.map((chip) => (
          <Chip
            key={chip.id}
            label={chip.label}
            onDelete={() => handleLocationDeleteChip(chip.id)}
            variant="outlined"
            sx={{ marginLeft: "5px", marginBottom: "10px" }}
          />
        ))}
        {locationTypeChips && (
          <Chip
            key={locationTypeChips}
            label={locationTypeChips}
            onDelete={() => handleDeleteLocationTypeChip(locationTypeChips)}
            variant="outlined"
            sx={{ marginLeft: "5px", marginBottom: "10px" }}
          />
        )}

        {chargerTypeChips && (
          <Chip
            key={chargerTypeChips}
            label={chargerTypeChips}
            onDelete={() => handleDeleteChargerChip(chargerTypeChips)}
            variant="outlined"
            sx={{ marginLeft: "5px", marginBottom: "10px" }}
          />
        )}

        {dateFilter && (
          <Chip
            key={dateFilter}
            label={dateFilter}
            onDelete={() => handleDeleteDateChip(dateFilter)}
            variant="outlined"
            sx={{ marginLeft: "5px", marginBottom: "10px" }}
          />
        )}
        {/* Single Date Chip */}
        {dateChips.map((chip) => (
          <Chip
            key={chip.id}
            label={chip.label}
            onDelete={() => handleDeleteDateChip(chip.id)}
            variant="outlined"
            sx={{ marginLeft: "5px", marginBottom: "10px" }}
          />
        ))}
        {/* Range Date Chip */}
        {dateRangeChipVisible && (
          <Chip
            label={`Date Between : ${moment(startDateFilter).format(
              "DD-MM-YYYY"
            )} - ${moment(endDateFilter).format("DD-MM-YYYY")}`}
            onDelete={handleDateRangeDelete}
            variant="outlined"
            sx={{ marginLeft: "5px" }}
          />
        )}
        {/* Handle range Date Chip when deleted Single Date chip shows this prevent that bug */}
        {!dateRangeChipVisible && SingleDateChips && startDateFilter && (
          <Chip
            label={`Date : ${moment(startDateFilter).format("DD-MM-YYYY")}`}
            onDelete={handleSingleDeleteDateChip}
            variant="outlined"
            sx={{ marginLeft: "5px", marginBottom: "10px" }}
          />
        )}
      </div>
      <h1 className="font-lexend text-2xl mt-3 ml-4">Reports</h1>
      <div
        style={{ background: "#F6F8FA" }}
        className="filter_container_report rounded-xl ml-3 mt-3 py-6 px-"
      >
        <h1 className="font-semibold font-lexend text-lg ml-3 ">Location*</h1>
        <ReportFilter data={"Location"} />{" "}
        <h1 className="font-semibold font-lexend text-lg ml-3 mt-8">
          Location Type*
        </h1>
        <ReportFilter data={"Location Type"} />
        <h1 className="font-semibold font-lexend text-lg  ml-3 mt-8 ">
          Charge Type*
        </h1>
        <ReportFilter data={"Current Type"} />
        <h1 className="font-semibold font-lexend text-lg  ml-3 mt-8 ">
          Connector Type*
        </h1>
        <ReportFilter data={"Connector Type"} />
        <div className="Date_filter_report flex items-center mt-8">
          <h4 className=" font-semibold font-lexend text-lg  ml-3 ">
            Select Period
          </h4>
          {/* Specify Time  */}
          {/* <FormControl variant="standard">
            <Stack direction="row" spacing={8}>
              {" "}
              <FormControlLabel control={<Checkbox />} label="Specify Time" />
            </Stack>
          </FormControl> */}
        </div>
        {/* Date Filter For Reports */}
        <div className="date_filter_container flex mt-2">
          <div className="flex items-center ml-3">
            <input
              type="radio"
              className="mr-1"
              style={{
                background: "black",
                width: "20px",
                height: "20px",
              }}
              checked={selectPeriod === "Today"}
              onChange={() => handleDateChange("Today")}
            />
            <label htmlFor="" className="font-lexend">
              Today
            </label>
          </div>
          <div className="flex items-center ml-4">
            <input
              type="radio"
              className="mr-1"
              style={{
                background: "black",
                width: "20px",
                height: "20px",
              }}
              checked={selectPeriod === "Yesterday"}
              onChange={() => handleDateChange("Yesterday")}
            />
            <label htmlFor="" className="font-lexend">
              Yesterday
            </label>
          </div>
          <div className="flex items-center ml-4">
            <input
              type="radio"
              className="mr-1"
              style={{
                background: "black",
                width: "20px",
                height: "20px",
              }}
              checked={selectPeriod === "This Week"}
              onChange={() => handleDateChange("This Week")}
            />
            <label htmlFor="" className="font-lexend">
              Last 7 Days
            </label>
          </div>
          <div className="flex items-center ml-4">
            <input
              type="radio"
              className="mr-1"
              style={{
                background: "black",
                width: "20px",
                height: "20px",
              }}
              checked={selectPeriod === "Last Month"}
              onChange={() => handleDateChange("Last Month")}
            />
            <label htmlFor="" className="font-lexend">
              Last Month
            </label>
          </div>
        </div>
        {/* Custom Date Picker Code */}
        <div className="flex items-center ml-3 mt-3">
          <input
            type="radio"
            className="mr-1"
            style={{
              background: "black",
              width: "20px",
              height: "20px",
            }}
            checked={openCustom}
            onChange={() => handleDateChange("")}
          />
          <label htmlFor="" className="font-lexend">
            Custom
          </label>
        </div>
        {openCustom && (
          <div className="dateSec mb-4 mt-4 flex justify-between">
            <div className="select_date_container flex flex-col">
              <label htmlFor="" className="font-lexend ml-4">
                Custom Date
              </label>
              <div className="start_dateContainer mt-4 ml-4">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <MuiDatePicker
                    label="Start Date"
                    readOnly
                    value={startDateFilter}
                    onChange={dateRangeChange}
                    className="mt-4"
                    renderInput={(params) => (
                      <TextField {...params} error={false} />
                    )}
                    inputFormat="DD-MM-YYYY"
                  />
                </LocalizationProvider>
              </div>
              <div className="endDate_container mt-4 ml-4">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <MuiDatePicker
                    label="End Date"
                    readOnly
                    value={endDateFilter}
                    onChange={(item) => setEndDateFilter(item || "")}
                    renderInput={(params) => (
                      <TextField {...params} error={false} />
                    )}
                    inputFormat="DD-MM-YYYY"
                  />
                </LocalizationProvider>
              </div>
              <div className="CSV_Button mt-4 ml-4 w-full">
              <CSVLink    className="reportButton" data={csvState} filename={customFilename()}>
                <button
                 className="flex justify-around items-center w-max px-3 py-2 rounded-full mr-3 mt-8 ml-3"
                   style={{ background: "#1AC47D", border: "2px solid #1AAD70" }}
                >
                  <span className="mr-2 ml-2 ">
               <svg
                width="13"
                height="16"
                viewBox="0 0 13 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.93501 1.25V4.25C7.93501 4.44891 8.01067 4.63968 8.14532 4.78033C8.27998 4.92098 8.46262 5 8.65305 5H11.5252M7.93501 1.25H2.90874C2.52786 1.25 2.16259 1.40804 1.89327 1.68934C1.62396 1.97064 1.47266 2.35218 1.47266 2.75V13.25C1.47266 13.6478 1.62396 14.0294 1.89327 14.3107C2.16259 14.592 2.52786 14.75 2.90874 14.75H10.0891C10.47 14.75 10.8353 14.592 11.1046 14.3107C11.3739 14.0294 11.5252 13.6478 11.5252 13.25V5M7.93501 1.25L11.5252 5M4.34482 11.75H8.65305M4.34482 8.75H8.65305"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
                <span className="font-lexend text-white">Generate CSV Report</span>
                </button>
              </CSVLink>
              </div>
             
            </div>

            <div className="rangePicker_container mt-4 mr-4 w-1/2">
              <DatePicker
                selected={startDateFilter}
                onChange={dateRangeChange}
                startDate={startDateFilter}
                endDate={endDateFilter}
                selectsRange
                inline
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                maxDate={new Date()} // Disabled Future Date
                minDate={new Date(2022, 0, 1)}
              />
            </div>
          </div>
        )}
        {!openCustom && (
          // <button
          //   className="flex justify-around items-center w-max px-3 py-2 rounded-full mr-3 mt-8 ml-3"
          //   style={{ background: "#1AC47D", border: "2px solid #1AAD70" }}
          //   disabled
          // >
          //   {/* Open custom  */}
          //   <span className="mr-2 ml-5 ">
          //     <svg
          //       width="13"
          //       height="16"
          //       viewBox="0 0 13 16"
          //       fill="none"
          //       xmlns="http://www.w3.org/2000/svg"
          //     >
          //       <path
          //         d="M7.93501 1.25V4.25C7.93501 4.44891 8.01067 4.63968 8.14532 4.78033C8.27998 4.92098 8.46262 5 8.65305 5H11.5252M7.93501 1.25H2.90874C2.52786 1.25 2.16259 1.40804 1.89327 1.68934C1.62396 1.97064 1.47266 2.35218 1.47266 2.75V13.25C1.47266 13.6478 1.62396 14.0294 1.89327 14.3107C2.16259 14.592 2.52786 14.75 2.90874 14.75H10.0891C10.47 14.75 10.8353 14.592 11.1046 14.3107C11.3739 14.0294 11.5252 13.6478 11.5252 13.25V5M7.93501 1.25L11.5252 5M4.34482 11.75H8.65305M4.34482 8.75H8.65305"
          //         stroke="white"
          //         stroke-width="1.5"
          //         stroke-linecap="round"
          //         stroke-linejoin="round"
          //       />
          //     </svg>
          //   </span>
          //   <span style={{ color: "white" }} className="mr-5">
          //     <CSVLink
          //       data={csvState}
          //       className="reportButton"
          //       filename={"Charge-City.csv"}
          //     >
                
          //       <span className="font-lexend">Generate CSV Report</span>
          //     </CSVLink>
              
          //   </span>
          // </button>
          // <CSVLink
          //       data={csvState}
          //       className="reportButton"
          //       filename={"Charge-City.csv"}
          //     >
          <CSVLink    className="reportButton" data={csvState} filename={customFilename()}>
                <button
                 className="flex justify-around items-center w-max px-3 py-2 rounded-full mr-3 mt-8 ml-3"
                  //  style={{ cursor :` ${locationFilter.length >= 0 ? "not-allowed" : "pointer" }`, background: "#1AC47D", border: "2px solid #1AAD70"  }}
                   style={{ background: "#1AC47D", border: "2px solid #1AAD70"  }}
                  //  disabled={locationFilter.length >= 0}
                >
                  <span className="mr-2 ml-2 ">
               <svg
                width="13"
                height="16"
                viewBox="0 0 13 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.93501 1.25V4.25C7.93501 4.44891 8.01067 4.63968 8.14532 4.78033C8.27998 4.92098 8.46262 5 8.65305 5H11.5252M7.93501 1.25H2.90874C2.52786 1.25 2.16259 1.40804 1.89327 1.68934C1.62396 1.97064 1.47266 2.35218 1.47266 2.75V13.25C1.47266 13.6478 1.62396 14.0294 1.89327 14.3107C2.16259 14.592 2.52786 14.75 2.90874 14.75H10.0891C10.47 14.75 10.8353 14.592 11.1046 14.3107C11.3739 14.0294 11.5252 13.6478 11.5252 13.25V5M7.93501 1.25L11.5252 5M4.34482 11.75H8.65305M4.34482 8.75H8.65305"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
                <span className="font-lexend text-white">Generate CSV Report</span>
                </button>
              </CSVLink>
        )}
      </div>
    </div>
  );
});

export default Report;
