import React, { useState, useEffect, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import PercentIcon from "@mui/icons-material/Percent";
import EvStationOutlinedIcon from "@mui/icons-material/EvStationOutlined";
import PodcastsOutlinedIcon from "@mui/icons-material/PodcastsOutlined";
import {
  FormControl,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  alpha,
  InputBase,
  styled,
  MenuItem,
  Stack,
  TextField,
  TextareaAutosize,
  FormHelperText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAppSelector } from "../../../store/store";
import "./addEdit.scss";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { AddChargerSchema } from "../../../ValidationSchema/ValidationSchema";
import { z } from "zod";

const AddNewVendor = lazy(() => import("./AddNewVendor"));
interface PropTypes {
  show: boolean;
  handleClose: any;
  type: string;
  data: any;
}


type Vendor = {
  id: string;
  name: string;
  tradename: string;
};

interface Values {
  cpo: { id: string; name: string };
  loc_id: string;
  id: string;
  serial: string;
  power: string;
  status: string;
  connectionType: string;
  connector: { id: string; name: string };
  location: string;
  isEnabled: string;
  disabledReason: string;
  user: string;
  duration: string;
  connected: string;
  energyConsumed: string;
  vendor: { id: string; name: string };
  chargerId: string;
  chargerSerial: string;
  tariff: string;
  taxRate: string;
  inclusiveTax: string;
  currentType: string;
  locationObject: string;
  isOcpp: string;
  chargingRate: string;
}

const Input = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",

    border: "1px solid #ced4da",
    fontSize: 16,

    padding: "10px 12px",
    marginBottom: "20px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

function AddEditChargerModal(props: PropTypes) {
  const initialValues = {
    cpo: { id: "", name: "" },
    loc_id: props?.data?.location,
    id: "",
    serial: "",
    power: "",
    status: "",
    connectionType: "ws://",
    connector: { id: "", name: "" },
    location: props?.data?.location,
    isEnabled: "",
    disabledReason: "",
    user: "",
    duration: "",
    connected: "",
    energyConsumed: "",
    vendor: { id: "", name: "" },
    chargerId: "",
    chargerSerial: "",
    tariff: "",
    taxRate: "",
    inclusiveTax: "",
    currentType: "",
    locationObject: "",
    isOcpp: "true",
    chargingRate: "",
  };

  const [values, setValues] = React.useState<Values>(initialValues);

  const [chargerId, setChargerId] = useState<string[]>([]);
  const [selectedVendor, setSelectedVendor] = useState<string | "">("");
  const [openSelect, setOpenSelect] = useState<boolean>(false);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const handleVendorChange = (event: SelectChangeEvent<string>) => {
    const selectedVendorId = event.target.value;
    const selectedVendor = allVendorsType.find(
      (vendor: Vendor) => vendor.id === selectedVendorId
    );
    if (selectedVendor) {
      setValues({
        ...values,
        vendor: { id: selectedVendor.id, name: selectedVendor.name },
      });
    }
    setSelectedVendor(event.target.value);
  };

  const handleOpenModal = () => {
    setDropdownOpen(false); // Close the dropdown
    setModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // Validation error code
  type FormErrors = Partial<Record<keyof Values, string>>;
  const [errors, setErrors] = useState<FormErrors>({
    cpo: "",
    loc_id: props?.data?.location,
    id: "",
    serial: "",
    power: "",
    status: "",
    connectionType: "ws://",
    connector: "",
    location: props?.data?.location,
    isEnabled: "",
    disabledReason: "",
    user: "",
    duration: "",
    connected: "",
    energyConsumed: "",
    vendor: "",
    chargerId: "",
    chargerSerial: "",
    tariff: "",
    taxRate: "",
    inclusiveTax: "",
    currentType: "",
    locationObject: "",
    isOcpp: "true",
    chargingRate: "",
  });

  type AddLocationSchemaKeys = keyof typeof AddChargerSchema.shape;

  const validateField = (
    name: AddLocationSchemaKeys,
    value: string
  ): string => {
    const partialSchema = z.object({
      [name]: AddChargerSchema.shape[name],
    });
    try {
      partialSchema.parse({ [name]: value });
      return "";
    } catch (e) {
      if (e instanceof z.ZodError) {
        return e.errors[0]?.message || "Invalid input";
      }
      return "Invalid input";
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name in AddChargerSchema.shape) {
      const error = validateField(name as AddLocationSchemaKeys, value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error,
      }));
    }
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSelectChange = (e: SelectChangeEvent<string>, name: string) => {
    const { value } = e.target;
    if (name in AddChargerSchema.shape) {
      const error = validateField(name as AddLocationSchemaKeys, value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error,
      }));
    }
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const validateForm = (): boolean => {
    let valid = true;
    const newErrors: FormErrors = {};
    for (const key in values) {
      const field = key as keyof Values;
      if (field in AddChargerSchema.shape) {
        const error = validateField(
          field as AddLocationSchemaKeys,
          values[field] as string
        );
        if (error) valid = false;
        newErrors[field] = error;
      }
    }
    setErrors(newErrors);
    return valid;
  };

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                       For Getting All Existing Charger ID                      ||
  // ! ||--------------------------------------------------------------------------------||
  const getChargerId = async () => {
    try {
      const parseQuery = new Parse.Query("Chargers");
      const results = await parseQuery.find();
      // Extract charger IDs from results and update state
      const chargerIds = results.map((result) => result.get("ChargeId"));
      setChargerId(chargerIds);
    } catch (error) {
      console.error("Error fetching charger IDs:", error);
    }
  };

  useEffect(() => {
    getChargerId();
  }, [props.show]);

  // ! ||--------------------------------------------------------------------------------||
  // ! ||             This Function is To Handle Existing Charger ID                     ||
  // ! ||--------------------------------------------------------------------------------||
  let userDetail: any = sessionStorage.getItem("user-details");
  useEffect(() => {
    if (!props.show) return;
    setValues(props.data);
  }, [props]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const currentUser: any = Parse.User.current();

  const allConnectorType = useAppSelector(
    (state) => state.connectors.connectors
  );

  const [allVendorsType, setVendorList] = useState<any>([]);

  const getVendorList = async () => {
    const parseQuery = new Parse.Query("CP_Vendor");
    try {
      // Fetch the results from Parse Query
      const results = await parseQuery.find();
      // Process the results
      const newRow = results.map((user) => {
        // Ensure that the fields are available
        const objectId = user.id;
        const name = user.get("Name");
        const tradeName = user.get("TradeName");

        // Return the new object with the fields
        return {
          id: objectId ?? "", // Default to an empty string if undefined
          name: name ?? "", // Default to an empty string if undefined
          tradename: tradeName ?? "", // Default to an empty string if undefined
        };
      });

      setVendorList(newRow);
    } catch (error) {
      console.error("Error while fetching vendors", error);
    }
  };

  useEffect(() => {
    getVendorList();
  }, []);

  const allCPOs = useAppSelector((state) => state.cpo.cpo);

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                             For Adding New Charger                             ||
  // ! ||--------------------------------------------------------------------------------||

  

  const submitAddCharger = () => {
   try {
    // Chargers IDs of null and undefined is getting removed .
    const cleanedChargerId = chargerId.filter((id) => id !== null && id !== "");
    // Check if chargerId already exists
    if (cleanedChargerId.includes(values.chargerId)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        chargerId: "Charger ID already exists",
      }));
      return; // Abort submission
    }
    if (validateForm()) {
      let myNewObject: Parse.Object = new Parse.Object("Chargers");
      myNewObject.set("Location", {
        __type: "Pointer",
        className: "Locations",
        objectId: values.loc_id || values.location, /// Location Id Is " "
        // objectId: values.location, /// Location Id Is " "
      });
      myNewObject.set("isOCPP", values.isOcpp === "true" ? true : false);
      myNewObject.set("Serial", values.serial);
      myNewObject.set("Power", values.power);
      myNewObject.set("Status", values.status);
      myNewObject.set("Connector", values.connector.name);
      myNewObject.set("CurrentType", values.currentType);
      myNewObject.set("Brand", values.vendor.name);
      myNewObject.set(
        "ChargingRate",
        parseFloat(values.power.replace("kW", ""))
      );
      if (currentUser && !JSON.parse(userDetail).isSuperAdmin) {
        myNewObject.set("CPO", {
          __type: "Pointer",
          className: "ChargePointOperators",
          objectId: currentUser.get("CPO").id,
        });
      } else {
        myNewObject.set("CPO", {
          __type: "Pointer",
          className: "ChargePointOperators",
          objectId: values.cpo.id,
        });
      }

      myNewObject.set("ConnectorType", {
        __type: "Pointer",
        className: "ConnectorTypes",
        objectId: values.connector.id,
      });
      myNewObject.set("Vendor", {
        __type: "Pointer",
        className: "CP_Vendor",
        objectId: values.vendor.id,
      });
      myNewObject.set("ChargeId", values.chargerId);
      myNewObject.set("Cost", parseInt(values.tariff));
      myNewObject.set("TaxRate", parseInt(values.taxRate));
      myNewObject.set("isEnabled", values.isEnabled === "true" ? true : false);
      myNewObject.set("DisableReason", values.disabledReason);
      myNewObject.set(
        "inclusiveTax",
        values.inclusiveTax === "true" ? true : false
      );

      myNewObject.save().then(() => {
        
        alert("Charger Added successfully");
        props.handleClose();
        window.location.reload();
      });
    }
   } catch (error) {
    alert("Error: Unable To Add the charger at this moment.")
   }
    
  };

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                        For Edit Existing Charger Details                       ||
  // ! ||--------------------------------------------------------------------------------||
  const submitEditCharger = () => {
    if (
      values.serial &&
      values.power &&
      values.vendor.name &&
      values.connector.name &&
      values.tariff &&
      values.taxRate
      //  &&
      // (values.chargerId || values.isOcpp === "false")
    ) {
      let myNewObject: Parse.Object = new Parse.Object("Chargers");
      myNewObject.set("objectId", values.id);
      myNewObject.set("Serial", values.serial);
      myNewObject.set("Power", values.power);
      myNewObject.set("isOCPP", values.isOcpp === "true" ? true : false);
      myNewObject.set("Connector", values.connector.name);
      myNewObject.set("CurrentType", values.currentType);
      myNewObject.set(
        "ChargingRate",
        parseFloat(values.power.replace("kW", ""))
      );
      myNewObject.set("Brand", values.vendor.name);
      if (currentUser && !JSON.parse(userDetail).isSuperAdmin) {
        myNewObject.set("CPO", {
          __type: "Pointer",
          className: "ChargePointOperators",
          objectId: currentUser.get("CPO").id,
        });
      } else {
        myNewObject.set("CPO", {
          __type: "Pointer",
          className: "ChargePointOperators",
          objectId: values.cpo.id,
        });
      }
      myNewObject.set("ConnectorType", {
        __type: "Pointer",
        className: "ConnectorTypes",
        objectId: values.connector.id,
      });
      myNewObject.set("Vendor", {
        __type: "Pointer",
        className: "CP_Vendor",
        objectId: values.vendor.id,
      });
      myNewObject.set(
        "ChargeId",
        values.isEnabled === "false" ? "" : values.chargerId
      );
      myNewObject.set("Cost", parseInt(values.tariff));
      myNewObject.set("TaxRate", parseInt(values.taxRate));
      myNewObject.set("isEnabled", values.isEnabled === "true" ? true : false);
      myNewObject.set("DisableReason", values.disabledReason);
      myNewObject.set(
        "inclusiveTax",
        values.inclusiveTax === "true" ? true : false
      );
      myNewObject.save().then((res) => {
        alert("Charger Edited successfully");
        window.location.reload();
        setValues(initialValues);
      });
    } else {
      alert("Please Enter All Mandatory Fields");
    }
  };

  useEffect(() => {
    if (!props.show) return;
    else if (props.type !== "add" && props.data) {
      setValues({
        ...props.data,
        currentType: allConnectorType.filter(
          (item: any) => item.name === props.data.connector.name
        )[0]?.type,
      });
    }
  }, [props]);
  const handleSubmitCharger = () => {
    if (props.type === "add") {
      submitAddCharger();
    } else {
      submitEditCharger();
    }
  };
  return (
    <Dialog
      PaperProps={{
        sx: {
          maxWidth: 1515,
          height: 1000,
          zIndex: 10000,
        },
      }}
      maxWidth={"xl"}
      fullScreen={fullScreen}
      open={props.show}
      onClose={props.handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <div className="headItem">
          <span className="head1">
            {" "}
            {props.type == "add" ? "Add Charger" : "Edit Charger"}
          </span>{" "}
          <span className="head2" onClick={props.handleClose}>
            <CloseIcon />
          </span>
        </div>
      </DialogTitle>
      <DialogContent>
        {/* Main Form Container */}
        <div className="flex" style={{ width: "900px" }}>
          {/* Right Side Form Code */}
          <div style={{ width: "47%", marginRight: "3%" }}>
            <h2
              className="font-lexend text-2xl mt-5"
              style={{ color: "#808080" }}
            >
              CPO
            </h2>
            <div className="flex flex-col ">
              {!JSON.parse(userDetail).isSuperAdmin ? (
                // CPO Input if User has CPO
                <FormControl>
                  <TextField
                    sx={{ marginTop: "18px" }}
                    variant="standard"
                    value={currentUser.get("CPO").get("Name")}
                    disabled={!JSON.parse(userDetail).isSuperAdmin}
                  />
                </FormControl>
              ) : (
                // CPO Input if User has Not CPO
                <FormControl variant="standard">
                  <Select
                    fullWidth
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{ marginBottom: "20px" }}
                    value={values.cpo.id}
                    onChange={(e) => {
                      const selectedCpo = allCPOs.find(
                        (item: any) => item.id === e.target.value
                      );
                      setValues({
                        ...values,
                        cpo: { id: selectedCpo?.id, name: selectedCpo?.name },
                      });
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        cpo: validateField("cpo", selectedCpo?.name || ""),
                      }));
                    }}
                  >
                    <MenuItem disabled value="">
                      <em className="font-lexend">CPO</em>
                    </MenuItem>

                    {allCPOs.map((item: any) => (
                      <MenuItem
                        value={item.id}
                        onClick={() =>
                          setValues({
                            ...values,
                            cpo: { id: item.id, name: item.name },
                          })
                        }
                      >
                        <span className="font-lexend">{item.name}</span>
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {values.cpo?.name === "" && (
                      <span className="font-lexend text-red-600">
                        {errors.cpo}
                      </span>
                    )}
                  </FormHelperText>
                </FormControl>
              )}

              <h2
                className="font-lexend text-2xl mt-5 mb-3 "
                style={{ color: "#808080" }}
              >
                Basic Info
              </h2>

              {/* Serial Number */}
              <FormControl variant="standard">
                <label className="flex items-center" htmlFor="Serial Number">
                  <span>
                    <svg
                      className="mr-2"
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.25 4H7.75M10 6.25C10 6.44891 10.079 6.63968 10.2197 6.78033C10.3603 6.92098 10.5511 7 10.75 7H12.25C12.4489 7 12.6397 6.92098 12.7803 6.78033C12.921 6.63968 13 6.44891 13 6.25V1.75C13 1.55109 12.921 1.36032 12.7803 1.21967C12.6397 1.07902 12.4489 1 12.25 1H10.75C10.5511 1 10.3603 1.07902 10.2197 1.21967C10.079 1.36032 10 1.55109 10 1.75V3.25C10 3.44891 10.079 3.63968 10.2197 3.78033C10.3603 3.92098 10.5511 4 10.75 4H13M1 2.5V5.5C1 5.89782 1.15804 6.27936 1.43934 6.56066C1.72064 6.84196 2.10218 7 2.5 7C2.89782 7 3.27936 6.84196 3.56066 6.56066C3.84196 6.27936 4 5.89782 4 5.5V2.5C4 2.10218 3.84196 1.72064 3.56066 1.43934C3.27936 1.15804 2.89782 1 2.5 1C2.10218 1 1.72064 1.15804 1.43934 1.43934C1.15804 1.72064 1 2.10218 1 2.5Z"
                        stroke="#111111"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="font-lexend">
                    Serial Number <span className="text-red-500 text-l">*</span>
                  </span>
                </label>
                <TextField
                  id="input-with-sx"
                  variant="standard"
                  placeholder="Enter Serial"
                  value={values.serial}
                  name="serial"
                  // onChange={(e) =>
                  //   setValues({
                  //     ...values,
                  //     serial: e.target.value,
                  //   })
                  // }
                  onChange={handleInputChange}
                  sx={{
                    "& .MuiInputBase-input": {
                      fontFamily: "lexend, sans-serif", // Apply Lexend font family
                    },
                    "& .MuiFormHelperText-root": {
                      fontFamily: "lexend, sans-serif", // Apply Lexend font family to helper text
                    },
                  }}
                  error={!!errors.serial}
                  helperText={errors.serial}
                />
              </FormControl>
            </div>
            <div className="flex flex-col ">
              {/* Vendor */}
              <FormControl variant="standard" sx={{ display: "flex" }}>
                <label
                  className="flex items-center mt-5"
                  htmlFor="Select Vendor"
                >
                  <span>
                    <svg
                      className="mr-2"
                      width="15"
                      height="17"
                      viewBox="0 0 15 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 13.25H13.5M11.25 11V15.5M1.5 14.75V13.25C1.5 12.4544 1.81607 11.6913 2.37868 11.1287C2.94129 10.5661 3.70435 10.25 4.5 10.25H7.5M3 4.25C3 5.04565 3.31607 5.80871 3.87868 6.37132C4.44129 6.93393 5.20435 7.25 6 7.25C6.79565 7.25 7.55871 6.93393 8.12132 6.37132C8.68393 5.80871 9 5.04565 9 4.25C9 3.45435 8.68393 2.69129 8.12132 2.12868C7.55871 1.56607 6.79565 1.25 6 1.25C5.20435 1.25 4.44129 1.56607 3.87868 2.12868C3.31607 2.69129 3 3.45435 3 4.25Z"
                        stroke="#111111"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="font-lexend">
                    Vendor <span className="text-red-500 text-l">*</span>
                  </span>
                </label>
                <div>
                  <Select
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{ width: "100%", marginBottom: "20px" }}
                    // value={selectedVendor || values?.vendor?.name}
                    value={values.vendor.id}
                    // value={selectedVendor == "" ? values?.vendor?.name  : selectedVendor }
                    open={dropdownOpen}
                    onOpen={() => setDropdownOpen(true)}
                    onClose={() => setDropdownOpen(false)}
                    onChange={handleVendorChange}
                    // name={values?.vendor?.id}
                    // value={values.vendor.id}
                    // value={values.vendor.name}
                  >
                    <MenuItem disabled value="">
                      <em className="font-lexend">Vendor</em>
                    </MenuItem>

                    {allVendorsType.map((item: Vendor) => (
                      <MenuItem
                        key={item.id} // Add a unique key for each MenuItem
                        value={item.id}
                        // onClick={() =>
                        //   setValues({
                        //     ...values,
                        //     vendor: { id:  item.id, name: item.name },
                        //   })
                        // }
                      >
                        <span className="font-lexend">{item.name}</span>
                      </MenuItem>
                    ))}

                    <MenuItem>
                      <button
                        style={{
                          padding: "4px 0px",
                          background: "#1AC47D",
                          border: "2px solid #1AAD70",
                        }}
                        className="px-4 ml-2"
                        onClick={handleOpenModal}
                      >
                        <div className="flex items-center px-3">
                          <span className="mr-2">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.5 8H10M7.75 5.75V10.25M1 8C1 8.88642 1.17459 9.76417 1.51381 10.5831C1.85303 11.4021 2.35023 12.1462 2.97703 12.773C3.60382 13.3998 4.34794 13.897 5.16689 14.2362C5.98583 14.5754 6.86358 14.75 7.75 14.75C8.63642 14.75 9.51417 14.5754 10.3331 14.2362C11.1521 13.897 11.8962 13.3998 12.523 12.773C13.1498 12.1462 13.647 11.4021 13.9862 10.5831C14.3254 9.76417 14.5 8.88642 14.5 8C14.5 7.11358 14.3254 6.23583 13.9862 5.41689C13.647 4.59794 13.1498 3.85382 12.523 3.22703C11.8962 2.60023 11.1521 2.10303 10.3331 1.76381C9.51417 1.42459 8.63642 1.25 7.75 1.25C6.86358 1.25 5.98583 1.42459 5.16689 1.76381C4.34794 2.10303 3.60382 2.60023 2.97703 3.22703C2.35023 3.85382 1.85303 4.59794 1.51381 5.41689C1.17459 6.23583 1 7.11358 1 8Z"
                                stroke="white"
                                stroke-width="1.6"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                          <span className="text-white font-lexend">
                            {" "}
                            Add Vendor
                          </span>
                        </div>
                      </button>
                    </MenuItem>

                    {/* <AddNewVendor 
                  handleRefreshVendor={getVendorList}
                  closeSelectDropdown={() => setOpenSelect(false)}
                  /> */}
                  </Select>
                  <FormHelperText>
                    {values.vendor?.name === "" && (
                      <span className="font-lexend text-red-600">
                        {errors.vendor}
                      </span>
                    )}
                  </FormHelperText>
                </div>
                <Suspense fallback={<div>Loading...</div>}>
                  <AddNewVendor
                    open={modalOpen}
                    closeSelectDropdown={() => setOpenSelect(false)}
                    openSelectDropdown={() => setOpenSelect(true)}
                    handleClose={handleCloseModal}
                    handleRefreshVendor={getVendorList}
                  />
                </Suspense>
              </FormControl>

              {/* Max Power */}
              <FormControl variant="standard">
                <label className="flex items-center mt-5" htmlFor="Max Power">
                  <span>
                    <svg
                      className="mr-2"
                      width="15"
                      height="17"
                      viewBox="0 0 15 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 13.25H13.5M11.25 11V15.5M1.5 14.75V13.25C1.5 12.4544 1.81607 11.6913 2.37868 11.1287C2.94129 10.5661 3.70435 10.25 4.5 10.25H7.5M3 4.25C3 5.04565 3.31607 5.80871 3.87868 6.37132C4.44129 6.93393 5.20435 7.25 6 7.25C6.79565 7.25 7.55871 6.93393 8.12132 6.37132C8.68393 5.80871 9 5.04565 9 4.25C9 3.45435 8.68393 2.69129 8.12132 2.12868C7.55871 1.56607 6.79565 1.25 6 1.25C5.20435 1.25 4.44129 1.56607 3.87868 2.12868C3.31607 2.69129 3 3.45435 3 4.25Z"
                        stroke="#111111"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="font-lexend">
                    Max Power <span className="text-red-500 text-l">*</span>
                  </span>
                </label>
                <Select
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{ width: "100%", marginBottom: "20px" }}
                  value={values.power}
                  onChange={(e) => handleSelectChange(e, "power")}
                >
                  <MenuItem disabled value="">
                    <em className="font-lexend">Max Power</em>
                  </MenuItem>

                  <MenuItem
                    value="3.3 kW"
                    // onClick={() =>
                    //   setValues({
                    //     ...values,
                    //     power: "3.3 kW",
                    //   })
                    // }
                  >
                    <span className="font-lexend">3.3kW</span>
                  </MenuItem>
                  <MenuItem
                    value="7.2 kW"
                    // onClick={() =>
                    //   setValues({
                    //     ...values,
                    //     power: "7.2 kW",
                    //   })
                    // }
                  >
                    <span className="font-lexend">7.2kW</span>
                  </MenuItem>
                  <MenuItem
                    value="7.4 kW"
                    // onClick={() =>
                    //   setValues({
                    //     ...values,
                    //     power: "7.4 kW",
                    //   })
                    // }
                  >
                    <span className="font-lexend">7.4kW</span>
                  </MenuItem>
                  <MenuItem
                    value="11 kW"
                    // onClick={() =>
                    //   setValues({
                    //     ...values,
                    //     power: "11 kW",
                    //   })
                    // }
                  >
                    <span className="font-lexend">11kW</span>
                  </MenuItem>
                  <MenuItem
                    value="15 kW"
                    // onClick={() =>
                    //   setValues({
                    //     ...values,
                    //     power: "15 kW",
                    //   })
                    // }
                  >
                    <span className="font-lexend">15kW</span>
                  </MenuItem>
                  <MenuItem
                    value="22 kW"
                    // onClick={() =>
                    //   setValues({
                    //     ...values,
                    //     power: "22 kW",
                    //   })
                    // }
                  >
                    <span className="font-lexend">22kW</span>
                  </MenuItem>
                  <MenuItem
                    value="25 kW"
                    // onClick={() =>
                    //   setValues({
                    //     ...values,
                    //     power: "25 kW",
                    //   })
                    // }
                  >
                    <span className="font-lexend">25kW</span>
                  </MenuItem>
                  <MenuItem
                    value="30 kW"
                    // onClick={() =>
                    //   setValues({
                    //     ...values,
                    //     power: "30 kW",
                    //   })
                    // }
                  >
                    <span className="font-lexend">30kW</span>
                  </MenuItem>
                  <MenuItem
                    value="60 kW"
                    // onClick={() =>
                    //   setValues({
                    //     ...values,
                    //     power: "60 kW",
                    //   })
                    // }
                  >
                    <span className="font-lexend">60kW</span>
                  </MenuItem>
                  <MenuItem
                    value="120 kW"
                    // onClick={() =>
                    //   setValues({
                    //     ...values,
                    //     power: "120 kW",
                    //   })
                    // }
                  >
                    <span className="font-lexend">120kW</span>
                  </MenuItem>
                  <MenuItem
                    value="250 kW"
                    // onClick={() =>
                    //   setValues({
                    //     ...values,
                    //     power: "250 kW",
                    //   })
                    // }
                  >
                    <span className="font-lexend">250kW</span>
                  </MenuItem>
                </Select>
                <FormHelperText>
                  {" "}
                  <span className="font-lexend text-red-600">
                    {errors.power}
                  </span>
                </FormHelperText>
              </FormControl>

              {/* Connector */}
              <FormControl variant="standard">
                <label className="flex items-center" htmlFor="Connector">
                  <span>
                    <svg
                      className="mr-2"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 13L3.625 10.375M9.25 1L6.625 3.625M13 4.75L10.375 7.375M5.33877 2.5L11.5 8.66125L9.95952 10.2017C9.55791 10.6194 9.07704 10.9528 8.54504 11.1824C8.01304 11.412 7.44059 11.5332 6.86119 11.5389C6.28179 11.5445 5.70708 11.4346 5.17067 11.2155C4.63427 10.9964 4.14695 10.6725 3.73724 10.2628C3.32752 9.85306 3.00363 9.36575 2.78452 8.82934C2.56541 8.29294 2.45548 7.71822 2.46116 7.13882C2.46685 6.55942 2.58803 5.98698 2.81762 5.45497C3.0472 4.92297 3.38059 4.4421 3.79827 4.0405L5.33877 2.5Z"
                        stroke="#111111"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="font-lexend">
                    Connector <span className="text-red-500 text-l">*</span>
                  </span>
                </label>
                <Select
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{ width: "100%" }}
                  value={values.connector.id}
                >
                  <MenuItem disabled value="">
                    <em className="font-lexend">Connector</em>
                  </MenuItem>
                  {allConnectorType.map(
                    (item: { id: string; name: string; type: string }) => (
                      <MenuItem
                        value={item.id}
                        onClick={() =>
                          setValues({
                            ...values,
                            connector: { id: item.id, name: item.name },
                            currentType: item.type,
                          })
                        }
                      >
                        <span className="font-lexend">{item.name}</span>
                      </MenuItem>
                    )
                  )}
                </Select>

                <FormHelperText>
                  {values.connector?.name === "" && (
                    <span className="font-lexend text-red-600">
                      {errors.connector}
                    </span>
                  )}
                </FormHelperText>
              </FormControl>

              {/* AC / DC */}
              <div className="flex">
                <div className="mt-6 flex">
                  <input
                    className="custom-checkbox"
                    type="checkbox"
                    style={{ width: "20px", height: "20px" }}
                    checked={values.currentType === "AC"}
                    disabled
                  />
                  <label htmlFor="AC" className="font-lexend ml-2">
                    AC
                  </label>
                </div>
                <div className="mt-6 flex ml-5">
                  <input
                    className="custom-checkbox"
                    type="checkbox"
                    style={{ width: "20px", height: "20px" }}
                    checked={values.currentType === "DC"}
                    disabled
                  />
                  <label htmlFor="AC" className="font-lexend ml-2">
                    DC
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/* Left side Form Container */}
          <div style={{ width: "47%" }}>
            <h2
              className="font-lexend text-2xl mb-3 "
              style={{ color: "#808080" }}
            >
              Cost
            </h2>

            <div style={{ width: "100% !important" }}>
              <FormControl variant="standard">
                <label className="flex items-center" htmlFor="Charging Tarrif">
                  <span>
                    <svg
                      className="mr-2"
                      width="11"
                      height="12"
                      viewBox="0 0 11 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.5 0.75H1.25H3.5C4.29565 0.75 5.05871 1.06607 5.62132 1.62868C6.18393 2.19129 6.5 2.95435 6.5 3.75C6.5 4.54565 6.18393 5.30871 5.62132 5.87132C5.05871 6.43393 4.29565 6.75 3.5 6.75H1.25L5.75 11.25M1.25 3.75H9.5"
                        stroke="#111111"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="font-lexend">
                    Charging Tariff{" "}
                    <span className="text-red-500 text-l">*</span>
                  </span>
                </label>
                <TextField
                  id="input-with-sx"
                  type="number"
                  variant="standard"
                  placeholder="Enter Tariff"
                  name="tariff"
                  value={values.tariff}
                  // onChange={(e) =>
                  //   setValues({
                  //     ...values,
                  //     tariff: e.target.value,
                  //   })
                  // }
                  onChange={handleInputChange}
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                    "& .MuiInputBase-input": {
                      fontFamily: "lexend, sans-serif", // Apply Lexend font family
                    },
                    "& .MuiFormHelperText-root": {
                      fontFamily: "lexend, sans-serif", // Apply Lexend font family to helper text
                    },
                  }}
                  error={!!errors.tariff}
                  helperText={errors.tariff}
                />
              </FormControl>
              <FormControl variant="standard" sx={{ marginLeft: "18px" }}>
                <label className="flex items-center" htmlFor="Tax Rate">
                  <span>
                    <PercentIcon sx={{ width: "19px", marginRight: "5px" }} />
                  </span>
                  <span className="font-lexend">
                    Tax Rate <span className="text-red-500 text-l">*</span>
                  </span>
                </label>
                <TextField
                  id="input-with-sx"
                  variant="standard"
                  placeholder="Enter Amount"
                  defaultValue="₹"
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                    "& .MuiInputBase-input": {
                      fontFamily: "lexend, sans-serif", // Apply Lexend font family
                    },
                    "& .MuiFormHelperText-root": {
                      fontFamily: "lexend, sans-serif", // Apply Lexend font family to helper text
                    },
                    height: 48,
                  }}
                  type="number"
                  value={values.taxRate}
                  // onChange={(e) =>
                  //   setValues({
                  //     ...values,
                  //     taxRate: e.target.value,
                  //   })
                  // }
                  name="taxRate"
                  onChange={handleInputChange}
                  error={!!errors.taxRate}
                  helperText={errors.taxRate}
                />
              </FormControl>
            </div>

            <div className="flex mt-5">
              {/* <FormControl variant="standard">
              <label className="flex items-center" htmlFor="Description">
                <span>
                  <svg
                    className="mr-2"
                    width="11"
                    height="12"
                    viewBox="0 0 11 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.5 0.75H1.25H3.5C4.29565 0.75 5.05871 1.06607 5.62132 1.62868C6.18393 2.19129 6.5 2.95435 6.5 3.75C6.5 4.54565 6.18393 5.30871 5.62132 5.87132C5.05871 6.43393 4.29565 6.75 3.5 6.75H1.25L5.75 11.25M1.25 3.75H9.5"
                      stroke="#111111"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <span className="font-lexend">Charging Rate</span>
              </label>
              <TextField
                id="input-with-sx"
                variant="standard"
                placeholder="Enter Amount"
                defaultValue="₹"
                type="number"
                value={values.chargingRate}
                onChange={(e) =>
                  setValues({
                    ...values,
                    chargingRate: e.target.value,
                  })
                }
              />
            </FormControl> */}
            </div>

            {/* Cost and Tax code */}
            <div className="flex justify-between items-center">
              {/* Tax Code */}
              <div
                className="mt-5"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "48%",
                }}
              >
                <label className="flex items-center" htmlFor="Description">
                  <span>
                    <svg
                      className="mr-2"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.75037 8.00035L7.25037 9.50035L10.2504 6.50035M2.75035 4.40035C2.75035 3.96274 2.92418 3.54305 3.23362 3.23362C3.54305 2.92418 3.96274 2.75035 4.40035 2.75035H5.15035C5.58602 2.7501 6.00391 2.57755 6.31285 2.27035L6.83785 1.74535C6.99118 1.59115 7.17349 1.46878 7.37428 1.38528C7.57507 1.30178 7.79038 1.25879 8.00785 1.25879C8.22531 1.25879 8.44062 1.30178 8.64141 1.38528C8.8422 1.46878 9.02451 1.59115 9.17785 1.74535L9.70285 2.27035C10.0118 2.57785 10.4303 2.75035 10.8653 2.75035H11.6153C12.053 2.75035 12.4726 2.92418 12.7821 3.23362C13.0915 3.54305 13.2653 3.96274 13.2653 4.40035V5.15035C13.2653 5.58535 13.4378 6.00385 13.7453 6.31285L14.2703 6.83785C14.4245 6.99118 14.5469 7.17349 14.6304 7.37428C14.7139 7.57507 14.7569 7.79038 14.7569 8.00785C14.7569 8.22531 14.7139 8.44062 14.6304 8.64141C14.5469 8.8422 14.4245 9.02451 14.2703 9.17785L13.7453 9.70285C13.4381 10.0118 13.2656 10.4297 13.2653 10.8653V11.6153C13.2653 12.053 13.0915 12.4726 12.7821 12.7821C12.4726 13.0915 12.053 13.2653 11.6153 13.2653H10.8653C10.4297 13.2656 10.0118 13.4381 9.70285 13.7453L9.17785 14.2703C9.02451 14.4245 8.8422 14.5469 8.64141 14.6304C8.44062 14.7139 8.22531 14.7569 8.00785 14.7569C7.79038 14.7569 7.57507 14.7139 7.37428 14.6304C7.17349 14.5469 6.99118 14.4245 6.83785 14.2703L6.31285 13.7453C6.00391 13.4381 5.58602 13.2656 5.15035 13.2653H4.40035C3.96274 13.2653 3.54305 13.0915 3.23362 12.7821C2.92418 12.4726 2.75035 12.053 2.75035 11.6153V10.8653C2.7501 10.4297 2.57755 10.0118 2.27035 9.70285L1.74535 9.17785C1.59115 9.02451 1.46878 8.8422 1.38528 8.64141C1.30178 8.44062 1.25879 8.22531 1.25879 8.00785C1.25879 7.79038 1.30178 7.57507 1.38528 7.37428C1.46878 7.17349 1.59115 6.99118 1.74535 6.83785L2.27035 6.31285C2.57755 6.00391 2.7501 5.58602 2.75035 5.15035V4.40035Z"
                        stroke="#111111"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="font-lexend">Inclusive Tax</span>
                </label>
                <div className="flex">
                  <div className="flex mt-4 mr-5">
                    <input
                      type="radio"
                      style={{
                        background: "black",
                        width: "20px",
                        height: "20px",
                      }}
                      checked={values.inclusiveTax === "true"}
                      onClick={() =>
                        setValues({
                          ...values,
                          inclusiveTax: "true",
                        })
                      }
                    />
                    <label htmlFor="" className="font-lexend  ml-2">
                      True
                    </label>
                  </div>

                  <div className="flex mt-4">
                    <input
                      type="radio"
                      style={{
                        background: "black",
                        width: "20px",
                        height: "20px",
                      }}
                      checked={values.inclusiveTax === "false"}
                      onClick={() =>
                        setValues({
                          ...values,
                          inclusiveTax: "false",
                        })
                      }
                    />
                    <label htmlFor="" className="font-lexend ml-2">
                      False
                    </label>
                  </div>
                </div>
              </div>
              {/* isOcpp Code */}
              <div
                className="mt-5"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "48%",
                }}
              >
                <label className="flex items-center" htmlFor="Description">
                  <EvStationOutlinedIcon
                    sx={{ marginRight: "5px", height: "20px" }}
                  />
                  <span className="font-lexend">Is OCPP</span>
                </label>
                <div className="flex">
                  <div className="flex mt-4 mr-5">
                    <input
                      type="radio"
                      style={{
                        background: "black",
                        width: "20px",
                        height: "20px",
                      }}
                      checked={values.isOcpp === "true"}
                      onChange={() => setValues({ ...values, isOcpp: "true" })}
                    />
                    <label htmlFor="" className="font-lexend ml-2">
                      True
                    </label>
                  </div>
                  <div className="flex mt-4">
                    <input
                      type="radio"
                      style={{
                        background: "black",
                        width: "20px",
                        height: "20px",
                      }}
                      checked={values.isOcpp === "false"}
                      onChange={() => setValues({ ...values, isOcpp: "false" })}
                    />
                    <label htmlFor="" className="font-lexend ml-2">
                      False
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <Stack gap={2} direction="column">
              {/* <FormControlLabel
              control={
                <Switch
                  checked={values.isOcpp === "true"}
                  onChange={() =>
                    setValues({
                      ...values,
                      isOcpp: values.isOcpp === "true" ? "false" : "true",
                    })
                  }
                  name="antoine"
                />
              }
              label="is OCPP"
            /> */}
              {values.isOcpp === "true" ? (
                <h2
                  className="font-lexend text-2xl mt-5 "
                  style={{ color: "#808080" }}
                >
                  OCPP Setup
                </h2>
              ) : (
                ""
              )}
            </Stack>
            <br />
            {values.isOcpp === "true" ? (
              <>
                <FormControl variant="standard">
                  <label className="flex items-center" htmlFor="Description">
                    <span>
                      <svg
                        className="mr-2"
                        width="15"
                        height="17"
                        viewBox="0 0 15 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 13.25H13.5M11.25 11V15.5M1.5 14.75V13.25C1.5 12.4544 1.81607 11.6913 2.37868 11.1287C2.94129 10.5661 3.70435 10.25 4.5 10.25H7.5M3 4.25C3 5.04565 3.31607 5.80871 3.87868 6.37132C4.44129 6.93393 5.20435 7.25 6 7.25C6.79565 7.25 7.55871 6.93393 8.12132 6.37132C8.68393 5.80871 9 5.04565 9 4.25C9 3.45435 8.68393 2.69129 8.12132 2.12868C7.55871 1.56607 6.79565 1.25 6 1.25C5.20435 1.25 4.44129 1.56607 3.87868 2.12868C3.31607 2.69129 3 3.45435 3 4.25Z"
                          stroke="#111111"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    <span className="font-lexend">Connection Type </span>
                  </label>
                  <Select
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{ width: 395, marginBottom: "20px" }}
                    // value={values.connectionType}
                    // defaultValue="ws://"
                    value={values.connectionType || "ws://"}
                  >
                    <MenuItem disabled value="">
                      <em>Connection Type</em>
                    </MenuItem>

                    <MenuItem
                      value="ws://"
                      onClick={() =>
                        setValues({
                          ...values,
                          connectionType: "ws://",
                        })
                      }
                    >
                      <span className="font-lexend">ws://</span>
                    </MenuItem>
                    <MenuItem
                      value="wss://"
                      onClick={() =>
                        setValues({
                          ...values,
                          connectionType: "wss://",
                        })
                      }
                    >
                      <span className="font-lexend">wss://</span>
                    </MenuItem>
                  </Select>
                </FormControl>

                {values.chargerId || values.connectionType ? (
                  <Stack direction="row" gap={1} marginTop={2}>
                    <Input
                      id="bootstrap-input"
                      sx={{
                        width: 380,
                        fontFamily: "lexend",
                        fontWeight: "600",
                      }}
                      value={`${
                        values.connectionType || "ws://"
                      }ocpp.chargecity.co.in/${values.chargerId}/${
                        values.chargerSerial || ""
                      }`}
                    />{" "}
                    <ContentCopyIcon
                      className="copy-icon"
                      onClick={() =>
                        navigator.clipboard.writeText(
                          `${values.connectionType}ocpp.chargecity.co.in/${
                            values.chargerId
                          }/${values.chargerSerial || ""}`
                        )
                      }
                    />
                  </Stack>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
            {values.isOcpp === "true" ? (
              <div style={{ display: "flex", width: "100%" }}>
                <FormControl variant="standard">
                  <label
                    className="flex items-center mt-5"
                    htmlFor="Description"
                  >
                    <span>
                      <svg
                        className="mr-2"
                        width="15"
                        height="17"
                        viewBox="0 0 15 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 13.25H13.5M11.25 11V15.5M1.5 14.75V13.25C1.5 12.4544 1.81607 11.6913 2.37868 11.1287C2.94129 10.5661 3.70435 10.25 4.5 10.25H7.5M3 4.25C3 5.04565 3.31607 5.80871 3.87868 6.37132C4.44129 6.93393 5.20435 7.25 6 7.25C6.79565 7.25 7.55871 6.93393 8.12132 6.37132C8.68393 5.80871 9 5.04565 9 4.25C9 3.45435 8.68393 2.69129 8.12132 2.12868C7.55871 1.56607 6.79565 1.25 6 1.25C5.20435 1.25 4.44129 1.56607 3.87868 2.12868C3.31607 2.69129 3 3.45435 3 4.25Z"
                          stroke="#111111"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    <span className="font-lexend">
                      Charger Id <span className="text-red-500 text-l">*</span>
                    </span>
                  </label>
                  <TextField
                    id="input-with-sx"
                    variant="standard"
                    value={values.chargerId}
                    sx={{
                      "& .MuiInputBase-input": {
                        fontFamily: "lexend, sans-serif", // Apply Lexend font family
                      },
                      "& .MuiFormHelperText-root": {
                        fontFamily: "lexend, sans-serif", // Apply Lexend font family to helper text
                      },
                    }}
                    name="chargerId"
                    onChange={handleInputChange}
                    error={!!errors.chargerId}
                    helperText={errors.chargerId}
                  />
                </FormControl>

                <FormControl variant="standard" style={{ marginLeft: "15px" }}>
                  <label
                    className="flex items-center mt-5"
                    htmlFor="Description"
                  >
                    <span>
                      <svg
                        className="mr-2"
                        width="15"
                        height="17"
                        viewBox="0 0 15 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 13.25H13.5M11.25 11V15.5M1.5 14.75V13.25C1.5 12.4544 1.81607 11.6913 2.37868 11.1287C2.94129 10.5661 3.70435 10.25 4.5 10.25H7.5M3 4.25C3 5.04565 3.31607 5.80871 3.87868 6.37132C4.44129 6.93393 5.20435 7.25 6 7.25C6.79565 7.25 7.55871 6.93393 8.12132 6.37132C8.68393 5.80871 9 5.04565 9 4.25C9 3.45435 8.68393 2.69129 8.12132 2.12868C7.55871 1.56607 6.79565 1.25 6 1.25C5.20435 1.25 4.44129 1.56607 3.87868 2.12868C3.31607 2.69129 3 3.45435 3 4.25Z"
                          stroke="#111111"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    <span className="font-lexend"> Charger Serial</span>
                  </label>

                  <TextField
                    id="input-with-sx"
                    variant="standard"
                    value={values.chargerSerial}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        chargerSerial: e.target.value,
                      })
                    }
                    sx={{
                      "& .MuiInputBase-input": {
                        fontFamily: "lexend, sans-serif", // Apply Lexend font family
                      },
                      "& .MuiFormHelperText-root": {
                        fontFamily: "lexend, sans-serif", // Apply Lexend font family to helper text
                      },
                    }}
                  />
                </FormControl>
              </div>
            ) : (
              ""
            )}
            {/* Enable Charger */}
            <div
              className="mt-5"
              style={{ display: "flex", flexDirection: "column", width: "48%" }}
            >
              <label className="flex items-center" htmlFor="Description">
                <PodcastsOutlinedIcon
                  sx={{ marginRight: "5px", height: "20px" }}
                />
                <span className="font-lexend">Enable Charger</span>
              </label>
              <div className="flex">
                <div className="flex mt-4 mr-5">
                  <input
                    type="radio"
                    style={{
                      background: "black",
                      width: "20px",
                      height: "20px",
                    }}
                    checked={values.isEnabled === "true"}
                    onChange={() => setValues({ ...values, isEnabled: "true" })}
                  />
                  <label htmlFor="" className="font-lexend ml-2">
                    True
                  </label>
                </div>
                <div className="flex mt-4">
                  <input
                    type="radio"
                    style={{
                      background: "black",
                      width: "20px",
                      height: "20px",
                    }}
                    checked={values.isEnabled === "false"}
                    onChange={
                      () =>
                        setValues({
                          ...values,
                          isEnabled: "false",
                          chargerId: "",
                        }) // charger id null when charger is disabled
                    }
                  />
                  <label htmlFor="" className="font-lexend ml-2">
                    False
                  </label>
                </div>
              </div>
            </div>

            <div className="mt-4 flex">
              {values.isEnabled === "false" ? (
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={3}
                  value={values.disabledReason}
                  placeholder="Enter Reason for Disable"
                  onChange={(e) =>
                    setValues({
                      ...values,
                      disabledReason: e.target.value,
                    })
                  }
                  style={{
                    width: 500,
                    border: "1px solid gray",
                    padding: "7px 10px",
                    borderRadius: "5px",
                  }}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <div>
          <br />
        </div>
      </DialogContent>
      <DialogActions>
        <div className="flex justify-end mx-5 pb-5">
          <button
            className="flex justify-around items-center px-3 py-2 rounded-full mr-3"
            style={{ background: "#1AC47D", border: "2px solid #1AAD70" }}
            onClick={() => {
              handleSubmitCharger();
            }}
          >
            <span className="mr-2">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.75 9L7.5 12.75L15 5.25"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span style={{ color: "white" }}>Submit</span>
          </button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default AddEditChargerModal;
