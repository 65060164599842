import "./report.scss";

import {
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  TextField,
  InputLabel,
  ListItemText,
  ListSubheader,
  Input,
  Chip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { memo, useEffect, useState } from "react";
import { Stack } from "@mui/system";
import moment from "moment";

import InvoiceModal from "./invoice";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
interface dataObj {
  id: string;
  label: string;
}

interface LocationFilter {
  id: string;
  label: string;
}

const Report = memo(() => {
  const [selectPeriod, setSelectPeriod] = useState("");

  const [allLocations, setAllLocations] = useState<any | null>([]);
  const [allLocationType, setAllLocationType] = useState<any | null>([]);
  const [allCity, setAllCity] = useState<any | null>([]);
  const [openInvoice, setOpenInvoice] = useState(false);
  const [openCustom, setOpenCustom] = useState<boolean>(false);
  const [SingleDateChips, setSingleDateFilterChips] = useState<string | null>(
    null
  );
  const [dateFilter, setDateFilter] = useState("");
  const [dateRangeChipVisible, setDateRangeChipVisible] =
    useState<boolean>(false);

  // Filter For Date Range Picker Function
  const dateRangeChange = (dates: any) => {
    const [start, end] = dates;
    console.log("DateRange", dates);

    setCustomDateFilter(start);
    setStartDateFilter(start);
    setEndDateFilter(end);
    setEndDateFilter(end);
    setDateRangeChipVisible(!!start && !!end);
    setSingleDateFilterChips(start);
  };

  const [dateChips, setDateFilterChips] = useState<any[]>([]);

  const [locationTypeChips, setLocationTypeChips] = useState("");
  const [chargerTypeChips, setChargerTypeChips] = useState("");
  const [locationFilter, setLocationFilter] = useState<LocationFilter[]>([]);
  const [locationChips, setLocationChips] = useState<
    { id: string; label: string }[]
  >([]);
  const [filterChips, setFilterChips] = useState<
    { id: string; label: string }[]
  >([]);
  const [locationIds, setLocationIds] = useState<any[]>([]);


  // Get All Locations
  const getAllLocations = () => {
    const Locations = Parse.Object.extend("Locations");

    const parseQuery = new Parse.Query(Locations);

    parseQuery.limit(50);

    parseQuery.find().then((result) => {
      let addressOptions: dataObj[] = [];
      let locationArray: any[] = [];
      let locationType: any[] = [];
      let cityType: any[] = [];
      result.forEach((item, index) => {
        if (
          !cityType.find(function (i) {
            return i === item.get("City");
          })
        ) {
          cityType.push(item.get("City"));
        }

        if (
          !locationType.find(function (i) {
            return i === item.get("LocationType");
          })
        ) {
          locationType.push(item.get("LocationType"));
        }

        let locPoint = item.get("GeoLocation");

        locationArray.push({
          id: item.id,
          stationName: `${item.get("Name")}`,
          type: `${item.get("LocationType")}`,
          city: `${item.get("City")}`,
          access: `${
            item.get("hasRestrictedAccess") ? "Restricted" : "Public"
          }`,
          operator: "Charge City",
          address: `${item.get("Address")}`,
          state: `${item.get("State")}`,
          lat: `${locPoint.latitude}`,
          long: `${locPoint.longitude}`,
          openingTime: `${moment(item.get("OpenTime"), "hh:mm A")}`,
          closingTime: `${moment(item.get("CloseTime"), "hh:mm A")}`,
          electricityTariff: `${item.get("ElectricityTariff")}`,
          isEnabled: `${item.get("isEnabled") ? "true" : "false"}`,
          modelType: `${item.get("RevenueModel")}`,
          currency: "₹",
          revenueAmount: `${item.get("RevenueAmount")}`,
          revenuePercent: `${item.get("RevenueSharingType")}`,
        });

        addressOptions.push({
          id: item.id,
          label: item.get("Name"),
        });
      });

      setAllCity(allCity.length ? allCity : cityType);
      setAllLocationType(
        allLocationType.length ? allLocationType : locationType
      );
    });
  };

  useEffect(() => {
    // code for location
    if (Array.isArray(locationFilter)) {
      const locationIds = locationFilter.map((user: any) => user.id);

      setLocationIds(locationIds);

      if (locationIds.length > 0) {
        // Your logic when userFilter is an array
      }
    } else if (locationFilter && typeof locationFilter === "object") {
      const locationId = (locationFilter as { id?: string }).id;

      setLocationIds(locationId ? [locationId] : []);

      // Your logic when userFilter is an object
    } else {
      // Handle other cases when userFilter is not an array or object
    }
  }, [locationFilter]);

  let userDetail: any = sessionStorage.getItem("user-details");
  const getFilteredLocations = () => {
    const chargersQuery = new Parse.Query("Chargers");
    if (currentUser) {
      chargersQuery.equalTo("CPO", currentUser.get("CPO"));
    }

    chargersQuery.find().then((chargeResult) => {
      let locArray: any = [];
      chargeResult.map((chargePoint) => {
        if (!locArray.includes(chargePoint.get("Location").id)) {
          locArray.push(chargePoint.get("Location").id);
        }
      });

      const locationQuery = new Parse.Query("Locations");
      !JSON.parse(userDetail).isSuperAdmin &&
        locationQuery.containedIn("objectId", locArray);
      locationQuery.find().then((result) => {
        let addressArray: any[] = [];

        result.forEach((item) => {
          let locPoint = item.get("GeoLocation");
          addressArray.push({
            id: item.id,
            label: item.get("Name"),
          });
          setAllLocations(addressArray);
        });
      });
    });
  };
  const [allConnectorType, setAllConnectorType] = useState<any>([]);

  useEffect(() => {
    getAllLocations();
    getFilteredLocations();
  }, []);
  const [reportData, setReportData] = useState<any>([]);
  const [allUsers, setAllUsers] = useState<any>([]);
  const [csvState, setCsvState] = useState<any>([]);
  const payConfirm = () => {
    window.confirm("Do you want to proceed");
  };
  const [totalRevenue, setTotalRevenue] = useState(0);

  const [userFilter, setUserFilter] = useState({ id: "", label: "" });
  // const [locationFilter, setLocationFilter] = useState<any>([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [chargerFilter, setChargerFilter] = useState("");
  const [currentTypeFilter, setCurrentTypeFilter] = useState("");
  const [chargerTypeFilter, setChargerTypeFilter] = useState({
    label: "",
    type: "",
  });

  const [startDateFilter, setStartDateFilter] = useState<any>("");
  const [endDateFilter, setEndDateFilter] = useState<any>("");
  const [customDateFilter, setCustomDateFilter] = useState<any>("");
  const [locationTypeFilter, setLocationTypeFilter] = useState<any>([]);

  const [connectorTypeLoading, setConnectorTypeLoading] = useState(false);
  const getAllConnectorType = (currentType: string) => {
    const Locations = Parse.Object.extend("ConnectorTypes");

    const parseQuery = new Parse.Query(Locations);
    if (currentType) {
      parseQuery.equalTo("CurrentType", currentType);
    }
    setConnectorTypeLoading(true);
    parseQuery.limit(50);
    parseQuery.find().then((result) => {
      let connectorArray: any = [];
      result.forEach((item) => {
        connectorArray.push({
          label: item.get("Name"),
          type: item.get("CurrentType"),
        });
      });
      setConnectorTypeLoading(false);
      setAllConnectorType(connectorArray);
    });
  };

  const handleConnectorChange = (newValue: any) => {
    console.log("newValue Connector", newValue);

    setChargerTypeChips(`Type ${newValue.type} ${newValue.label}`);
    setChargerFilter((prevValue) => (prevValue === newValue ? "" : newValue));
    setChargerTypeFilter(newValue);
    setChargerTypeFilter(
      newValue === chargerTypeFilter ? { label: "", type: "" } : newValue
    );

    setCurrentTypeFilter("");
  };
  const handleDateChange = (selectedFilter: any) => {
   console.log("selectedFilter",selectedFilter);
    // const existingChip = dateChips.find((chip) => chip.label === values);

    // // If the selected date is not in the chips, update the chips array
    // if (!existingChip) {
    //   // Remove the previous date chip if it exists
    //   const updatedChips = dateChips.filter((chip) => chip.id !== dateFilter);

    //   // Add the selected date to the chips array
    //   setDateFilterChips([...updatedChips, { id: values, label: values }]);
    // }

    // Update the dateFilter state
    setDateFilter(selectedFilter);
    if(selectedFilter === ""){
      setSelectPeriod("");
   setOpenCustom(!openCustom)

    }else{
      setSelectPeriod(selectedFilter);
    }
    // You can add additional logic here, such as making API calls or updating other state variables
  };
  const handleLocationChange = (newValue: any) => {
    setLocationTypeChips(newValue);
    setStatusFilter((prevValue) => (prevValue === newValue ? "" : newValue));
    setLocationTypeFilter(newValue);
  };

  const loadSessions = (
    userId: string,
    locationId: any,
    locationType: any,
    connectorType: any,
    currentType: any,
    status: string,
    date: string,
    startDate: string | Date,
    endDate: string | Date,
    customDate: string | Date
  ) => {
    const parseQuery = new Parse.Query("ChargeSession");
    parseQuery.include("ChargePoint");
    if (userId) {
      let User = Parse.User;
      let userObj = User.createWithoutData(userId);
      parseQuery.equalTo("User", userObj);
    }
    if (currentUser) {
      var innerQuery = new Parse.Query("Chargers");

      innerQuery.equalTo("CPO", currentUser.get("CPO"));
      parseQuery.matchesQuery("ChargePoint", innerQuery);
    }
    if (locationId.length) {
      var innerQuery = new Parse.Query("Locations");
      innerQuery.containedIn(
        "objectId",
        locationId.map((item: any) => item.id)
      );
      parseQuery.matchesQuery("Location", innerQuery);
    }
    console.log("locationType", locationType);
    if (locationType.length) {
      var innerQuery = new Parse.Query("Locations");
      innerQuery.equalTo("LocationType", locationType);
      parseQuery.matchesQuery("Location", innerQuery);
    }
    if (connectorType.label) {
      var innerQuery = new Parse.Query("Chargers");
      innerQuery.equalTo("Connector", connectorType.label);
      parseQuery.matchesQuery("ChargePoint", innerQuery);
    }

    if (currentType) {
      var innerQuery = new Parse.Query("Chargers");

      var moreInnerQuery = new Parse.Query("ConnectorTypes");
      moreInnerQuery.equalTo("CurrentType", currentType);

      innerQuery.matchesQuery("ConnectorType", moreInnerQuery);
      parseQuery.matchesQuery("ChargePoint", innerQuery);
    }
    if (status) {
      status === "Completed"
        ? parseQuery.equalTo("Live", false)
        : parseQuery.equalTo("Live", true);
    }

    if (date) {
      if (date === "Today") {
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment().startOf("day").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "createdAt",
          new Date(moment().add(1, "day").startOf("day").toString())
        );
      } else if (date === "Yesterday") {
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment().subtract(1, "day").startOf("day").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "createdAt",
          new Date(moment().startOf("day").toString())
        );
      } else if (date === "This Week") {
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment().subtract(7, "day").startOf("day").toString())
        );
        parseQuery.lessThanOrEqualTo("createdAt", new Date());
      } else if (date === "Last Month") {
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment().subtract(1, "month").startOf("month").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "createdAt",
          new Date(moment().subtract(1, "month").endOf("month").toString())
        );
      }
    }

    if (startDate) {
      // Apply single date filter
      parseQuery.greaterThanOrEqualTo(
        "createdAt",
        new Date(moment(startDate).startOf("day").toString())
      );
      parseQuery.lessThanOrEqualTo(
        "createdAt",
        new Date(moment(startDate).endOf("day").toString())
      );
    }
    if (startDate && endDate) {
      parseQuery.greaterThanOrEqualTo("createdAt", new Date(startDate));
      parseQuery.greaterThanOrEqualTo("createdAt", new Date(startDate));
      const adjustedEndDate = moment(endDate).endOf("day").toDate();
      parseQuery.lessThanOrEqualTo("createdAt", adjustedEndDate);
    }

    // if (startDate) {
    //   parseQuery.greaterThanOrEqualTo("createdAt", new Date(startDate));
    // }
    // if (endDate) {
    //   parseQuery.lessThanOrEqualTo("createdAt", new Date(endDate));
    // }

    // Logic Need to be Handle
    // if (customDate) {
    //   parseQuery.greaterThanOrEqualTo(
    //     "createdAt",
    //     new Date(moment(customDate).startOf("day").toString())
    //   );
    //   parseQuery.lessThanOrEqualTo(
    //     "createdAt",
    //     new Date(moment(customDate).add(1, "day").startOf("day").toString())
    //   );
    // }

    parseQuery.include("ChargePoint");
    parseQuery.include("Location");
    parseQuery.include("Vehicle");
    parseQuery.include("User");
    parseQuery.descending("createdAt");
    parseQuery.limit(1000);
    parseQuery.find().then((result: any[]) => {
      let newRow: {
        id: number;
        status: string;
        startTime: string;
        endTime: string;
        chargeBy: string;
        chargeFor: string;
        customer: string;
        location: string;
        carCharged: string;
        aid: string;
        date: string;
        cost: string;
        energy: string;
        duration: string;
        obj: any;
        serialNum: string;
        power: string;
        connector: string;
      }[] = [];

      let usersArray: any[] = [];
      let totalEnergy = 0;
      let thisMonthEnergy = 0;
      let lastMonthEnergy = 0;
      let percentageEnergy = 0;
      let totalMoney = 0;
      let thisMonthMoney = 0;
      let lastMonthMoney = 0;
      let totalSession = 0;
      let thisMonthSession = 0;
      let lastMonthSession = 0;
      let csvRow: any[] = [
        [
          "Id",
          "Name",
          "Location",
          "Start Time",
          "End Time",
          "Cost",
          "Energy",
          "Serial",
          "Power",
          "Connector",
        ],
      ];
      let csvFirstRow: any[] = [
        ["Revenue", "Energy Consumed", "Total Session"],
      ];

      result.forEach((item, index) => {
        totalEnergy = item.get("TotalEnergyConsumed")
          ? item.get("TotalEnergyConsumed") + totalEnergy
          : 0 + totalEnergy;
        totalMoney = item.get("TotalCost")
          ? item.get("TotalCost") + totalMoney
          : 0 + totalMoney;
        totalSession = totalSession + 1;
        setTotalRevenue(totalMoney);
        let bc = [
          index + 1,
          `${item.get("User").get("FullName")}`,
          item.get("Location").get("Name"),
          `${
            moment(item.get("createdAt")).format("lll")
              ? moment(item.get("createdAt")).format("lll")
              : "-"
          }`,
          `${
            moment(item.get("updatedAt")).format("lll")
              ? moment(item.get("updatedAt")).format("lll")
              : "-"
          }`,
          `₹ ${parseFloat(item.get("TotalCost")).toFixed(2)}`,
          `${parseFloat(item.get("TotalEnergyConsumed")).toFixed(2)} kWh`,

          `${item.get("ChargePoint").get("Serial")}`,
          `${item.get("ChargePoint").get("Power")}`,
          `${item.get("ChargePoint").get("Connector")}`,
        ];

        csvRow.push(bc);

        let car = "";
        if (`${item.get("Vehicle")}` !== "undefined") {
          car = `${item.get("Vehicle").get("Name")}`;
        }

        const userObj = {
          id: `${item.get("User").id}`,
          label: `${item.get("User").get("FullName")}`,
        };

        if (
          !usersArray.find(function (i) {
            return i.id === userObj.id;
          })
        ) {
          usersArray.push(userObj);
        }

        newRow.push({
          id: index + 1,
          status: `${item.get("User").get("FullName")}`,
          customer: `${item.get("User").get("FullName")}`,
          location: `${item.get("Location").get("Name")}`,
          serialNum: `${item.get("ChargePoint").get("Serial")}`,
          power: `${item.get("ChargePoint").get("Power")}`,
          connector: `${item.get("ChargePoint").get("Connector")}`,
          startTime: `${
            moment(item.get("createdAt")).format("lll")
              ? moment(item.get("createdAt")).format("lll")
              : "-"
          }`,
          endTime: `${
            moment(item.get("updatedAt")).format("lll")
              ? moment(item.get("updatedAt")).format("lll")
              : "-"
          }`,
          carCharged: car,
          chargeBy: `${item.get("ChargeBy")}`,
          chargeFor: `${item.get("ChargeFor")}`,
          duration: `${
            moment
              .duration(
                moment(new Date(item.get("updatedAt"))).diff(
                  moment(new Date(item.get("createdAt")))
                )
              )
              .hours() +
            "hr" +
            " " +
            moment
              .duration(
                moment(new Date(item.get("updatedAt"))).diff(
                  moment(new Date(item.get("createdAt")))
                )
              )
              .minutes() +
            "min"
          }  `,
          aid: `${item.get("AID")}`,
          date: `${item.get("createdAt")}`,
          cost: `₹ ${parseFloat(item.get("TotalCost")).toFixed(2)}`,
          energy: `${parseFloat(item.get("TotalEnergyConsumed")).toFixed(
            2
          )} kWh`,
          obj: item,
        });
      });

      csvFirstRow.push([totalMoney, totalEnergy, totalSession], ["", "", ""]);
      setCsvState(csvFirstRow.concat(csvRow));
      setAllUsers(!allUsers.length ? usersArray : allUsers);
      setReportData(newRow);
    });
  };
  console.log("locationFilter", locationFilter);

  useEffect(() => {
    loadSessions(
      userFilter.id,
      locationFilter,
      locationTypeFilter,
      chargerTypeFilter,
      currentTypeFilter,
      statusFilter,
      selectPeriod,
      startDateFilter,

      endDateFilter,
      customDateFilter
    );
  }, [
    userFilter.id,
    locationFilter,
    locationTypeFilter,
    chargerTypeFilter,
    currentTypeFilter,
    statusFilter,
    selectPeriod,
    startDateFilter,
    endDateFilter,
    customDateFilter,
  ]);

  useEffect(() => {
    getAllConnectorType(currentTypeFilter);
  }, [currentTypeFilter]);

  console.log("chargerTypeFilter.label", chargerTypeFilter.label);

  const ReportFilter = (props: { data: any }) => {
    switch (props.data) {
      case "Location":
        return (
          <Autocomplete
            className="mb-4 mt-4 mx-4"
            freeSolo
            id="checkboxes-tags-demo"
            disableClearable
            multiple
            limitTags={-1}
            options={allLocations}
            // loading={loadingUsers}
            autoHighlight
            size="small"
            value={locationFilter} // Set the value to control the selected Location
            onChange={(event: any, newValue: any) => {
              console.log("New Value", newValue);

              setLocationChips((prevChips: { id: string; label: string }[]) => {
                // Ensure that newValue is an array of objects with 'id' property
                const newChips: { id: string; label: string }[] = newValue.map(
                  (user: any) => ({
                    id: user.id,
                    label: `Location: ${user.label}`,
                  })
                );

                const filteredChips = newChips.filter(
                  (chip) =>
                    !prevChips.some((prevChip) => prevChip.id === chip.id)
                );

                return [...prevChips, ...filteredChips];
              });
              setLocationFilter(newValue);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option: any) => option.label}
            getOptionDisabled={(option) =>
              filterChips.some((chip) => chip.id === option.id)
            }
            renderOption={(props, option) => {
              let counter = 0;
              const uniqueKey = `${option.id}_${option.label}_${counter++}`;
              const selected = filterChips.some(
                (chip) => chip.id === option.id
              );
              // console.log("selected", selected);

              return (
                <MenuItem key={uniqueKey} {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={
                      selected ||
                      filterChips.some((chip) => chip.id === option.id)
                    }
                  />
                  <ListItemText primary={option.label} />
                </MenuItem>
              );
            }}
            renderInput={(params: any) => (
              <TextField {...params} label="Select Location" />
            )}
          />
        );
      case "Location Type":
        return (
          <div className="flex mt-2">
            {allLocationType?.map((ele: any, index: number) => (
              <div className="flex items-center ml-3" key={index}>
                <input
                  type="radio"
                  className={`mr-1 ${index !== 0 ? "ml-4" : "ml-0"}`}
                  style={{
                    background: "black",
                    width: "20px",
                    height: "20px",
                  }}
                  checked={ele == locationTypeFilter}
                  onChange={() => handleLocationChange(ele)}
                />
                <label htmlFor="" className="font-lexend">
                  {ele}
                </label>
              </div>
            ))}
          </div>
        );

      case "Connector Type":
        return (
          <div className="connector_Type_container flex mt-2">
            {allConnectorType?.map((ele: any, index: number) => (
              <div className="flex items-center ml-3" key={index}>
                <input
                  type="radio"
                  className={`mr-1 ${index !== 0 ? "ml-4" : "ml-0"}`}
                  style={{
                    background: "black",
                    width: "20px",
                    height: "20px",
                  }}
                  checked={ele.label === chargerTypeFilter.label}
                  onChange={() => handleConnectorChange(ele)}
                />
                <label htmlFor="" className="font-lexend">
                  {ele?.label}
                </label>
              </div>
            ))}
          </div>
        );

      case "Current Type":
        if (chargerTypeFilter.label) {
          return (
            <>
              <div className="flex">
                <div className="flex items-center ml-3">
                  <input
                    type="radio"
                    style={{
                      background: "black",
                      width: "20px",
                      height: "20px",
                    }}
                    checked={chargerTypeFilter.type === "AC"}
                  />
                  <label htmlFor="" className="font-lexend">
                    AC
                  </label>
                </div>
                <div className="flex items-center ml-3">
                  <input
                    type="radio"
                    style={{
                      background: "black",
                      width: "20px",
                      height: "20px",
                    }}
                    checked={chargerTypeFilter.type === "DC"}
                  />
                  <label htmlFor="" className="font-lexend">
                    DC
                  </label>
                </div>
              </div>
            </>
          );
        } else {
          return (
            <div className="flex">
              <div className="flex items-center ml-3">
                <input
                  type="radio"
                  onClick={() => {
                    currentTypeFilter === "AC"
                      ? setCurrentTypeFilter("")
                      : setCurrentTypeFilter("AC");
                    setChargerTypeFilter({
                      label: "",
                      type: "",
                    });
                  }}
                  style={{
                    background: "black",
                    width: "20px",
                    height: "20px",
                  }}
                  checked={currentTypeFilter === "AC"}
                />
                <label htmlFor="" className="font-lexend ml-2">
                  AC
                </label>
              </div>
              <div className="flex items-center ml-5">
                <input
                  type="radio"
                  onClick={() => {
                    currentTypeFilter === "DC"
                      ? setCurrentTypeFilter("")
                      : setCurrentTypeFilter("DC");
                    setChargerTypeFilter({
                      label: "",
                      type: "",
                    });
                  }}
                  style={{
                    background: "black",
                    width: "20px",
                    height: "20px",
                  }}
                  checked={currentTypeFilter === "DC"}
                />
                <label htmlFor="" className="font-lexend ml-2">
                  DC
                </label>
              </div>
            </div>
          );
        }

      case "User":
        return (
          <FormControl>
            <Select
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{ width: 350 }}
              input={<OutlinedInput />}
              value={userFilter.id}
            >
              <MenuItem disabled value="">
                User
              </MenuItem>
              {allUsers.map((item: any) => (
                <MenuItem value={item.id} onClick={() => setUserFilter(item)}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case "CPO":
        return (
          <FormControl>
            <Select
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{ width: 350 }}
              input={<OutlinedInput />}
              value=""
            >
              <MenuItem disabled value="">
                CPO
              </MenuItem>

              <MenuItem>Charge City</MenuItem>
            </Select>
          </FormControl>
        );
      default:
        return null;
    }
  };
  const [invoiceUserFilter, setInvoiceUserFilter] = useState<any>([]);
  const [invoiceLocationFilter, setInvoiceLocationFilter] = useState<any>([]);
  const [gdata, setGdata] = useState<any>([]);

  const InvoiceFilter = (props: { data: any }) => {
    switch (props.data) {
      case "Location":
        return (
          <Autocomplete
            multiple
            sx={{ width: 350, backgroundColor: "white" }}
            id="tags-outlined"
            value={invoiceLocationFilter}
            onChange={(event, newValue: any) => {
              setInvoiceLocationFilter(newValue);
            }}
            options={allLocations}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                label="Locations"
                placeholder="Favorites"
              />
            )}
          />
        );

      case "User":
        return (
          <FormControl>
            <InputLabel id="demo-multiple-checkbox-label">Users</InputLabel>

            <Select
              sx={{ width: 350, backgroundColor: "white" }}
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              variant="outlined"
              value={invoiceUserFilter}
              onChange={(event: any) => {
                const {
                  target: { value },
                } = event;
                setInvoiceUserFilter(value);
              }}
              input={<OutlinedInput label="Users" />}
              renderValue={(selected) => {
                return selected.map((item: any) => item.label).join(", ");
              }}
              MenuProps={{
                PaperProps: { sx: { maxHeight: 400, maxWidth: 300 } },
              }}
            >
              <ListSubheader>Users</ListSubheader>
              {allUsers.map((item: any) => (
                <MenuItem key={item.id} value={item}>
                  <Checkbox
                    checked={
                      invoiceUserFilter
                        .map((item: any) => item.id)
                        .indexOf(item.id) > -1
                    }
                  />
                  <ListItemText primary={item.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );

      default:
        return null;
    }
  };
  const currentUser: any = Parse.User.current();
  const intitalPayoutFields = {
    cpo: currentUser.get("CPO").get("Name"),
    isProcessed: false,
    status: "Pending",

    amount: 0,
  };
  const [payoutFields, setPayoutFields] = useState(intitalPayoutFields);
  const columns = [
    {
      field: "id",
      headerName: "S.No.",
      renderCell: (params: any) => (
        <>
          <div className="font-lexend font-medium">{params?.row?.id}</div>
        </>
      ),
      width: 50,
    },
    {
      field: "cpo",
      headerName: "CPO",
      renderCell: (params: any) => (
        <>
          <div className="font-lexend font-medium">{params?.row?.cpo}</div>
        </>
      ),
      width: 150,
    },
    {
      field: "amount",
      headerName: "Amount",
      renderCell: (params: any) => (
        <>
          <div className="font-lexend font-medium">{params?.row?.amount}</div>
        </>
      ),
      width: 100,
    },
    {
      field: "createdAt",
      headerName: "Date",
      renderCell: (params: any) => (
        <>
          <div className="font-lexend font-medium">
            {params?.row?.createdAt}
          </div>
        </>
      ),
      width: 250,
    },

    {
      field: "status",
      width: 120,
      headerName: "Status",
      renderCell: (params: { row: { obj: any } }) => {
        let currentStatus = params.row.obj.get("Status");
        console.log("lHey", currentStatus);
        return currentStatus === "Approved" ? (
          <span className=" flex items-center" style={{ color: "#19885A" }}>
            <span>
              <svg
                width="14"
                height="10"
                viewBox="0 0 14 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.42969 5L5.17969 8.75L12.6797 1.25"
                  stroke="#19885A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span className="ml-2 font-lexend">Approved</span>
          </span>
        ) : (
          <div className="label-offline">
            <span className="labelText">{params.row.obj.get("Status")}</span>
          </div>
        );
      },
    },
  ];

  const submitPayout = () => {
    if (payoutFields.amount) {
      let myNewObject: Parse.Object = new Parse.Object("Payouts");
      myNewObject.set("CPO", currentUser.get("CPO"));
      myNewObject.set("isProcessed", payoutFields.isProcessed);
      myNewObject.set("Amount", Number(payoutFields.amount));
      myNewObject.set("Status", "Pending");

      myNewObject.save().then((res) => {
        alert("Payout Added Sucessfully");
        getAllPayoutData();
      });
    } else {
      window.alert("Please fill amount");
    }
  };
  console.log("allConnectorType", allConnectorType);

  const [allPayouts, setAllPayouts] = useState<any>([]);
  const [totalPayout, setTotalPayout] = useState<any>(0);
  const getAllPayoutData = () => {
    const Locations = Parse.Object.extend("Payouts");

    const parseQuery = new Parse.Query(Locations);
    parseQuery.include("ChargePoint");
    parseQuery.limit(50);

    parseQuery.find().then((result) => {
      let allPayoutsArray: any = [];
      let payout: number = 0;
      result.forEach((item, index) => {
        payout = payout + (item.get("Amount") || 0);
        allPayoutsArray.push({
          id: index + 1,

          cpo: `${item.get("CPO").get("Name")}`,
          isProcessed: `${item.get("isProcessed")}`,
          status: `${item.get("Status")}`,
          updatedAt: `${item.get("updatedAt")}`,
          amount: `${item.get("Amount")}`,
          createdAt: `${moment(item.get("createdAt")).format("lll")}`,
          obj: item,
        });
      });
      setTotalPayout(payout);
      setAllPayouts(allPayoutsArray);
    });
  };
  useEffect(() => {
    getAllPayoutData();
  }, []);
  console.log("allPay", allPayouts);
  const [invoiceTableData, setInvoiceTableData] = useState<any>([]);

  const loadInvoiceSessions = (
    userId: any,
    locationId: any,
    startDate: any,
    endDate: any
  ) => {
    const parseQuery = new Parse.Query("ChargeSession");
    parseQuery.include("ChargePoint");
    if (userId.length) {
      var innerQuery = new Parse.Query("User");
      innerQuery.containedIn(
        "objectId",
        userId.map((item: any) => item.id)
      );
      parseQuery.matchesQuery("User", innerQuery);
    }
    if (currentUser) {
      var innerQuery = new Parse.Query("Chargers");

      innerQuery.equalTo("CPO", currentUser.get("CPO"));
      parseQuery.matchesQuery("ChargePoint", innerQuery);
    }

    if (locationId.length) {
      var innerQuery = new Parse.Query("Locations");
      innerQuery.containedIn(
        "objectId",
        locationId.map((item: any) => item.id)
      );
      parseQuery.matchesQuery("Location", innerQuery);
    }
    if (startDate) {
      parseQuery.greaterThanOrEqualTo("createdAt", new Date(startDate));
    }
    if (endDate) {
      parseQuery.lessThanOrEqualTo("createdAt", new Date(endDate));
    }
    parseQuery.descending("createdAt");
    parseQuery.limit(1000);
    parseQuery.find().then((result: any[]) => {
      let newRow: any[] = [];

      let usersArray: any[] = [];
      let rawAmt: number = 0;
      let totalAmt: number = 0;
      let ab = locationId.map((item: any) => {
        return {
          name: item.label,
          rawAmt: 0,
          energy: 0,
          rate: 0,
        };
      });

      let bc = userId.map((item: any) => {
        return {
          name: item.label,
          rawAmt: 0,
          energy: 0,
          rate: 0,
        };
      });
      result.forEach((item, index) => {
        ab.forEach((element: any) => {
          if (
            String(element.name) === String(item.get("Location").get("Name"))
          ) {
            element.rawAmt = item.get("TotalCost")
              ? (parseFloat(item.get("TotalCost")) * 100) / 118 + element.rawAmt
              : 0 + element.rawAmt;
            element.energy = item.get("TotalEnergyConsumed")
              ? item.get("TotalEnergyConsumed") + element.energy
              : 0 + element.energy;
            element.rate =
              element.rate === 0
                ? (item.get("ChargePoint").get("Cost") * 100) / 118
                : element.rate;
          } else {
            return;
          }
        });
        bc.forEach((element: any) => {
          if (
            String(element.name) === String(item.get("User").get("FullName"))
          ) {
            element.rawAmt = item.get("TotalCost")
              ? (parseFloat(item.get("TotalCost")) * 100) / 118 + element.rawAmt
              : 0 + element.rawAmt;
            element.energy = item.get("TotalEnergyConsumed")
              ? item.get("TotalEnergyConsumed") + element.energy
              : 0 + element.energy;
            element.rate =
              element.rate === 0
                ? (item.get("ChargePoint").get("Cost") * 100) / 118
                : element.rate;
          } else {
            return;
          }
        });
        rawAmt = item.get("TotalCost")
          ? (parseFloat(item.get("TotalCost")) * 100) / 118 + rawAmt
          : 0 + rawAmt;
        totalAmt = item.get("TotalCost")
          ? item.get("TotalCost") + totalAmt
          : 0 + totalAmt;
        newRow.push({
          id: index + 1,
          location: `${item.get("Location").get("Name")}`,
          rate: (
            (parseFloat(item.get("ChargePoint").get("Cost")) * 100) /
            118
          ).toFixed(2),

          startTime: `${
            moment(item.get("createdAt")).format("lll")
              ? moment(item.get("createdAt")).format("lll")
              : "-"
          }`,
          endTime: `${
            moment(item.get("updatedAt")).format("lll")
              ? moment(item.get("updatedAt")).format("lll")
              : "-"
          }`,

          date: `${item.get("createdAt")}`,
          cost: ((parseFloat(item.get("TotalCost")) * 100) / 118).toFixed(2),
          energy: `${parseFloat(item.get("TotalEnergyConsumed")).toFixed(
            2
          )} kWh`,
          obj: item,
        });
      });
      setValues({ ...values, rawAmount: rawAmt, totalAmount: totalAmt });
      setInvoiceTableData(bc.length ? bc : ab);
    });
  };

  const [startDateInvoiceFilter, setStartDateInvoiceFilter] = useState<any>("");
  const [endDateInvoiceFilter, setEndDateInvoiceFilter] = useState<any>("");
  useEffect(() => {
    loadInvoiceSessions(
      invoiceUserFilter,
      invoiceLocationFilter,
      startDateInvoiceFilter,
      endDateInvoiceFilter
    );
  }, [
    invoiceUserFilter,
    invoiceLocationFilter,
    startDateInvoiceFilter,
    endDateInvoiceFilter,
  ]);

  const playersData = [
    { key: "id", label: "ID" },
    { key: "customer", label: "Name" },
    { key: "location", label: "Location" },
    {
      key: "isLive",

      label: "Status",
      renderCell: (params: {
        row: { obj: { get: (arg0: string) => any } };
      }) => {
        let currentStatus = params.row.obj.get("Live");
        return currentStatus === false ? (
          <div className="labelCompleted">
            <span className="labelText">Completed</span>
          </div>
        ) : (
          <div className="label">
            <span className="labelText">Charging</span>
          </div>
        );
      },
    },
    { key: "carCharged", label: "Vehicle" },

    { key: "startTime", label: "Start Time" },
    { key: "endTime", label: "End Time" },

    { key: "cost", label: "Cost" },

    { key: "energy", label: "Energy" },
    { key: "duration", label: "Duration" },
    { key: "serialNum", label: "Serial Number" },
    { key: "power", label: "Power" },
    { key: "connector", label: "Connector" },
  ];
  const csvLink = {
    filename: "Charge-City.csv",
    headers: playersData,
    data: reportData,
  };
  const initialValues = {
    name: "",
    email: "",
    address: "",
    gstNum: "",
    billto: "",
    invoiceNum: "",
    startDate: "",
    endDate: "",
    rawAmount: 0,
    totalAmount: 0,
  };

  const [values, setValues] = useState(initialValues);
  console.log("allLocationType", allLocationType);

  return (
    <div className="report_container md:flex flex-wrap">
     
      {/* PayOut Section */}
      <div className="payout_section sm:w-full">
        <h1 className="font-lexend text-2xl mt-3 ml-4">Payouts</h1>

        <div
          className="payout-sec flex justify-between mx-4 mt-3"
          style={{ width: "97%" }}
        >
          <div
            className="payout-container p-4 rounded-xl"
            style={{ width: "49%", background: "#F6F8FA" }}
          >
            <Stack direction="column" spacing={4}>
              <FormControl variant="standard">
                <InputLabel shrink htmlFor="bootstrap-input">
                  Name
                </InputLabel>
                <Input
                  id="bootstrap-input"
                  value={currentUser.get("CPO").get("Name")}
                />
              </FormControl>
              <FormControl variant="standard" >
                <InputLabel shrink htmlFor="bootstrap-input">
                  Amount available for payout
                </InputLabel>
                <Input
                  id="bootstrap-input"
                  defaultValue="₹"
                  value={(
                    totalRevenue -
                    (totalPayout + (2 / 100) * (totalRevenue + totalPayout))
                  ).toFixed(2)}
                />
              </FormControl>
              <FormControl variant="standard">
                <InputLabel shrink htmlFor="bootstrap-input">
                  Amount
                </InputLabel>
                <Input
                  id="bootstrap-input"
                  type="number"
                  inputProps={{
                    pattern: "[0-9]*", // Regular expression to allow only numeric values
                  }}
                  onKeyPress={(e) => {
                    if (e.key === '-' || e.key === 'e' || e.key === 'E') {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e: any) => {
                    if (
                      e.target.value <=
                      totalRevenue -
                        (totalPayout + (2 / 100) * (totalRevenue + totalPayout))
                    ) {
                      setPayoutFields({
                        ...payoutFields,
                        amount: e.target.value,
                      });
                    } else {
                      alert(
                        `Can'nt withdrawal more than ${
                          totalRevenue -
                          (totalPayout +
                            (2 / 100) * (totalRevenue + totalPayout))
                        }`
                      );
                    }
                  }}
                  
                />
          
              </FormControl>
              <div className="mt-4 ml-4 w-full">
                <button
                  className="flex justify-center items-center w-full py-2 rounded-full mr-3"
                  // style={{  background: "#1AC47D", border: "2px solid #1AAD70" }}
                  style={{
                    background: payoutFields.amount <= 0 ? "#29d48c" : "#1AC47D",
                    border: payoutFields.amount <= 0 ? "2px solid #CCCCCC" : "2px solid #1AAD70",
                    cursor: payoutFields.amount <= 0 ? "not-allowed" : "pointer"
                  }}
                  onClick={() => submitPayout()}
                  disabled={payoutFields.amount <= 0}
                >
                  <span className="mr-2 ml-5 ">
                    <svg
                      width="15"
                      height="10"
                      viewBox="0 0 15 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.875 5L5.625 8.75L13.125 1.25"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span style={{ color: "white" }} className="mr-5 font-lexend">
                    Confirm
                  </span>
                </button>
              </div>
            </Stack>
          </div>{" "}
          <div
            className="mx-4 p-4 rounded-xl"
            style={{ width: "49%", background: "#F6F8FA" }}
          >
            <h3 className="font-lexend text-xl">Payout Calculation</h3>
            <span className="flex justify-between mt-5">
              <p className="item-head font-lexend">Revenue</p>
              <span className="font-lexend">₹ {totalRevenue.toFixed(2)}</span>
            </span>
            <span className="flex justify-between mt-2">
              <p className="item-head font-lexend">Payouts</p>
              <span className="font-lexend">₹ {totalPayout.toFixed(2)}</span>
            </span>

            <span className="flex justify-between mt-2">
              <p className="item-head font-lexend">Payment Gateway Charges</p>
              <span className="font-lexend">
                ₹ {((2 / 100) * (totalRevenue + totalPayout)).toFixed(2)}
              </span>
            </span>
            <hr className="mt-4" />
            <span className="flex justify-between mt-2">
              <p className="item-head font-lexend">
                Amount Available for Payout
              </p>
              <span style={{ fontSize: "15px" }} className="font-lexend">
                ₹{" "}
                {(
                  totalRevenue -
                  (totalPayout + (2 / 100) * (totalRevenue + totalPayout))
                ).toFixed(2)}
              </span>
            </span>

            <div className="mt-14 w-full">
              <button
                className="flex justify-center items-center w-full py-2 rounded-full mr-3"
                style={{ background: "#1AC47D", border: "2px solid #1AAD70" }}
                onClick={() => setOpenInvoice(true)}
              >
                <span className="mr-4">
                  <svg
                    width="13"
                    height="16"
                    viewBox="0 0 13 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.25 4.25H8.75M4.25 7.25H8.75M4.25 10.25H7.25M1.25 2.75C1.25 2.35218 1.40804 1.97064 1.68934 1.68934C1.97064 1.40804 2.35218 1.25 2.75 1.25H10.25C10.6478 1.25 11.0294 1.40804 11.3107 1.68934C11.592 1.97064 11.75 2.35218 11.75 2.75V13.25C11.75 13.6478 11.592 14.0294 11.3107 14.3107C11.0294 14.592 10.6478 14.75 10.25 14.75H2.75C2.35218 14.75 1.97064 14.592 1.68934 14.3107C1.40804 14.0294 1.25 13.6478 1.25 13.25V2.75Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <span style={{ color: "white" }} className="font-lexend">
                  Preview Invoice
                </span>
              </button>
            </div>
          </div>
        </div>
        {/* Table Code */}
        <DataGrid
          rows={allPayouts}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[5]}
          checkboxSelection={false}
          disableSelectionOnClick
          autoHeight
          sx={{
            margin: "17px 18px",
            background: "#F6F8FA",
            border: "none",
            borderRadius: "12px",
          }}
        />
      </div>
      {/* Invoice Modal */}
      <InvoiceModal
        show={openInvoice}
        handleClose={() => setOpenInvoice(false)}
        data={values}
        tableData={invoiceTableData}
        location={invoiceLocationFilter}
      />
    </div>
  );
});

export default Report;

