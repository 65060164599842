import React from 'react';
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
export const FilterIcon = () => (
  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M3 3H15V4.629C14.9999 5.02679 14.8418 5.40826 14.5605 5.6895L11.25 9V14.25L6.75 15.75V9.375L3.39 5.679C3.13909 5.40294 3.00004 5.0433 3 4.67025V3Z"
                    stroke="#111111"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg> 
);

export const EditIcon = () => (
  <svg
              className="ml-1"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.33268 2.66635L9.33268 6.66635M0.666016 8.66635L8.46602 0.866353C8.59063 0.744201 8.75818 0.675781 8.93268 0.675781C9.10718 0.675781 9.27473 0.744201 9.39935 0.866353L11.1327 2.59969C11.2548 2.72431 11.3233 2.89185 11.3233 3.06635C11.3233 3.24085 11.2548 3.4084 11.1327 3.53302L3.33268 11.333H0.666016V8.66635Z"
                stroke="white"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
);
export const AddIcon = () => (
  <svg
  style={{ marginLeft: "2px" }}
  width="16"
  height="16"
  viewBox="0 0 16 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M5.75 8H10.25M8 5.75V10.25M1.25 8C1.25 8.88642 1.42459 9.76417 1.76381 10.5831C2.10303 11.4021 2.60023 12.1462 3.22703 12.773C3.85382 13.3998 4.59794 13.897 5.41689 14.2362C6.23583 14.5754 7.11358 14.75 8 14.75C8.88642 14.75 9.76417 14.5754 10.5831 14.2362C11.4021 13.897 12.1462 13.3998 12.773 12.773C13.3998 12.1462 13.897 11.4021 14.2362 10.5831C14.5754 9.76417 14.75 8.88642 14.75 8C14.75 7.11358 14.5754 6.23583 14.2362 5.41689C13.897 4.59794 13.3998 3.85382 12.773 3.22703C12.1462 2.60023 11.4021 2.10303 10.5831 1.76381C9.76417 1.42459 8.88642 1.25 8 1.25C7.11358 1.25 6.23583 1.42459 5.41689 1.76381C4.59794 2.10303 3.85382 2.60023 3.22703 3.22703C2.60023 3.85382 2.10303 4.59794 1.76381 5.41689C1.42459 6.23583 1.25 7.11358 1.25 8Z"
    stroke="white"
    stroke-width="1.6"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
);



export const CloseFilterIcon = () => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M3.33301 8.00033L6.66634 11.3337L13.333 4.66699"
      stroke="white"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

// Charge session Widget icons-----
export const ChargingDurationIcon = () => (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.795 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V5C1 4.46957 1.21071 3.96086 1.58579 3.58579C1.96086 3.21071 2.46957 3 3 3H15C15.5304 3 16.0391 3.21071 16.4142 3.58579C16.7893 3.96086 17 4.46957 17 5V9H1M13 1V5M5 1V5M16 14.496V16L17 17M12 16C12 17.0609 12.4214 18.0783 13.1716 18.8284C13.9217 19.5786 14.9391 20 16 20C17.0609 20 18.0783 19.5786 18.8284 18.8284C19.5786 18.0783 20 17.0609 20 16C20 14.9391 19.5786 13.9217 18.8284 13.1716C18.0783 12.4214 17.0609 12 16 12C14.9391 12 13.9217 12.4214 13.1716 13.1716C12.4214 13.9217 12 14.9391 12 16Z" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
);
export const EnergyConsumedIcon = () => (
    <ElectricalServicesIcon/>
);
export const PriceChangeSvgIcon = () => (
    <PriceChangeIcon/>
);
export const TotalChargeSessionIcon = () => (
    <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 3C2 2.46957 2.21071 1.96086 2.58579 1.58579C2.96086 1.21071 3.46957 1 4 1H15C15.5304 1 16.0391 1.21071 16.4142 1.58579C16.7893 1.96086 17 2.46957 17 3V3.5C17 3.63261 17.0527 3.75979 17.1464 3.85355C17.2402 3.94732 17.3674 4 17.5 4C17.6326 4 17.7598 4.05268 17.8536 4.14645C17.9473 4.24021 18 4.36739 18 4.5V7.5C18 7.63261 17.9473 7.75979 17.8536 7.85355C17.7598 7.94732 17.6326 8 17.5 8C17.3674 8 17.2402 8.05268 17.1464 8.14645C17.0527 8.24021 17 8.36739 17 8.5V9C17 9.53043 16.7893 10.0391 16.4142 10.4142C16.0391 10.7893 15.5304 11 15 11H10.5M4 16V13M2 9V6.5M6 9V6.5M1 9H7V11C7 11.5304 6.78929 12.0391 6.41421 12.4142C6.03914 12.7893 5.53043 13 5 13H3C2.46957 13 1.96086 12.7893 1.58579 12.4142C1.21071 12.0391 1 11.5304 1 11V9Z" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
);
// -----Charge session Widget icons

// Home Page Icons---------------
export const PeopleSvgIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 22 22" fill="none">
        <path d="M19.2354 11.4913C19.335 9.82122 18.9245 8.16037 18.0584 6.72901C17.1923 5.29765 15.9115 4.16343 14.3859 3.47682C12.8603 2.7902 11.162 2.58364 9.51625 2.88453C7.87053 3.18542 6.35517 3.97954 5.17121 5.16155C3.98724 6.34356 3.19061 7.85759 2.887 9.50281C2.58338 11.148 2.78714 12.8467 3.47122 14.3734C4.15531 15.9002 5.28741 17.1829 6.71733 18.0513C8.14725 18.9198 9.80742 19.333 11.4776 19.2362M17.4167 20.1666V14.6666M17.4167 14.6666L20.1667 17.4166M17.4167 14.6666L14.6667 17.4166M8.25006 9.16659H8.25922M13.7501 9.16659H13.7592M8.70839 13.7499C9.31156 14.3366 10.1384 14.6666 11.0001 14.6666C11.8617 14.6666 12.6886 14.3366 13.2917 13.7499" stroke="#354052" stroke-width="1.83333" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
);
export const EnergyConsumedSvgIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none">
        <path d="M16 7H17C17.5304 7 18.0391 7.21071 18.4142 7.58579C18.7893 7.96086 19 8.46957 19 9V9.5C19 9.63261 19.0527 9.75979 19.1464 9.85355C19.2402 9.94732 19.3674 10 19.5 10C19.6326 10 19.7598 10.0527 19.8536 10.1464C19.9473 10.2402 20 10.3674 20 10.5V13.5C20 13.6326 19.9473 13.7598 19.8536 13.8536C19.7598 13.9473 19.6326 14 19.5 14C19.3674 14 19.2402 14.0527 19.1464 14.1464C19.0527 14.2402 19 14.3674 19 14.5V15C19 15.5304 18.7893 16.0391 18.4142 16.4142C18.0391 16.7893 17.5304 17 17 17H15M8 7H6C5.46957 7 4.96086 7.21071 4.58579 7.58579C4.21071 7.96086 4 8.46957 4 9V15C4 15.5304 4.21071 16.0391 4.58579 16.4142C4.96086 16.7893 5.46957 17 6 17H7M12 8L10 12H13L11 16" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
);
export const BillRevenueSvgIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none">
  <path d="M15 8H9H10C10.7956 8 11.5587 8.31607 12.1213 8.87868C12.6839 9.44129 13 10.2044 13 11C13 11.7956 12.6839 12.5587 12.1213 13.1213C11.5587 13.6839 10.7956 14 10 14H9L12 17M9 11H15M3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3C10.8181 3 9.64778 3.23279 8.55585 3.68508C7.46392 4.13738 6.47177 4.80031 5.63604 5.63604C4.80031 6.47177 4.13738 7.46392 3.68508 8.55585C3.23279 9.64778 3 10.8181 3 12Z" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
);
export const AvgRevenueSvgIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none">
        <path d="M11 7C11 8.06087 10.5786 9.07828 9.82843 9.82843C9.07828 10.5786 8.06087 11 7 11C5.93913 11 4.92172 10.5786 4.17157 9.82843C3.42143 9.07828 3 8.06087 3 7C3 5.93913 3.42143 4.92172 4.17157 4.17157C4.92172 3.42143 5.93913 3 7 3M11 7C11 5.93913 10.5786 4.92172 9.82843 4.17157C9.07828 3.42143 8.06087 3 7 3M11 7H7V3M9 17V21M17 14V21M13 13V21M21 12V21" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
);
// ----------Home Page Icons