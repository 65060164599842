import { memo, useEffect, useState } from "react";
import "./invoice.scss";
import {
  MenuItem,
  Checkbox,
  Autocomplete,
  TextField,
  Input,
  ListItemText,
  Chip,
  Typography,
} from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import Person3OutlinedIcon from "@mui/icons-material/Person3Outlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

import moment from "moment";
import DatePicker from "react-datepicker";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "react-datepicker/dist/react-datepicker.css";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import InvoiceModal from "./inoviceModal";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
interface dataObj {
  id: string;
  label: string;
}

const Report = memo(() => {
  const [selectPeriod, setSelectPeriod] = useState("");
  const [locationChips, setLocationChips] = useState<
    { id: string; label: string }[]
  >([]);
  const [userChips, setUserChips] = useState<{ id: string; label: string }[]>(
    []
  );
  const [locationIds, setLocationIds] = useState<any[]>([]);
  const [userIds, setUserIds] = useState<any[]>([]);
  const [filterChips, setFilterChips] = useState<
    { id: string; label: string }[]
  >([]);
  const [allLocations, setAllLocations] = useState<any | null>([]);
  const [allLocationType, setAllLocationType] = useState<any | null>([]);
  const [allCity, setAllCity] = useState<any | null>([]);
  const [openInvoice, setOpenInvoice] = useState(false);
  const getAllLocations = () => {
    const Locations = Parse.Object.extend("Locations");

    const parseQuery = new Parse.Query(Locations);

    parseQuery.limit(50);

    parseQuery.find().then((result) => {
      let addressOptions: dataObj[] = [];
      let locationArray: any[] = [];
      let locationType: any[] = [];
      let cityType: any[] = [];
      result.forEach((item, index) => {
        if (
          !cityType.find(function (i) {
            return i === item.get("City");
          })
        ) {
          cityType.push(item.get("City"));
        }

        if (
          !locationType.find(function (i) {
            return i === item.get("LocationType");
          })
        ) {
          locationType.push(item.get("LocationType"));
        }

        let locPoint = item.get("GeoLocation");

        locationArray.push({
          id: item.id,
          stationName: `${item.get("Name")}`,
          type: `${item.get("LocationType")}`,
          city: `${item.get("City")}`,
          access: `${
            item.get("hasRestrictedAccess") ? "Restricted" : "Public"
          }`,
          operator: "Charge City",
          address: `${item.get("Address")}`,
          state: `${item.get("State")}`,
          lat: `${locPoint.latitude}`,
          long: `${locPoint.longitude}`,
          openingTime: `${moment(item.get("OpenTime"), "hh:mm A")}`,
          closingTime: `${moment(item.get("CloseTime"), "hh:mm A")}`,
          electricityTariff: `${item.get("ElectricityTariff")}`,
          isEnabled: `${item.get("isEnabled") ? "true" : "false"}`,
          modelType: `${item.get("RevenueModel")}`,
          currency: "₹",
          revenueAmount: `${item.get("RevenueAmount")}`,
          revenuePercent: `${item.get("RevenueSharingType")}`,
        });

        addressOptions.push({
          id: item.id,
          label: item.get("Name"),
        });
      });

      setAllCity(allCity.length ? allCity : cityType);
      setAllLocationType(
        allLocationType.length ? allLocationType : locationType
      );
    });
  };
  let userDetail: any = sessionStorage.getItem("user-details");
  const getFilteredLocations = () => {
    const chargersQuery = new Parse.Query("Chargers");
    if (currentUser) {
      chargersQuery.equalTo("CPO", currentUser.get("CPO"));
    }

    chargersQuery.find().then((chargeResult) => {
      let locArray: any = [];
      chargeResult.map((chargePoint) => {
        if (!locArray.includes(chargePoint.get("Location").id)) {
          locArray.push(chargePoint.get("Location").id);
        }
      });

      const locationQuery = new Parse.Query("Locations");
      !JSON.parse(userDetail).isSuperAdmin &&
        locationQuery.containedIn("objectId", locArray);
      locationQuery.find().then((result) => {
        let addressArray: any[] = [];

        result.forEach((item) => {
          let locPoint = item.get("GeoLocation");
          addressArray.push({
            id: item.id,
            label: item.get("Name"),
          });
          setAllLocations(addressArray);
        });
      });
    });
  };
  const [allConnectorType, setAllConnectorType] = useState<any>([]);

  useEffect(() => {
    getAllLocations();
    getFilteredLocations();
  }, []);
  const [reportData, setReportData] = useState<any>([]);
  const [allUsers, setAllUsers] = useState<any>([]);
  const loadSessions = (
    userId: string,
    locationId: any,
    locationType: any,
    connectorType: any,
    currentType: any,
    status: string,
    date: string,
    startDate: string | Date,
    endDate: string | Date,
    customDate: string | Date
  ) => {
    const parseQuery = new Parse.Query("ChargeSession");
    parseQuery.include("ChargePoint");
    if (userId) {
      let User = Parse.User;
      let userObj = User.createWithoutData(userId);
      parseQuery.equalTo("User", userObj);
    }
    if (currentUser) {
      var innerQuery = new Parse.Query("Chargers");

      innerQuery.equalTo("CPO", currentUser.get("CPO"));
      parseQuery.matchesQuery("ChargePoint", innerQuery);
    }
    if (locationId.length) {
      var innerQuery = new Parse.Query("Locations");
      innerQuery.containedIn(
        "objectId",
        locationId.map((item: any) => item.id)
      );
      parseQuery.matchesQuery("Location", innerQuery);
    }
    if (locationType.length) {
      var innerQuery = new Parse.Query("Locations");
      innerQuery.containedIn(
        "LocationType",
        locationType.map((item: any) => item)
      );
      parseQuery.matchesQuery("Location", innerQuery);
    }
    if (connectorType.label) {
      var innerQuery = new Parse.Query("Chargers");
      innerQuery.equalTo("Connector", connectorType.label);
      parseQuery.matchesQuery("ChargePoint", innerQuery);
    }

    if (currentType) {
      var innerQuery = new Parse.Query("Chargers");

      var moreInnerQuery = new Parse.Query("ConnectorTypes");
      moreInnerQuery.equalTo("CurrentType", currentType);

      innerQuery.matchesQuery("ConnectorType", moreInnerQuery);
      parseQuery.matchesQuery("ChargePoint", innerQuery);
    }
    if (status) {
      status === "Completed"
        ? parseQuery.equalTo("Live", false)
        : parseQuery.equalTo("Live", true);
    }

    if (date) {
      if (date === "Today") {
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment().startOf("day").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "createdAt",
          new Date(moment().add(1, "day").startOf("day").toString())
        );
      } else if (date === "Yesterday") {
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment().subtract(1, "day").startOf("day").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "createdAt",
          new Date(moment().startOf("day").toString())
        );
      } else if (date === "This Week") {
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment().subtract(7, "day").startOf("day").toString())
        );
        parseQuery.lessThanOrEqualTo("createdAt", new Date());
      } else if (date === "Last Month") {
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment().subtract(1, "month").startOf("month").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "createdAt",
          new Date(moment().subtract(1, "month").endOf("month").toString())
        );
      }
    }

    if (startDateFilter) {
      parseQuery.limit(1000);
      // Apply single date filter
      parseQuery.greaterThanOrEqualTo(
        "createdAt",
        new Date(moment(startDateFilter).startOf("day").toString())
      );
      parseQuery.lessThanOrEqualTo(
        "createdAt",
        new Date(moment(startDateFilter).endOf("day").toString())
      );
    }
    if (startDateFilter && endDateFilter) {
      parseQuery.limit(5000);
      parseQuery.greaterThanOrEqualTo("createdAt", new Date(startDateFilter));
      parseQuery.greaterThanOrEqualTo("createdAt", new Date(startDateFilter));
      const adjustedEndDate = moment(endDateFilter).endOf("day").toDate();
      parseQuery.lessThanOrEqualTo("createdAt", adjustedEndDate);
    }

    // if (customDate) {
    //   parseQuery.greaterThanOrEqualTo(
    //     "createdAt",
    //     new Date(moment(customDate).startOf("day").toString())
    //   );
    //   parseQuery.lessThanOrEqualTo(
    //     "createdAt",
    //     new Date(moment(customDate).add(1, "day").startOf("day").toString())
    //   );
    // }

    parseQuery.include("ChargePoint");
    parseQuery.include("Location");
    parseQuery.include("Vehicle");
    parseQuery.include("User");
    parseQuery.descending("createdAt");
    parseQuery.limit(1000);
    parseQuery.find().then((result: any[]) => {
      let newRow: {
        id: number;
        status: string;
        startTime: string;
        endTime: string;
        chargeBy: string;
        chargeFor: string;
        customer: string;
        location: string;
        carCharged: string;
        aid: string;
        date: string;
        cost: string;
        energy: string;
        duration: string;
        obj: any;
        serialNum: string;
        power: string;
        connector: string;
      }[] = [];

      let usersArray: any[] = [];

      result.forEach((item, index) => {
        let car = "";
        if (`${item.get("Vehicle")}` !== "undefined") {
          car = `${item.get("Vehicle").get("Name")}`;
        }

        const userObj = {
          id: `${item.get("User").id}`,
          label: `${item.get("User").get("FullName")}`,
        };

        if (
          !usersArray.find(function (i) {
            return i.id === userObj.id;
          })
        ) {
          usersArray.push(userObj);
        }

        newRow.push({
          id: index + 1,
          status: `${item.get("User").get("FullName")}`,
          customer: `${item.get("User").get("FullName")}`,
          location: `${item.get("Location").get("Name")}`,
          serialNum: `${item.get("ChargePoint").get("Serial")}`,
          power: `${item.get("ChargePoint").get("Power")}`,
          connector: `${item.get("ChargePoint").get("Connector")}`,
          startTime: `${
            moment(item.get("createdAt")).format("lll")
              ? moment(item.get("createdAt")).format("lll")
              : "-"
          }`,
          endTime: `${
            moment(item.get("updatedAt")).format("lll")
              ? moment(item.get("updatedAt")).format("lll")
              : "-"
          }`,
          carCharged: car,
          chargeBy: `${item.get("ChargeBy")}`,
          chargeFor: `${item.get("ChargeFor")}`,
          duration: `${
            moment
              .duration(
                moment(new Date(item.get("updatedAt"))).diff(
                  moment(new Date(item.get("createdAt")))
                )
              )
              .hours() +
            "hr" +
            " " +
            moment
              .duration(
                moment(new Date(item.get("updatedAt"))).diff(
                  moment(new Date(item.get("createdAt")))
                )
              )
              .minutes() +
            "min"
          }  `,
          aid: `${item.get("AID")}`,
          date: `${item.get("createdAt")}`,
          cost: `₹ ${parseFloat(item.get("TotalCost")).toFixed(2)}`,
          energy: `${parseFloat(item.get("TotalEnergyConsumed")).toFixed(
            2
          )} kWh`,
          obj: item,
        });
      });
      // setAllUsers(!allUsers.length ? usersArray : allUsers);
      setReportData(newRow);
    });
  };

  const getUsers = async () => {
    const parseQuery = new Parse.Query("ChargeSession");
    parseQuery.include("User");
    parseQuery.limit(5000);
    parseQuery.find().then(async (result) => {
      console.log("User", result);

      let usersArray: any[] = [];
      const ocppPromises = result.map(async (item, index) => {
        const userObj = {
          id: `${item.get("User").id}`,
          label: `${item.get("User").get("FullName")}`,
        };

        if (
          !usersArray.find(function (i) {
            return i.id === userObj.id;
          })
        ) {
          usersArray.push(userObj);
        }

        let name = "";
        if (`${item.get("User")?.get("FullName")}` !== "undefined") {
          name = `${item.get("User")?.get("FullName")}`;
        }
      });
      await Promise.all(ocppPromises);

      setAllUsers(!allUsers.length ? usersArray : allUsers);
    });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const [userFilter, setUserFilter] = useState({ id: "", label: "" });
  const [invoiceLocationFilter, setInvoiceLocationFilter] = useState<any>([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [currentTypeFilter, setCurrentTypeFilter] = useState("");
  const [chargerTypeFilter, setChargerTypeFilter] = useState({
    label: "",
    type: "",
  });

  const [startDateFilter, setStartDateFilter] = useState<any>("");
  const [endDateFilter, setEndDateFilter] = useState<any>("");
  const [customDateFilter, setCustomDateFilter] = useState<any>("");
  const [locationTypeFilter, setLocationTypeFilter] = useState<any>([]);

  useEffect(() => {
    loadSessions(
      userFilter.id,
      invoiceLocationFilter,
      locationTypeFilter,
      chargerTypeFilter,
      currentTypeFilter,
      statusFilter,
      selectPeriod,
      startDateFilter,

      endDateFilter,
      customDateFilter
    );
  }, [
    userFilter.id,
    invoiceLocationFilter,
    locationTypeFilter,
    chargerTypeFilter,
    currentTypeFilter,
    statusFilter,
    selectPeriod,
    startDateFilter,
    endDateFilter,
    customDateFilter,
  ]);

  const [connectorTypeLoading, setConnectorTypeLoading] = useState(false);
  const getAllConnectorType = (currentType: string) => {
    const Locations = Parse.Object.extend("ConnectorTypes");

    const parseQuery = new Parse.Query(Locations);
    if (currentType) {
      parseQuery.equalTo("CurrentType", currentType);
    }
    setConnectorTypeLoading(true);
    parseQuery.limit(50);
    parseQuery.find().then((result: any) => {
      let connectorArray: any = [];
      result.forEach((item: any) => {
        connectorArray.push({
          label: item.get("Name"),
          type: item.get("CurrentType"),
        });
      });
      setConnectorTypeLoading(false);
      setAllConnectorType(connectorArray);
    });
  };
  useEffect(() => {
    getAllConnectorType(currentTypeFilter);
  }, [currentTypeFilter]);

  const [invoiceUserFilter, setInvoiceUserFilter] = useState<any>([]);
  // const [invoiceLocationFilter, setInvoiceLocationFilter] = useState<any>([]);
  const capitalizeFirstLetterOfEachWord = (str:any) => {
    // return str.replace(/\b\w/g, (char:any) => char.toUpperCase());
    return str
    .toLowerCase()
    .replace(/\b\w/g, (char:any) => char.toUpperCase());
  };
  const InvoiceFilter = (props: { data: any }) => {
    switch (props.data) {
      case "Location":
        return (
          <Autocomplete
            className="mb-4"
            sx={{ width: "580px" }}
            freeSolo
            id="checkboxes-tags-demo"
            disableClearable
            multiple
            limitTags={-1}
            options={allLocations}
            autoHighlight
            size="small"
            value={invoiceLocationFilter}
            onChange={(event: any, newValue: any) => {
              console.log("New Value", newValue);
              setInvoiceLocationFilter(newValue);
              setLocationChips((prevChips: { id: string; label: string }[]) => {
                // Ensure that newValue is an array of objects with 'id' property
                const newChips: { id: string; label: string }[] = newValue.map(
                  (user: any) => ({
                    id: user.id,
                    label: `Location: ${user.label}`,
                  })
                );

                const filteredChips = newChips.filter(
                  (chip) =>
                    !prevChips.some((prevChip) => prevChip.id === chip.id)
                );

                return [...prevChips, ...filteredChips];
              });
              setInvoiceLocationFilter(newValue);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option: any) => option.label}
            getOptionDisabled={(option) =>
              invoiceLocationFilter.some((chip: any) => chip.id === option.id)
            }
            renderOption={(props, option) => {
              let counter = 0;
              const uniqueKey = `${option.id}_${option.label}_${counter++}`;
              const selected = filterChips.some(
                (chip) => chip.id === option.id
              );

              return (
                <MenuItem key={uniqueKey} {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={
                      selected ||
                      invoiceLocationFilter.some(
                        (chip: any) => chip.id === option.id
                      )
                    }
                  />
                  <ListItemText primary={option.label} />
                </MenuItem>
              );
            }}
            renderInput={(params: any) => (
              <TextField {...params} variant="standard" />
            )}
          />
        );

      case "User":
        return (
          // <Autocomplete
          //   className="mb-4"
          //   sx={{ width: "580px" }}
          //   freeSolo
          //   id="checkboxes-tags-demo"
          //   disableClearable
          //   multiple
          //   filterOptions={(options, { inputValue }) => {
          //     const inputPattern = new RegExp(`^${inputValue.split(' ').map(word => `(?=.*\\b${word})`).join('')}`, 'i'); 
      
          //     // Filter options based on the inputValue and ensure selected options are handled properly
          //     return options.filter(option => {
             
          //       const label = option.label.toLowerCase();
          //       const matchesInput = inputPattern.test(label);
          //       const isDisabled = filterChips.some(chip => chip.id === option.id);
      
          //       return matchesInput && !isDisabled;
          //     });
          //   }}
          //   limitTags={-1}
          //   options={allUsers}
          //   autoHighlight
          //   size="small"
          //   value={invoiceUserFilter}
          //   onChange={(event: any, newValue: any) => {
          //     console.log("New Value", newValue);
          //     setInvoiceUserFilter(newValue);
          //     setUserChips((prevChips: { id: string; label: string }[]) => {
          //       const newChips: { id: string; label: string }[] = newValue.map(
          //         (user: any) => ({
          //           id: user.id,
          //           label: `User: ${user.label}`,
          //         })
          //       );

          //       const filteredChips = newChips.filter(
          //         (chip) =>
          //           !prevChips.some((prevChip) => prevChip.id === chip.id)
          //       );

          //       return [...prevChips, ...filteredChips];
          //     });
          //     setInvoiceUserFilter(newValue);
          //   }}
          //   isOptionEqualToValue={(option, value) => option.id === value.id}
          //   getOptionLabel={(option: any) => option.label}
          //   getOptionDisabled={(option) =>
          //     invoiceUserFilter.some((chip: any) => chip.id === option.id)
          //   }
          //   renderOption={(props, option:any,index) => {
          //     const key = `listItem-${index}-${option.id}`;
             
          //     const selected = filterChips.some(
          //       (chip) => chip.id === option.id
          //     );

          //     return (
          //       <MenuItem key={key} {...props}>
          //         <Checkbox
          //           icon={icon}
          //           checkedIcon={checkedIcon}
          //           checked={
          //             selected ||
          //             invoiceUserFilter.some(
          //               (chip: any) => chip.id === option.id
          //             )
          //           }
          //         />
          //         <ListItemText primary={option.label} />
          //       </MenuItem>
          //     );
          //   }}
          //   renderInput={(params: any) => (
          //     <TextField {...params} variant="standard" />
          //   )}
          // />
          <Autocomplete
          className="mb-4"
          sx={{ width: "580px" }}
          ListboxProps={{ style: { maxHeight: 210 } }}
          id="checkboxes-tags-demo"
          disableClearable
          multiple
          limitTags={-1}
          options={allUsers}
          autoHighlight
          size="small"
          value={invoiceUserFilter}
          filterOptions={(options, { inputValue }) => {
            const inputPattern = new RegExp(`^${inputValue.split(' ').map(word => `(?=.*\\b${word})`).join('')}`, 'i'); 
    
            // Filter options based on the inputValue and ensure selected options are handled properly
            return options.filter(option => {
           
              const label = option.label.toLowerCase();
              const matchesInput = inputPattern.test(label);
              const isDisabled = filterChips.some(chip => chip.id === option.id);
    
              return matchesInput && !isDisabled;
            });
          }}
          // onChange={(event: any, newValue: any) => {
          //   setFilterChips(
          //     (prevChips: { id: string; label: string }[]) => {
          //       const newChips: { id: string; label: string }[] =
          //         newValue.map((user: any) => ({
          //           id: user.id,
          //           label: `User: ${user.label}`,
          //         }));

          //       const filteredChips = newChips.filter(
          //         (chip) =>
          //           !prevChips.some((prevChip) => prevChip.id === chip.id)
          //       );

          //       return [...prevChips, ...filteredChips];
          //     }
          //   );
          //   setUserFilter(newValue);
          // }}
          onChange={(event: any, newValue: any) => {
                console.log("New Value", newValue);
                setInvoiceUserFilter(newValue);
                setUserChips((prevChips: { id: string; label: string }[]) => {
                  const newChips: { id: string; label: string }[] = newValue.map(
                    (user: any) => ({
                      id: user.id,
                      label: `User: ${user.label}`,
                    })
                  );
  
                  const filteredChips = newChips.filter(
                    (chip) =>
                      !prevChips.some((prevChip) => prevChip.id === chip.id)
                  );
  
                  return [...prevChips, ...filteredChips];
                });
                setInvoiceUserFilter(newValue);
              }}
          
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option: any) => option.label}
          getOptionDisabled={(option) =>
            filterChips.some((chip) => chip.id === option.id)
          }

          renderOption={(props, option:any,index) => {
           const key = `listItem-${index}-${option.id}`;
            const selected = filterChips.some(
              (chip) => chip.id === option.id
            );
            return (
              <MenuItem {...props} key={key}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={
                    selected ||
                    filterChips.some((chip) => chip.id === option.id)
                  }
                />
                <Typography sx={{ fontFamily: "lexend" ,fontSize:'16px'}}>
                  {/* Formatted name function call */}
                {capitalizeFirstLetterOfEachWord(option.label)} 
                 
                </Typography>
              </MenuItem>
            );
          }}
          
          renderInput={(params: any) => (
            <TextField {...params} variant="standard" placeholder="Type to search...." />
          )}
        />
        );

      default:
        return null;
    }
  };

  const [invoiceTableData, setInvoiceTableData] = useState<any>([]);

  const [filteredData, setFilteredData] = useState<any>([]);
  const currentUser: any = Parse.User.current();

  const loadInvoiceSessions = (
    userId: any,
    locationId: any,
    startDate: any,
    endDate: any
  ) => {
    const parseQuery = new Parse.Query("ChargeSession");
    parseQuery.include("ChargePoint");
    if (userId.length) {
      var innerQuery = new Parse.Query("User");
      innerQuery.containedIn(
        "objectId",
        userId.map((item: any) => item.id)
      );
      parseQuery.matchesQuery("User", innerQuery);
    }
    if (currentUser) {
      var innerQuery = new Parse.Query("Chargers");

      innerQuery.equalTo("CPO", currentUser.get("CPO"));
      parseQuery.matchesQuery("ChargePoint", innerQuery);
    }

    if (locationId.length) {
      var innerQuery = new Parse.Query("Locations");
      innerQuery.containedIn(
        "objectId",
        locationId.map((item: any) => item.id)
      );
      parseQuery.matchesQuery("Location", innerQuery);
    }
    if (startDateFilter) {
      parseQuery.limit(1000);
      // Apply single date filter
      parseQuery.greaterThanOrEqualTo(
        "createdAt",
        new Date(moment(startDateFilter).startOf("day").toString())
      );
      parseQuery.lessThanOrEqualTo(
        "createdAt",
        new Date(moment(startDateFilter).endOf("day").toString())
      );
    }
    if (startDateFilter && endDateFilter) {
      parseQuery.limit(5000);
      parseQuery.greaterThanOrEqualTo("createdAt", new Date(startDateFilter));
      parseQuery.greaterThanOrEqualTo("createdAt", new Date(startDateFilter));
      const adjustedEndDate = moment(endDateFilter).endOf("day").toDate();
      parseQuery.lessThanOrEqualTo("createdAt", adjustedEndDate);
    }
    parseQuery.descending("createdAt");
    parseQuery.limit(1000);
    parseQuery.find().then((result: any[]) => {
      let newRow: any[] = [];

      let usersArray: any[] = [];
      let rawAmt: number = 0;
      let revenueWithGST: number = 0;
      let revenueGSTValue: number = 0;
      let revenueWithoutGST: number = 0;
      let totalAmt: number = 0;
      let actualRate: number = 0;

      const gstPercentage = 18;
      const deductGst = (rate: number, gstPercentage: number) => {
        const divisor = 1 + gstPercentage / 100;
        return rate / divisor;
      };

      let ab = locationId.map((item: any) => {
        return {
          name: item.label,
          rawAmt: 0,
          revenueWithGST: 0,
          revenueGSTValue: 0,
          revenueWithoutGST: 0,
          energy: 0,
          actualRate: 0,
          rate: 0,
        };
      });

      let bc = userId.map((item: any) => {
        return {
          name: item.label,
          rawAmt: 0,
          revenueWithGST: 0,
          revenueGSTValue: 0,
          revenueWithoutGST: 0,
          actualRate: 0,
          energy: 0,
          rate: 0,
        };
      });
      result.forEach((item, index) => {
        ab.forEach((element: any) => {
          if (
            String(element.name) === String(item.get("Location").get("Name"))
          ) {
            element.actualRate = parseFloat(item.get("TotalCost"));
            element.rawAmt = item.get("TotalCost")
              ? (parseFloat(item.get("TotalCost")) * 100) / 118 + element.rawAmt
              : 0 + element.rawAmt;
            element.revenueGSTValue = parseFloat(
              (element.revenueWithGST * (gstPercentage / 100)).toFixed(2)
            );
            element.revenueWithGST = item.get("TotalCost")
              ? parseFloat(item.get("TotalCost")) + element.revenueWithGST
              : 0 + element.revenueWithGST;
            element.revenueWithoutGST =
              revenueWithGST - revenueWithGST * (gstPercentage / 100);

            element.energy = item.get("TotalEnergyConsumed")
              ? item.get("TotalEnergyConsumed") + element.energy
              : 0 + element.energy;
            element.actualRate =
              element.actualRate === 0
                ? item.get("ChargePoint").get("Cost")
                : element.actualRate;
            element.rate =
              element.rate === 0
                ? deductGst(
                    parseFloat(item.get("ChargePoint").get("Cost")),
                    gstPercentage
                  )
                : element.rate;
            // element.rate =
            //   element.rate === 0
            //     ? (item.get("ChargePoint").get("Cost") * 100) / 118
            //     : element.rate;
          } else {
            return;
          }
        });
        bc.forEach((element: any) => {
          if (
            String(element.name) === String(item.get("User").get("FullName"))
          ) {
            element.actualRate = parseFloat(item.get("TotalCost"));
            element.rawAmt = item.get("TotalCost")
              ? (parseFloat(item.get("TotalCost")) * 100) / 118 + element.rawAmt
              : 0 + element.rawAmt;
            element.revenueGSTValue = parseFloat(
              (element.revenueWithGST * (gstPercentage / 100)).toFixed(2)
            );

            element.revenueWithGST = item.get("TotalCost")
              ? parseFloat(item.get("TotalCost")) + element.revenueWithGST
              : 0 + element.revenueWithGST;
            element.revenueWithoutGST =
              element.revenueWithGST / (1 + gstPercentage / 100);
            element.energy = item.get("TotalEnergyConsumed")
              ? item.get("TotalEnergyConsumed") + element.energy
              : 0 + element.energy;
            element.actualRate =
              element.actualRate === 0
                ? item.get("ChargePoint").get("Cost")
                : element.actualRate;
            // element.rate =
            // element.rate === 0
            //   ? (item.get("ChargePoint").get("Cost")  )
            //   : element.rate;
            element.rate =
              element.rate === 0
                ? deductGst(item.get("ChargePoint").get("Cost"), gstPercentage)
                : element.rate;
          } else {
            return;
          }
        });
        actualRate = parseFloat(item.get("ChargePoint").get("Cost"));
        rawAmt = item.get("TotalCost")
          ? (parseFloat(item.get("TotalCost")) * 100) / 118 + rawAmt
          : 0 + rawAmt;
        revenueWithGST = item.get("TotalCost")
          ? parseFloat(item.get("TotalCost")) + revenueWithGST
          : 0 + revenueWithGST;
        revenueWithoutGST = revenueWithGST / (1 + gstPercentage / 100);
        revenueGSTValue = revenueWithGST * (gstPercentage / 100); // Calculate revenueGSTValue
        totalAmt = item.get("TotalCost")
          ? item.get("TotalCost") + totalAmt
          : 0 + totalAmt;
        actualRate: parseFloat(item.get("ChargePoint").get("Cost"));
        newRow.push({
          id: index + 1,

          // customer: invoiceUserFilter.id
          //   ? item.get("User").get("FullName")
          //   : item.get("Location").get("Name"),
          location: `${item.get("Location").get("Name")}`,
          actualRate: parseFloat(item.get("ChargePoint").get("Cost")),
          rate: (
            (parseFloat(item.get("ChargePoint").get("Cost")) * 100) /
            118
          ).toFixed(2),

          startTime: `${
            moment(item.get("createdAt")).format("lll")
              ? moment(item.get("createdAt")).format("lll")
              : "-"
          }`,
          endTime: `${
            moment(item.get("updatedAt")).format("lll")
              ? moment(item.get("updatedAt")).format("lll")
              : "-"
          }`,

          date: `${item.get("createdAt")}`,
          cost: ((parseFloat(item.get("TotalCost")) * 100) / 118).toFixed(2),

          // gstincludecost: `${item.get("TotalCost")}`,
          energy: `${parseFloat(item.get("TotalEnergyConsumed")).toFixed(
            2
          )} kWh`,
          obj: item,
        });
      });

      setValues({
        ...values,
        costWithGST: actualRate,
        revenueWithoutGST,
        revenueWithGST: revenueWithGST,
        rawAmount: rawAmt,
        totalAmount: totalAmt,
      });
      setInvoiceTableData(bc.length ? bc : ab);
    });
  };

  const [startDateInvoiceFilter, setStartDateInvoiceFilter] = useState<any>("");
  const [endDateInvoiceFilter, setEndDateInvoiceFilter] = useState<any>("");
  useEffect(() => {
    loadInvoiceSessions(
      invoiceUserFilter,
      invoiceLocationFilter,
      startDateInvoiceFilter,
      endDateInvoiceFilter
    );
  }, [
    invoiceUserFilter,
    invoiceLocationFilter,
    startDateInvoiceFilter,
    endDateInvoiceFilter,
    startDateFilter,
    endDateFilter,
  ]);

  const playersData = [
    { key: "id", label: "ID" },
    { key: "customer", label: "Name" },
    { key: "location", label: "Location" },
    {
      key: "isLive",

      label: "Status",
      renderCell: (params: {
        row: { obj: { get: (arg0: string) => any } };
      }) => {
        let currentStatus = params.row.obj.get("Live");
        return currentStatus === false ? (
          <div className="labelCompleted">
            <span className="labelText">Completed</span>
          </div>
        ) : (
          <div className="label">
            <span className="labelText">Charging</span>
          </div>
        );
      },
    },
    { key: "carCharged", label: "Vehicle" },

    { key: "startTime", label: "Start Time" },
    { key: "endTime", label: "End Time" },

    { key: "cost", label: "Cost" },

    { key: "energy", label: "Energy" },
    { key: "duration", label: "Duration" },
    { key: "serialNum", label: "Serial Number" },
    { key: "power", label: "Power" },
    { key: "connector", label: "Connector" },
  ];
  const csvLink = {
    filename: "Charge-City.csv",
    headers: playersData,
    data: reportData,
  };
  const initialValues = {
    name: "",
    email: "",
    address: "",
    gstNum: "",
    billto: "",
    invoiceNum: "",
    startDate: "",
    endDate: "",
    rawAmount: 0,
    costWithGST: 0,
    revenueWithGST: 0,
    revenueWithoutGST: 0,
    totalAmount: 0,
  };

  const [values, setValues] = useState(initialValues);

  // Filter For Date Range Picker Function
  const dateRangeChange = (dates: any) => {
    const [start, end] = dates;
    console.log("DateRange", dates);
    setValues({
      ...values,
      startDate: start,
      endDate: end,
    });
    setCustomDateFilter(start);
    setStartDateFilter(start);
    setEndDateFilter(end);
    setEndDateFilter(end);
  };

  useEffect(() => {
    if (Array.isArray(invoiceLocationFilter)) {
      const locationIds = invoiceLocationFilter.map(
        (location: any) => location?.label
      );

      setLocationIds(locationIds);

      if (locationIds.length > 0) {
        // Your logic when userFilter is an array
      }
    } else if (
      invoiceLocationFilter &&
      typeof invoiceLocationFilter === "object"
    ) {
      const locationId = (invoiceLocationFilter as { id?: string }).id;

      setLocationIds(locationId ? [locationId] : []);

      // Your logic when userFilter is an object
    } else {
      // Handle other cases when userFilter is not an array or object
    }
  }, [invoiceLocationFilter]);

  const handleLocationDeleteChip = (chipId: string) => {
    console.log("Deleting chip with id:", chipId);

    // Remove the corresponding chip from the state
    setLocationChips((prevChips) =>
      prevChips.filter((chip) => chip.id !== chipId)
    );

    // Remove the location filter
    setLocationIds((prevUserIds) => prevUserIds.filter((id) => id !== chipId));

    setInvoiceLocationFilter((prevUserFilter: any) =>
      prevUserFilter.filter((filter: any) => filter.id !== chipId)
    );
  };

  const handleDeleteUserChip = (chipId: string) => {
    // console.log("Deleting chip with id:", chipId);

    // Remove the corresponding chip from the state
    setUserChips((prevChips) => prevChips.filter((chip) => chip.id !== chipId));

    // Remove the user filter
    setUserIds((prevUserIds) => prevUserIds.filter((id) => id !== chipId));

    setInvoiceUserFilter((prevUserFilter: any) =>
      prevUserFilter.filter((filter: any) => filter.id !== chipId)
    );
    // console.log("userFilter in delete", userFilter);
  };
  console.log("data of PDF", values);
  return (
    <div className="mt-4 mx-8">
      {/* Top */}
      <div className="flex justify-between">
        <h1 className="font-bold font-lexend text-xl mt-4">Invoice</h1>
        {/* <button
                  className="font-lexend mt-4"
                  style={{
                    background: "#1AC47D",
                    color: "white",
                    border: "2px solid #1AAD70",
                    padding: "9px 15px",
                    borderRadius: "20px",
                  }}
                  onClick={() => setOpenInvoice(true)}
                >
                  <RemoveRedEyeIcon className="icon mr-3" />
                  Preview Invoice
                </button> */}
      </div>

      <div className="mt-4">
        {locationChips.map((chip) => (
          <Chip
            key={chip.id}
            label={chip.label}
            icon={<LocationOnOutlinedIcon />}
            onDelete={() => handleLocationDeleteChip(chip.id)}
            variant="outlined"
            sx={{ marginLeft: "5px", marginBottom: "10px" }}
          />
        ))}
        {userChips.map((chip) => (
          <Chip
            key={chip.id}
            label={capitalizeFirstLetterOfEachWord(chip.label)}
            icon={<Person3OutlinedIcon />}
            onDelete={() => handleDeleteUserChip(chip.id)}
            variant="outlined"
            sx={{ marginLeft: "5px", marginBottom: "10px" }}
          />
        ))}
      </div>

      {/* Invoice form */}
      <div className="flex w-full mt-6">
        {/*location Form Code */}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label className="flex items-center mb-2" htmlFor="station name">
            <span>
              <svg
                className="mr-2"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.79411 19.3218C9.42629 19.4806 9.01925 19.5253 8.62574 19.4501C8.23224 19.3748 7.8704 19.1831 7.58711 18.8998L3.34311 14.6568C2.38184 13.6952 1.68153 12.5045 1.30829 11.197C0.935052 9.88956 0.901208 8.50856 1.20995 7.18439C1.5187 5.86023 2.15985 4.63661 3.07286 3.62907C3.98587 2.62152 5.14061 1.86331 6.42805 1.42601C7.71549 0.988721 9.09315 0.886778 10.4309 1.12981C11.7687 1.37285 13.0225 1.95284 14.0738 2.81503C15.1252 3.67721 15.9395 4.79313 16.4398 6.05743C16.94 7.32173 17.1098 8.69267 16.9331 10.0408M13.0001 16.9998H19.0001M16.0001 13.9998V19.9998M6.00012 8.99982C6.00012 9.79547 6.31619 10.5585 6.8788 11.1211C7.44141 11.6837 8.20447 11.9998 9.00012 11.9998C9.79577 11.9998 10.5588 11.6837 11.1214 11.1211C11.6841 10.5585 12.0001 9.79547 12.0001 8.99982C12.0001 8.20417 11.6841 7.44111 11.1214 6.8785C10.5588 6.31589 9.79577 5.99982 9.00012 5.99982C8.20447 5.99982 7.44141 6.31589 6.8788 6.8785C6.31619 7.44111 6.00012 8.20417 6.00012 8.99982Z"
                  stroke="#354052"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>{" "}
            <span className="font-lexend">Location </span>
          </label>
          <InvoiceFilter data={"Location"} />
        </div>
        {/*User Form Code */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "33px",
          }}
        >
          <label className="flex items-center mb-2" htmlFor="station name">
            <span>
              <svg
                className="mr-2"
                width="17"
                height="20"
                viewBox="0 0 17 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 19V17C1 15.9391 1.42143 14.9217 2.17157 14.1716C2.92172 13.4214 3.93913 13 5 13H9M10 17L12 19L16 15M3 5C3 6.06087 3.42143 7.07828 4.17157 7.82843C4.92172 8.57857 5.93913 9 7 9C8.06087 9 9.07828 8.57857 9.82843 7.82843C10.5786 7.07828 11 6.06087 11 5C11 3.93913 10.5786 2.92172 9.82843 2.17157C9.07828 1.42143 8.06087 1 7 1C5.93913 1 4.92172 1.42143 4.17157 2.17157C3.42143 2.92172 3 3.93913 3 5Z"
                  stroke="#354052"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>{" "}
            <span className="font-lexend">Users</span>
          </label>
          <InvoiceFilter data={"User"} />
        </div>
      </div>

      <div className="w-full">
        <div className="mb-4 mt-4 flex">
          <div className="flex flex-col w-full">
            <div className="select_date_container flex">
              <div style={{ width: "27%" }} className=" mt-4 ml-2 mr-6">
                <label className="flex items-center mb-2" htmlFor="Invoice">
                  <span>
                    <svg
                      className="mr-2"
                      width="16"
                      height="20"
                      viewBox="0 0 16 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 3H3C2.46957 3 1.96086 3.21071 1.58579 3.58579C1.21071 3.96086 1 4.46957 1 5V17C1 17.5304 1.21071 18.0391 1.58579 18.4142C1.96086 18.7893 2.46957 19 3 19H13C13.5304 19 14.0391 18.7893 14.4142 18.4142C14.7893 18.0391 15 17.5304 15 17V5C15 4.46957 14.7893 3.96086 14.4142 3.58579C14.0391 3.21071 13.5304 3 13 3H11M5 3C5 2.46957 5.21071 1.96086 5.58579 1.58579C5.96086 1.21071 6.46957 1 7 1H9C9.53043 1 10.0391 1.21071 10.4142 1.58579C10.7893 1.96086 11 2.46957 11 3M5 3C5 3.53043 5.21071 4.03914 5.58579 4.41421C5.96086 4.78929 6.46957 5 7 5H9C9.53043 5 10.0391 4.78929 10.4142 4.41421C10.7893 4.03914 11 3.53043 11 3M5 15V10M8 15V14M11 15V12"
                        stroke="#354052"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>{" "}
                  <span className="font-lexend">Invoice</span>
                </label>
                <Input
                  sx={{ width: "100%", paddingTop: "20px" }}
                  id="bootstrap-input"
                  value={values.invoiceNum}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      invoiceNum: e.target.value,
                    })
                  }
                />
              </div>

              <div style={{ width: "27%" }} className=" mt-4  ml-6">
                <label className="flex items-center mb-2" htmlFor="GSTIN">
                  <span>
                    <svg
                      className="mr-2"
                      width="16"
                      height="20"
                      viewBox="0 0 16 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 3H3C2.46957 3 1.96086 3.21071 1.58579 3.58579C1.21071 3.96086 1 4.46957 1 5V17C1 17.5304 1.21071 18.0391 1.58579 18.4142C1.96086 18.7893 2.46957 19 3 19H13C13.5304 19 14.0391 18.7893 14.4142 18.4142C14.7893 18.0391 15 17.5304 15 17V5C15 4.46957 14.7893 3.96086 14.4142 3.58579C14.0391 3.21071 13.5304 3 13 3H11M5 3C5 2.46957 5.21071 1.96086 5.58579 1.58579C5.96086 1.21071 6.46957 1 7 1H9C9.53043 1 10.0391 1.21071 10.4142 1.58579C10.7893 1.96086 11 2.46957 11 3M5 3C5 3.53043 5.21071 4.03914 5.58579 4.41421C5.96086 4.78929 6.46957 5 7 5H9C9.53043 5 10.0391 4.78929 10.4142 4.41421C10.7893 4.03914 11 3.53043 11 3M5 15V10M8 15V14M11 15V12"
                        stroke="#354052"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>{" "}
                  <span className="font-lexend">GSTIN</span>
                </label>
                <Input
                  sx={{ width: "100%", paddingTop: "20px" }}
                  id="bootstrap-input"
                  value={values.gstNum}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      gstNum: e.target.value,
                    })
                  }
                />
              </div>

              <div className="start_dateContainer mt-4 ml-10 w-1/4">
                <label
                  className="flex items-center mb-2"
                  htmlFor="station name"
                >
                  <span></span>{" "}
                  <span className="font-lexend text-gray-400">
                    Selected Start Date Value
                  </span>
                </label>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <MuiDatePicker
                    label="Start Date"
                    disabled
                    value={startDateFilter}
                    onChange={dateRangeChange}
                    className="mt-4"
                    renderInput={(params) => (
                      <TextField
                        sx={{ width: "290px" }}
                        {...params}
                        error={false}
                      />
                    )}
                    inputFormat="DD-MM-YYYY"
                  />
                </LocalizationProvider>
              </div>
            </div>

            <div style={{ width: "86%" }} className="flex mt-3">
              <div style={{ width: "31%" }} className=" mt-4 ml-4">
                <label className="flex items-center mb-2" htmlFor="Billto">
                  <span>
                    <svg
                      className="mr-2"
                      width="16"
                      height="20"
                      viewBox="0 0 16 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 5H11M5 9H11M5 13H9M1 3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H13C13.5304 1 14.0391 1.21071 14.4142 1.58579C14.7893 1.96086 15 2.46957 15 3V17C15 17.5304 14.7893 18.0391 14.4142 18.4142C14.0391 18.7893 13.5304 19 13 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V3Z"
                        stroke="#354052"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>{" "}
                  <span className="font-lexend">Bill To</span>
                </label>
                <Input
                  sx={{ width: "100%", paddingTop: "20px" }}
                  id="bootstrap-input"
                  value={values.billto}
                  onChange={(e: any) =>
                    setValues({
                      ...values,
                      billto: e.target.value,
                    })
                  }
                />
              </div>
              <div style={{ width: "31%" }} className=" mt-4 ml-12">
                <label
                  className="flex items-center mb-2"
                  htmlFor="contact name"
                >
                  <span>
                    <svg
                      className="mr-2"
                      width="17"
                      height="20"
                      viewBox="0 0 17 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 19V17C1 15.9391 1.42143 14.9217 2.17157 14.1716C2.92172 13.4214 3.93913 13 5 13H9M10 17L12 19L16 15M3 5C3 6.06087 3.42143 7.07828 4.17157 7.82843C4.92172 8.57857 5.93913 9 7 9C8.06087 9 9.07828 8.57857 9.82843 7.82843C10.5786 7.07828 11 6.06087 11 5C11 3.93913 10.5786 2.92172 9.82843 2.17157C9.07828 1.42143 8.06087 1 7 1C5.93913 1 4.92172 1.42143 4.17157 2.17157C3.42143 2.92172 3 3.93913 3 5Z"
                        stroke="#354052"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>{" "}
                  <span className="font-lexend">Contact Name</span>
                </label>
                <Input
                  sx={{ width: "100%", paddingTop: "20px" }}
                  id="bootstrap-input"
                  value={values.name}
                  onChange={(e: any) =>
                    setValues({
                      ...values,
                      name: e.target.value,
                    })
                  }
                />
              </div>

              <div className="endDate_container mt-4 w-1/4 ml-12">
                <label
                  className="flex items-center mb-2"
                  htmlFor="station name"
                >
                  <span></span>{" "}
                  <span className="font-lexend text-gray-400">
                    Selected End Date Value
                  </span>
                </label>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <MuiDatePicker
                    label="End Date"
                    disabled
                    value={endDateFilter}
                    onChange={(item) => setEndDateFilter(item || "")}
                    renderInput={(params) => (
                      <TextField
                        sx={{ width: "280px" }}
                        {...params}
                        error={false}
                      />
                    )}
                    inputFormat="DD-MM-YYYY"
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div style={{ width: "98%" }} className="flex mt-3">
              <div style={{ width: "39%" }} className=" mt-4 ml-4">
                <label className="flex items-center mb-2" htmlFor="Address">
                  <span>
                    <svg
                      className="mr-2"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 19H19M7 6H8M7 10H8M7 14H8M12 6H13M12 10H13M12 14H13M3 19V3C3 2.46957 3.21071 1.96086 3.58579 1.58579C3.96086 1.21071 4.46957 1 5 1H15C15.5304 1 16.0391 1.21071 16.4142 1.58579C16.7893 1.96086 17 2.46957 17 3V19"
                        stroke="#354052"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>{" "}
                  <span className="font-lexend">Address</span>
                </label>
                <Input
                  sx={{ width: "100%" }}
                  id="bootstrap-input"
                  value={values.address}
                  onChange={(e: any) =>
                    setValues({
                      ...values,
                      address: e.target.value,
                    })
                  }
                />
              </div>
              <div style={{ width: "39%" }} className=" mt-4 ml-12">
                <label
                  className="flex items-center mb-2"
                  htmlFor="contact email"
                >
                  <span>
                    <svg
                      className="mr-2"
                      width="17"
                      height="20"
                      viewBox="0 0 17 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 19V17C1 15.9391 1.42143 14.9217 2.17157 14.1716C2.92172 13.4214 3.93913 13 5 13H9M10 17L12 19L16 15M3 5C3 6.06087 3.42143 7.07828 4.17157 7.82843C4.92172 8.57857 5.93913 9 7 9C8.06087 9 9.07828 8.57857 9.82843 7.82843C10.5786 7.07828 11 6.06087 11 5C11 3.93913 10.5786 2.92172 9.82843 2.17157C9.07828 1.42143 8.06087 1 7 1C5.93913 1 4.92172 1.42143 4.17157 2.17157C3.42143 2.92172 3 3.93913 3 5Z"
                        stroke="#354052"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>{" "}
                  <span className="font-lexend">Contact Email</span>
                </label>
                <Input
                  sx={{ width: "100%" }}
                  id="bootstrap-input"
                  value={values.email}
                  onChange={(e: any) =>
                    setValues({
                      ...values,
                      email: e.target.value,
                    })
                  }
                />
              </div>
              <div className=" mt-4 w-1/2 ml-10">
                <button
                  className="font-lexend mt-4"
                  style={{
                    background: "#1AC47D",
                    color: "white",
                    border: "2px solid #1AAD70",
                    padding: "9px 15px",
                    borderRadius: "20px",
                  }}
                  onClick={() => setOpenInvoice(true)}
                >
                  <RemoveRedEyeIcon className="icon mr-3" />
                  Preview Invoice
                </button>
              </div>
              <InvoiceModal
                show={openInvoice}
                handleClose={() => setOpenInvoice(false)}
                data={values}
                tableData={invoiceTableData}
                location={invoiceLocationFilter}
              />
            </div>
          </div>
          <div className="rangePicker_container mt-4 mr-6">
            <label className="flex items-center mb-2" htmlFor="station name">
              <span>
                <svg
                  className="mr-2"
                  width="18"
                  height="18"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 4.99998H4C3.46957 4.99998 2.96086 5.2107 2.58579 5.58577C2.21071 5.96084 2 6.46955 2 6.99998V16C2 16.5304 2.21071 17.0391 2.58579 17.4142C2.96086 17.7893 3.46957 18 4 18H13C13.5304 18 14.0391 17.7893 14.4142 17.4142C14.7893 17.0391 15 16.5304 15 16V15M8.507 8.49798L7 9.99998V13H10L11.493 11.502M13.493 9.49198L18.383 4.58499C18.7768 4.19114 18.9981 3.65697 18.9981 3.09998C18.9981 2.543 18.7768 2.00883 18.383 1.61498C17.9892 1.22114 17.455 0.999878 16.898 0.999878C16.341 0.999878 15.8068 1.22114 15.413 1.61498L10.5 6.51098M14 2.99998L17 5.99998M1 0.999985L19 19"
                    stroke="#354052"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>{" "}
              <span className="font-lexend">Select Date</span>
            </label>
            <DatePicker
              selected={startDateFilter}
              onChange={dateRangeChange}
              startDate={startDateFilter}
              endDate={endDateFilter}
              maxDate={new Date()}
              selectsRange
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              minDate={new Date(2022, 0, 1)} 
              inline
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default Report;
