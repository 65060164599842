import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./invoiceModal.scss";
import {
  useMediaQuery,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  CircularProgress,
  Backdrop,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import moment from "moment";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import myImg from "../../../icons/main.png";

interface PropTypes {
  show: boolean;
  handleClose: any;
  tableData: any;
  data: any;
  location: any;
}

function InvoiceModal(props: PropTypes) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [loader, setLoader] = useState(false);
  const [tableData, setTableData] = useState([]);

  const pdfInvoice: any = html2canvas;

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                     This function is for generating Invoice                    ||
  // ! ||--------------------------------------------------------------------------------||

  function GenerateInvoice() {
    setLoader(true);
    pdfInvoice(document.querySelector("#invoiceCapture"), { scale: 4 }).then(
      (canvas: any) => {
        const imgData = canvas.toDataURL("image/jpeg", 0.7);

        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "pt",
          format: [612, 792], // [width, height] in points
        });
        pdf.internal.scaleFactor = 1;

        const margin = 10; // Define the margin size in points (1 pt = 1/72 inch)

        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const contentWidth = pageWidth - 2 * margin;
        const contentHeight = pageHeight - 2 * margin;

        const imgProps = pdf.getImageProperties(imgData);
        const imgWidth = imgProps.width;
        const imgHeight = imgProps.height;

        const ratio = Math.min(
          contentWidth / imgWidth,
          contentHeight / imgHeight
        );
        const scaledWidth = imgWidth * ratio;
        const scaledHeight = imgHeight * ratio;

        const xOffset = (pageWidth - scaledWidth) / 2;
        const yOffset = (pageHeight - scaledHeight) / 2;

        pdf.addImage(
          imgData,
          "PNG",
          xOffset,
          yOffset,
          scaledWidth,
          scaledHeight
        );

        // Add email link annotation
        // pdf.textWithLink('Email: info@chargecity.co', xOffset + 440, yOffset + 100, { url: 'mailto:info@chargecity.co' });
        // pdf.textWithLink('Tel: 1800-843-6467', xOffset + 440, yOffset + 120, { url: 'tel:1800-843-6467' });

        // pdf.save("invoice-001.pdf");
        const currentDate = new Date()
          .toLocaleDateString("en-GB")
          .split("/")
          .reverse()
          .join("-"); // Format: DD-MM-YY
        const docName = `CC-INV-${currentDate}-${props.data.invoiceNum}`;
        pdf.save(docName);
        setLoader(false);
        props.handleClose();
      }
    );
  }

  useEffect(() => {
    setTableData(props.tableData);
  }, [props.tableData]);

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                       This is for change the rate in table                      ||
  // ! ||--------------------------------------------------------------------------------||
  const handleRateChange = (type: string, id: any, item: any) => {
    let ab: any = [];
    if (type === "Rate") {
      ab = tableData.map((el: any, idx) => {
        if (idx === id) {
          el = { ...el, rate: parseFloat(item) };
        } else {
          return el;
        }
        return el;
      });
    } else {
      ab = tableData.map((el: any, idx) => {
        if (idx === id) {
          el = { ...el, energy: parseFloat(item) };
        } else {
          return el;
        }
        return el;
      });
    }

    setTableData(ab);
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          width: 960,
          height: 700,
        },
      }}
      maxWidth={"lg"}
      fullScreen={fullScreen}
      open={props.show}
      onClose={props.handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <div className="headItem">
          <span className="font-lexend font-bold">Generate Invoice</span>{" "}
          <span className="head2" onClick={props.handleClose}>
            <CloseIcon />
          </span>
        </div>
      </DialogTitle>

      <DialogContent>
        <div id="invoiceCapture">
          <div className="invoice-box">
            <span className="font-lexend text-2xl font-bold flex justify-center">
              Coulomb EV Solutions Pvt Ltd
            </span>
            <hr style={{ border: "1px solid #CCCCCC", margin: "20px 0px" }} />
            {/* Heading Of Invoice */}
            <div style={{ height: "9.2rem" }} className="flex">
              {/* Icon */}
              <div className="w-24 h-24">
                <img src={myImg} className="w-full h-full" />
                <span style={{ color: "#1AC47D" }} className="font-lexend">
                  Charge City
                </span>
              </div>
              {/* Header Info */}
              <div className="ml-14 w-10/12  ">
                <div className="flex justify-between">
                  <div>
                    <span className="font-lexend">
                      Regus, Assotech Business Crestera, Sec-135
                    </span>{" "}
                    <br />
                    <span className="font-lexend">Noida - U.P - 201304</span>
                  </div>

                  <div className="">
                    <span className="font-lexend">
                      Email :{" "}
                      <a className="font-lexend" href="mailto:info@chargecity.co">info@chargecity.co</a>
                    </span>{" "}
                    <br />
                    <span className="font-lexend">
                      {" "}
                      Tel: <a className="font-lexend" href="tel:1800-843-6467">1800-843-6467</a>{" "}
                    </span>
                  </div>
                </div>

                <div style={{}} className="flex justify-between mt-6 py-2">
                  <span className="font-lexend text-lg font-bold">
                    GSTIN: 09AAICC3145K1ZH
                  </span>
                  <span className="font-lexend text-3xl font-bold tracking-wider">
                    INVOICE
                  </span>
                </div>
              </div>
            </div>

            <hr style={{ border: "1px solid #CCCCCC" }} />
            <div className="mid-row">
              <div className="left-item">
                <div className="flex item-center mt-4">
                  <span className=" font-lexend font-bold">Bill To : </span>

                  <div className="flex items-center font-lexend">
                    {/* <span className="font-lexend text-sm ml-1"> Mr/Ms</span> {" "} */}
                    <span className="font-lexend text-lg ml-2">
                      {" "}
                      {props.data.billto ? props.data.billto : "-"}
                    </span>
                  </div>
                </div>

                <div className="flex-col list-item font-lexend">
                  {" "}
                  {props.data.name}
                  <br />
                  {props.data.email}
                  <br />
                  {props.data.address}
                  <br />
                  <span className="font-semibold mt-2"> {props.data.gstNum ? <span className="font-lexend">GSTIN:- {props.data.gstNum}</span> : ""}</span>
                
                  <br />
                </div>
              </div>

              <div>
                <p className="list-item">
                  <span className="left-item font-lexend">Invoice Number:</span>

                  <span className="right-item font-lexend">
                    {" "}
                    {props.data.invoiceNum ? props.data.invoiceNum : "-"}
                  </span>
                </p>
                <p className="list-item">
                  <span className="left-item font-lexend"> Start Date:</span>

                  <span className="right-item font-lexend">
                    {props.data.startDate
                      ? moment(props.data.startDate).format("ll")
                      : "-"}
                  </span>
                </p>
                <p className="list-item">
                  <span className="left-item font-lexend">End Date:</span>

                  <span className="right-item font-lexend">
                    {" "}
                    {props.data.endDate
                      ? moment(props.data.endDate).format("ll")
                      : "-"}
                  </span>
                </p>
              </div>
            </div>
            <hr style={{ border: "1px solid #CCCCCC", margin: "10px 0px" }} />
            <div className="bottom-row">
              <TableContainer>
                <Table aria-label="collapsible table">
                  <TableHead sx={{ backgroundColor: "#ebebeb" }}>
                    <TableRow>
                      <TableCell sx={{ fontFamily: "lexend", fontSize: 16 }}>
                        S.NO.
                      </TableCell>

                      <TableCell sx={{ fontFamily: "lexend", fontSize: 16 }}>
                        Item Description
                      </TableCell>
                      <TableCell sx={{ fontFamily: "lexend", fontSize: 16 }}>
                        Rate (/kWh)
                      </TableCell>
                      <TableCell sx={{ fontFamily: "lexend", fontSize: 16 }}>
                        Energy
                      </TableCell>
                      <TableCell sx={{ fontFamily: "lexend", fontSize: 16 }}>
                        Amount
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ width: "100%" }}>
                    {tableData.map((item: any, index: any) => (
                      <TableRow key={index}>
                        <TableCell>
                          <span className="font-lexend">{index + 1}</span>
                        </TableCell>
                        <TableCell>
                          {" "}
                          <span className="font-lexend font-semibold">
                            {item.name}
                          </span>{" "}
                        </TableCell>
                        <TableCell
                          contentEditable={true}
                          onInput={(e: any) => {
                            let value = e.currentTarget.textContent;
                            setTimeout(() => {
                              handleRateChange("Rate", index, value);
                            }, 2000);
                          }}
                        >
                          <span className="font-lexend">
                            {item.rate ? item.rate.toFixed(2) : ""}
                          </span>
                        </TableCell>
                        <TableCell
                          // contentEditable={true}
                          onInput={(e: any) => {
                            let a = e.currentTarget.textContent;
                            setTimeout(() => {
                              handleRateChange("Energy", index, a);
                            }, 1000);
                          }}
                        >
                          <span className="font-lexend">
                            {item.energy ? item.energy.toFixed(2) : ""}
                          </span>
                        </TableCell>
                        <TableCell>
                          <span className="font-lexend">
                            ₹{(item.rate * item.energy).toFixed(2)}
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>

              <div className="mid-row">
                <div className=" border-r flex justify-between border-b border-l border-black-600 w-full">
                  <div>
                    <p className="font-lexend m-5 text-sm">
                      Thanks for your business. For any Queries please contact
                      <br /> info@chargecity.co or Call 1800-843-6467
                    </p>
                    <p className="font-lexend m-5  text-sm font-semibold">
                      Coulomb EV Solutions Private Limited
                      <br /> BANK - ICICI Bank
                      <br />
                      ACCOUNT NUMBER - 081505500764
                      <br />
                      IFSC - ICIC0000815
                    </p>
                  </div>
                  <div></div>
                  <div className="flex flex-col w-4/12">
                    <span className="my-2 border-b  py-2 mr-14 flex justify-between">
                      <span className="mr-14 font-lexend font-semibold text-gray-500 ">
                        {" "}
                        Subtotal :
                      </span>

                      <span className="right-item font-lexend ">
                        ₹
                        {tableData
                          .reduce((acc, curr: any) => {
                            return acc + curr.rate * curr.energy;
                          }, 0)
                          .toFixed(2)}
                      </span>
                    </span>
                    <span className="my-2 mr-14 flex border-b  py-2 justify-between">
                      <span className="mr-14 left-item font-lexend font-semibold text-gray-500">
                        {" "}
                        CGST (9%) :
                      </span>

                      <span className=" right-item  font-lexend ">
                        ₹
                        {tableData.reduce((acc, curr: any) => {
                          return acc + curr.rate * curr.energy;
                        }, 0)
                          ? (
                              (9 / 100) *
                              tableData.reduce((acc, curr: any) => {
                                return acc + curr.rate * curr.energy;
                              }, 0)
                            ).toFixed(2)
                          : "-"}
                      </span>
                    </span>
                    <span className="my-2 mr-14 flex border-b py-2 justify-between">
                      <span className="left-item mr-14 font-lexend font-semibold text-gray-500">
                        {" "}
                        SGST (9%) :
                      </span>

                      <span className="right-item  font-lexend ">
                        ₹
                        {tableData.reduce((acc, curr: any) => {
                          return acc + curr.rate * curr.energy;
                        }, 0)
                          ? (
                              (9 / 100) *
                              tableData.reduce((acc, curr: any) => {
                                return acc + curr.rate * curr.energy;
                              }, 0)
                            ).toFixed(2)
                          : "-"}
                      </span>
                    </span>
                    <span className="py-2 mr-14 flex justify-between">
                      <span className="mr-18 left-item font-lexend font-semibold text-gray-500">
                        Total :
                      </span>

                      <span className="right-item font-bold  font-lexend text-lg">
                        ₹
                        {tableData.reduce((acc, curr: any) => {
                          return acc + curr.rate * curr.energy;
                        }, 0)
                          ? (
                              tableData.reduce((acc, curr: any) => {
                                return acc + curr.rate * curr.energy;
                              }, 0) +
                              (18 / 100) *
                                tableData.reduce((acc, curr: any) => {
                                  return acc + curr.rate * curr.energy;
                                }, 0)
                            ).toFixed(2)
                          : "-"}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex justify-end mb-8">
              <div className="relative h-24 border-black-500 border-r border-l border-b w-1/4">
                <span className="absolute -bottom-2 left-8 bg-white font-lexend font-semibold">
                  Authorized Signature
                </span>
              </div>
              </div>
              
            </div>
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <button
          className="rounded text-white tracking-wider font-bold font-lexend mr-8  "
          style={{
            cursor: loader ? "not-allowed" : "pointer",
            background: loader ? "gray" : "#1AC47D",
            width: "25%",
            padding: "9px 7px",
          }}
          onClick={() => GenerateInvoice()}
        >
          <span className="mr-2 flex items-center justify-center">
            {loader && (
              <>
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme: any) => theme.zIndex.drawer + 1,
                  }}
                  open={open}
                  onClick={handleClose}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
                {/* <DownloadIcon /> */}
              </>
            )}

            {loader ? (
              <>
                <div>
                  {" "}
                  <CircularProgress size={20} color="inherit" /> Downloading
                </div>{" "}
              </>
            ) : (
              <>
                <span>Download</span>{" "}
              </>
            )}
          </span>
        </button>
      </DialogActions>
    </Dialog>
  );
}

export default InvoiceModal;
