import React, { useEffect, useState, useRef, useCallback, memo } from "react";
import "./Table.scss";
import { DataGrid, DataGridProps, GridColDef } from "@mui/x-data-grid";
import { RefObject } from "react";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
interface tableProps {
  data: any;
  loading: boolean;
}

interface ChargerData {
  serialNum: string;
  costPerUnit: number;
  cost: number;
  taxInclusive: number;
}

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Tooltip title="Vehicle SOC" placement="right">
      <Box sx={{ position: "relative", display: "inline-flex", width: "34px" }}>
        <CircularProgress variant="determinate" {...props} />

        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 7,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span className="font-lexend" style={{ fontSize: "10px" }}>
            {`${Math.round(props.value)}%`}{" "}
          </span>
        </Box>
      </Box>
    </Tooltip>
  );
}

export function CircularWithValueLabel(props: any) {
  let { Soc } = props;

  const [progress, setProgress] = React.useState(Soc);

  return <CircularProgressWithLabel value={progress} />;
}

const DatabaseTable = memo((props: tableProps) => {
  const { data } = props;
  const [dataRow, setDataRow] = useState([]);
  const [chargersData, setChargersData] = useState<ChargerData[]>([]);

  useEffect(() => {
    // Fetch charger data from Parse when the component mounts
    async function fetchChargersData() {
      const Charger = Parse.Object.extend("Chargers");
      const query = new Parse.Query(Charger);
      try {
        const results = await query.find();
        // Transform Parse results into usable data
        const chargers = results.map((result: any) => ({
          serialNum: result.get("Serial"),
          cost: result.get("Cost"),
          taxInclusive: result.get("inclusiveTax"),
        })) as ChargerData[];
        setChargersData(chargers);
      } catch (error) {
        console.error("Error fetching charger data:", error);
      }
    }
    fetchChargersData();
  }, []);
  useEffect(() => {
    setDataRow(data.sort((a: any, b: any) => a.id - b.id));
  }, [data]);

  useEffect(() => {
    // Update data row with total cost after chargers data is fetched
    const updatedDataRow = data.map((item: any) => ({
      ...item,
      totalCost: calculateTotalCost(item),
    }));
    setDataRow(updatedDataRow);
  }, [data, chargersData]);

  const calculateTotalCost = (charger: any) => {
    const chargerData = chargersData.find(
      (item: ChargerData) => item.serialNum === charger.serialNum
    );
    if (chargerData) {
      const { cost, taxInclusive } = chargerData;
      const { obj, ocppEnergy } = charger;

      const currentStatus = obj.get("Live");
      const charge = obj.get("ChargePoint")?.get("isOCPP");
      const meterStart = obj.get("MeterStart");

      if (charge && currentStatus === true) {
        const totalCost = ((ocppEnergy - meterStart / 1000) * cost).toFixed(2);
        return totalCost;
      } else {
        return parseFloat(obj.get("TotalCost")).toFixed(2);
      }
    }
    return "N/A"; // Handle case where charger data is not found
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          ID
        </span>
      ),
      width: 60,
    },

    {
      field: "customer",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Name
        </span>
      ),
      width: 170,
      cellClassName: "custom_cell_name",
      renderCell: (params: any) => (
        <>
          <div className="custom_cell_style">
            {params?.row?.customer &&
              params.row.customer.charAt(0).toUpperCase() +
                params.row.customer.slice(1)}
          </div>
        </>
      ),
    },
    {
      field: "location",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Location
        </span>
      ),
      width: 230,
      cellClassName: "custom-cell",
      renderCell: (params: any) => {
        let location = params;

        return <p className="font-lexend">{params.row.location}</p>;
      },
    },

    {
      field: "isLive",
      width: 200,
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Status
        </span>
      ),
      renderCell: (params: any) => {
        let currentStatus = params.row.obj.get("Live");
        let cost = params.row.cost;
        let soc = params?.row?.soc;
        return currentStatus === false ? (
          <>
            {cost == "₹ NaN" || cost == "₹undefined" ? (
              <span
                style={{ color: "#C99456" }}
                className="flex align-middle rounded-full p-1"
              >
                <svg
                  className=" mr-2 mt-2"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.03906 1.25L14.5391 14.75M7.03906 4.25H11.5391C11.9369 4.25 12.3184 4.40804 12.5997 4.68934C12.881 4.97064 13.0391 5.35218 13.0391 5.75V6.125C13.0391 6.22446 13.0786 6.31984 13.1489 6.39017C13.2192 6.46049 13.3146 6.5 13.4141 6.5C13.5135 6.5 13.6089 6.53951 13.6792 6.60983C13.7496 6.68016 13.7891 6.77554 13.7891 6.875V9.125C13.7891 9.22446 13.7496 9.31984 13.6792 9.39017C13.6089 9.46049 13.5135 9.5 13.4141 9.5C13.3146 9.5 13.2192 9.53951 13.1489 9.60983C13.0786 9.68016 13.0391 9.77554 13.0391 9.875V10.25M11.5391 11.75H3.28906C2.89124 11.75 2.50971 11.592 2.2284 11.3107C1.9471 11.0294 1.78906 10.6478 1.78906 10.25V5.75C1.78906 5.35218 1.9471 4.97064 2.2284 4.68934C2.50971 4.40804 2.89124 4.25 3.28906 4.25H4.03906"
                    stroke="#C99456"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p
                  style={{
                    fontFamily: "lexend",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                  className="mt-1"
                >
                  Cancelled
                </p>{" "}
              </span>
            ) : (
              <span className="flex align-middle rounded-full p-1 text-blue-600">
                <svg
                  className=" mr-2 mt-2"
                  width="19"
                  height="18"
                  viewBox="0 0 19 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.03906 7.5V10.5M8.28906 7.5V10.5M10.5391 7.5V10.5M12.7891 7.5V10.5M5.28906 5.25H13.5391C13.9369 5.25 14.3184 5.40804 14.5997 5.68934C14.881 5.97064 15.0391 6.35218 15.0391 6.75V7.125C15.0391 7.22446 15.0786 7.31984 15.1489 7.39017C15.2192 7.46049 15.3146 7.5 15.4141 7.5C15.5135 7.5 15.6089 7.53951 15.6792 7.60983C15.7496 7.68016 15.7891 7.77554 15.7891 7.875V10.125C15.7891 10.2245 15.7496 10.3198 15.6792 10.3902C15.6089 10.4605 15.5135 10.5 15.4141 10.5C15.3146 10.5 15.2192 10.5395 15.1489 10.6098C15.0786 10.6802 15.0391 10.7755 15.0391 10.875V11.25C15.0391 11.6478 14.881 12.0294 14.5997 12.3107C14.3184 12.592 13.9369 12.75 13.5391 12.75H5.28906C4.89124 12.75 4.50971 12.592 4.2284 12.3107C3.9471 12.0294 3.78906 11.6478 3.78906 11.25V6.75C3.78906 6.35218 3.9471 5.97064 4.2284 5.68934C4.50971 5.40804 4.89124 5.25 5.28906 5.25Z"
                    stroke="#1A72C4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p
                  style={{
                    fontFamily: "lexend",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                  className="mt-1"
                >
                  Completed
                </p>{" "}
              </span>
            )}
          </>
        ) : (
          <div className="flex items-center">
            <span className="flex align-middle rounded-full p-1 text-green-600">
              {" "}
              <svg
                className=" mr-2 mt-1"
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_3937_2347)">
                  <path
                    d="M3.78906 6.75C3.78906 6.35218 3.9471 5.97064 4.2284 5.68934C4.50971 5.40804 4.89124 5.25 5.28906 5.25H13.5391C13.9369 5.25 14.3184 5.40804 14.5997 5.68934C14.881 5.97064 15.0391 6.35218 15.0391 6.75V7.125C15.0391 7.22446 15.0786 7.31984 15.1489 7.39017C15.2192 7.46049 15.3146 7.5 15.4141 7.5C15.5135 7.5 15.6089 7.53951 15.6792 7.60983C15.7496 7.68016 15.7891 7.77554 15.7891 7.875V10.125C15.7891 10.2245 15.7496 10.3198 15.6792 10.3902C15.6089 10.4605 15.5135 10.5 15.4141 10.5C15.3146 10.5 15.2192 10.5395 15.1489 10.6098C15.0786 10.6802 15.0391 10.7755 15.0391 10.875V11.25C15.0391 11.6478 14.881 12.0294 14.5997 12.3107C14.3184 12.592 13.9369 12.75 13.5391 12.75H10.1641M5.28906 16.5V14.25M3.78906 11.25V9.375M6.78906 11.25V9.375M3.03906 11.25H7.53906V12.75C7.53906 13.1478 7.38103 13.5294 7.09972 13.8107C6.81842 14.092 6.43689 14.25 6.03906 14.25H4.53906C4.14124 14.25 3.75971 14.092 3.4784 13.8107C3.1971 13.5294 3.03906 13.1478 3.03906 12.75V11.25Z"
                    stroke="#1AC47D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3937_2347">
                    <rect
                      width="18"
                      height="18"
                      fill="white"
                      transform="translate(0.789062)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <p
                style={{
                  fontFamily: "lexend",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
              >
                Charging
              </p>
            </span>
            <span className="ml-4 mt-2">
              {soc != undefined ? (
                <CircularWithValueLabel Soc={soc} />
              ) : (
                <span className="ml-4">-</span>
              )}
            </span>
          </div>
        );
      },
    },
    {
      field: "carCharged",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Vehicle
        </span>
      ),
      width: 120,
      renderCell: (params: any) => {
        return (
          <div style={{ fontFamily: "lexend", fontWeight: "300" }}>
            {params.row.carCharged}
          </div>
        );
      },
    },

    {
      field: "startTime",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Start Time
        </span>
      ),
      width: 200,
      renderCell: (params: any) => {
        return (
          <div style={{ fontFamily: "lexend", fontWeight: "300" }}>
            {params.row.startTime}
          </div>
        );
      },
    },
    {
      field: "endTime",

      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          End Time
        </span>
      ),
      width: 200,
      renderCell: (params: any) => {
        let currentStatus = params.row.obj.get("Live");

        return currentStatus === true ? (
          <div>-</div>
        ) : (
          <span style={{ fontFamily: "lexend", fontWeight: "300" }}>
            {params.row.endTime}
          </span>
        );
      },
    },
    {
      field: "totalCost",

      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Cost
        </span>
      ),
      width: 100,
      renderCell: (params: any) => (
        <>
          <div style={{ fontFamily: "lexend", fontWeight: "300" }}>
            {params.value}
          </div>
        </>
      ),
    },
    {
      field: "energy",

      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Energy
        </span>
      ),
      width: 120,
      renderCell: (params: any) => {
        let meterStart = params.row.obj.get("MeterStart");
        let ocpp = params.row.time;

        let currentStatus = params.row.obj.get("Live");
        let charge = params.row.obj.get("ChargePoint")?.get("isOCPP");

        return charge && currentStatus === true ? (
          <div style={{ fontFamily: "lexend", fontWeight: "300" }}>
            {(params.row.ocppEnergy - meterStart / 1000).toFixed(2)}
          </div>
        ) : (
          <span style={{ fontFamily: "lexend", fontWeight: "300" }}>
            {parseFloat(params.row.obj.get("TotalEnergyConsumed")).toFixed(2)}
          </span>
        );
      },
    },
    {
      field: "duration",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Duration
        </span>
      ),
      width: 120,
      renderCell: (params: {
        row: {
          ocppDuration: any;
          time: any;
          duration: string;
          obj: { get: (arg0: string) => any };
        };
      }) => {
        let currentStatus = params.row.obj.get("Live");
        let charge = params.row.obj.get("ChargePoint")?.get("isOCPP");
        let totalSeconds = parseInt(params.row.duration, 10);
        let hours = Math.floor(totalSeconds / 3600);
        let minutes = Math.floor((totalSeconds % 3600) / 60);
        return charge && currentStatus === true ? (
          <div style={{ fontFamily: "lexend", fontWeight: "300" }}>
            {params.row.ocppDuration}
          </div>
        ) : (
          <span style={{ fontFamily: "lexend", fontWeight: "300" }}>
            {params.row.duration == undefined
              ? params.row?.time
              : params.row.duration}
          </span>
        );
      },
    },
    {
      field: "serialNum",
      headerName: "Serial Number",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Serial Number
        </span>
      ),
      width: 150,
      renderCell: (params: any) => {
        return (
          <div style={{ fontFamily: "lexend", fontWeight: "300" }}>
            {params.row.serialNum}
          </div>
        );
      },
    },
    {
      field: "power",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Power
        </span>
      ),
      width: 120,
      renderCell: (params: any) => {
        return (
          <div style={{ fontFamily: "lexend", fontWeight: "300" }}>
            {params.row.power}
          </div>
        );
      },
    },
    {
      field: "connector",

      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Connector
        </span>
      ),
      width: 100,
      renderCell: (params: any) => {
        return (
          <div style={{ fontFamily: "lexend", fontWeight: "300" }}>
            {params.row.connector}
          </div>
        );
      },
    },
  ];

  const containerRef: RefObject<HTMLDivElement> = useRef(null);

  const onWheel = useCallback((e: any) => {
    // Check if containerRef.current is not null before accessing properties
    if (containerRef.current) {
      const containerScrollPosition = containerRef.current.scrollLeft;
      containerRef.current.scrollTo({
        top: 0,
        left: containerScrollPosition + e.deltaY,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <div
      className=" Table HomePage_table_container"
      ref={containerRef}
      id="container"
      onWheel={onWheel}
    >
      <DataGrid
        rows={dataRow.slice(0, 10)}
        rowHeight={39}
        columns={columns}
        rowsPerPageOptions={[6]}
        checkboxSelection={false}
        disableSelectionOnClick
        autoHeight
        hideFooter // for hiding footer of the table
        headerHeight={48}
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#ffffff",
            color: "#949597",
            fontSize: 16,
            fontFamily: "lexend",
            fontWeight: "500",
            lineHeight: "110%",
          },
        }}
        loading={props.loading}
      />
    </div>
  );
});

export default DatabaseTable;
